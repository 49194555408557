import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Alert, LoadingScreen } from '@/components/Elements'
import { useAppWindowFocus } from '@/features/app/hooks'
import { SocketMiddleware } from '@/features/socket/components/Socket'
import { EchoProvider } from '@/features/socket/contexts'
import { useUserDataQuery } from '@/features/user/store'

export const PrivateLayout = () => {
  const { t } = useTranslation()

  // todo: move to App component
  useAppWindowFocus()

  const { error, isLoading, isFetching, isSuccess } = useUserDataQuery()

  const loading = isLoading || isFetching

  const prepared = isSuccess

  return loading ? (
    <LoadingScreen />
  ) : prepared ? (
    <EchoProvider>
      <SocketMiddleware>
        <Outlet />
      </SocketMiddleware>
    </EchoProvider>
  ) : error && 'data' in error ? (
    <Alert type={'error'} mt={'lg'} mx={'lg'}>
      {(error.data as any)?.message || t('error')}
    </Alert>
  ) : null
}
