import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Flex, SimpleGrid } from '@mantine/core'
import { Button, DataShower, NoDataTextMessage } from '@/components/Elements'
import { TariffsPeriodSwitch } from '@/features/billing/components/Elements'
import { SubscriptionChange } from '@/features/billing/components/Subscription'
import { GeneralCard } from '@/features/billing/components/Tariff/Tariffs/Cards'
import {
  useTariffStaticOptions,
  useTariffsList,
  useTariffsPeriodGrid,
} from '@/features/billing/hooks'
import { TariffModel } from '@/features/billing/types'
import { useUserSubscription } from '@/features/user/hooks'
import { usePopup } from '@/hooks'
import useStyles from './SubscriptionPlans.styles'

export const SubscriptionPlans = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { classes } = useStyles()

  const { list, fetched, loading, error } = useTariffsList({
    options: { skip: false },
  })

  const { isPremium } = useTariffStaticOptions()

  const { subscription, isSubscriptionTariffAllow } = useUserSubscription()

  const { period, onPeriodChange, data: tariffs } = useTariffsPeriodGrid({ tariffs: list })

  const onSubscribe = (tariff: TariffModel) => {
    if (subscription?.tariff) {
      onSwap(tariff)
    } else {
      navigate('/billing/payment', {
        state: {
          paymentInfo: {
            price: tariff?.price,
            tariff,
          },
        },
      })
    }
  }

  const renderTariffAction = (tariff: TariffModel) => {
    const current = tariff?.id === subscription?.tariff?.id

    return (
      <Button
        fullWidth
        variant={isPremium(tariff.alias) ? 'filled' : 'outline'}
        disabled={!isSubscriptionTariffAllow(tariff)}
        onClick={() => onSubscribe(tariff)}
      >
        {current ? t('current_plan') : t('subscribe')}
      </Button>
    )
  }

  const swapPopup = usePopup()

  const onSwapClose = () => {
    swapPopup.close()
  }

  const onSwapSubmitSuccess = () => {
    onSwapClose()
  }

  const onSwap = (tariff: TariffModel) => {
    swapPopup.open(tariff)
  }

  return (
    <>
      <div>
        <Container className={classes.wrapper} size={1400}>
          <div className={classes.inner}>
            <Container py={20} size={800}>
              <DataShower isLoading={loading} isFetched={fetched} isFailed={!!error} error={error}>
                <Flex direction="column" align="center">
                  <Box mb={'xl'}>
                    <TariffsPeriodSwitch value={period} onChange={onPeriodChange} />
                  </Box>

                  {tariffs.length > 0 ? (
                    <SimpleGrid
                      className={'w-full'}
                      cols={tariffs.length}
                      maw={400}
                      breakpoints={[{ maxWidth: 550, cols: 1 }]}
                    >
                      {tariffs.map((item) => (
                        <React.Fragment key={item.id}>
                          <GeneralCard
                            data={item}
                            highlight={isPremium(item.alias)}
                            actionSections={renderTariffAction(item)}
                          />
                        </React.Fragment>
                      ))}
                    </SimpleGrid>
                  ) : (
                    <NoDataTextMessage variant={'data'} />
                  )}
                </Flex>
              </DataShower>
            </Container>
          </div>
        </Container>
      </div>

      <SubscriptionChange
        tariff={swapPopup.data}
        visible={swapPopup.visible}
        onClose={onSwapClose}
        onSuccess={onSwapSubmitSuccess}
      />
    </>
  )
}
