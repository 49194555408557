import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Table, Text, Title } from '@mantine/core'
import { useBilling } from '@/features/billing/hooks'
import { InvoiceModel } from '@/features/billing/types'

interface IProps {
  data: InvoiceModel
}

export const Summary = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { lines, tax, subtotal, total } = data

  const taxExist = tax !== null

  const { getCurrency } = useBilling()

  const currencySymbol = getCurrency()?.symbol

  return (
    <>
      <Box mb={'md'}>
        <Title order={4}>{t('summary')}</Title>
      </Box>

      <div className={'overflow-x-auto'}>
        <Table className={'whitespace-nowrap'}>
          <thead>
            <tr>
              <th style={{ width: '70px' }}>{t('number-no_abbreviation')}</th>
              <th>{t('description')}</th>
              <th>{t('quantity')}</th>
              {taxExist && <th>{t('vat')}</th>}
              <th>
                <Text ta={'right'}>{t('amount')}</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {lines &&
              !!lines.length &&
              lines.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.description}</td>
                  <td>{item.quantity}</td>
                  {taxExist && (
                    <td>
                      {item.tax_amount} {` ${currencySymbol} `}
                    </td>
                  )}
                  <td className="text-right">
                    {item.amount} {` ${currencySymbol} `}
                  </td>
                </tr>
              ))}

            {taxExist && (
              <tr>
                <td colSpan={taxExist ? 4 : 3} className="text-right">
                  {t('vat')}
                </td>
                <td className="text-right">
                  {tax} {` ${currencySymbol} `}
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={taxExist ? 4 : 3} className="text-right">
                {t('subtotal')}
              </td>
              <td className="text-right">
                {subtotal} {` ${currencySymbol} `}
              </td>
            </tr>
            <tr>
              <td colSpan={taxExist ? 4 : 3} className="border-0 text-right">
                <strong>{t('total')}</strong>
              </td>
              <td className="border-0 text-right">
                <Title order={3}>
                  {total} {` ${currencySymbol} `}
                </Title>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}
