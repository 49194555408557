import { rootApi } from '@/store/api'
import { CreateSubscriptionDTO, SwapTariffSubscriptionDTO } from '@/features/billing/api'
import { BillingAddressModel } from '@/features/billing/types'

const enhanceApi = rootApi.enhanceEndpoints({ addTagTypes: ['BillingSubscription'] })

export const billingSubscriptionApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    billingSubscriptionCreate: builder.mutation<any, { params: CreateSubscriptionDTO }>({
      query: (arg) => {
        const { params } = arg

        return {
          url: `billing/subscription`,
          method: 'POST',
          body: params,
        }
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    billingSubscriptionCancel: builder.mutation<null, void>({
      query: (id) => {
        return {
          url: `billing/subscription`,
          method: 'DELETE',
        }
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    billingSubscriptionResume: builder.mutation<any, void>({
      query: () => {
        return {
          url: `billing/subscription/resume`,
          method: 'POST',
        }
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    billingSubscriptionSwapTariff: builder.mutation<any, { params: SwapTariffSubscriptionDTO }>({
      query: (arg) => {
        const { params } = arg

        return {
          url: `billing/subscription/swap`,
          method: 'POST',
          body: params,
        }
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    billingSubscriptionPaymentLink: builder.query<any, void>({
      query: (id) => ({
        url: `billing/subscription/payment-link`,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
})

export const {
  useBillingSubscriptionCreateMutation,
  useBillingSubscriptionCancelMutation,
  useBillingSubscriptionResumeMutation,
  useBillingSubscriptionSwapTariffMutation,
  useLazyBillingSubscriptionPaymentLinkQuery,
} = billingSubscriptionApi
