import { IbanElement } from '@stripe/react-stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StripeField } from '@/features/stripe/components/FormElements'

interface IProps {
  elementStyle: object
  state: any
  onChange: (state: object) => void
}

export const IbanStripeField = ({ elementStyle, state, onChange }: IProps) => {
  const { t } = useTranslation()

  const options = {
    supportedCountries: ['SEPA'],
    // placeholderCountry: "IT",
    style: {
      ...elementStyle,
    },
  }

  return (
    <>
      <StripeField
        label={t('iban')}
        meta={state?.['iban']}
        options={options}
        onChange={onChange}
        component={IbanElement}
        wrapperProps={{
          mb: 'md',
        }}
      />
    </>
  )
}
