/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Badge,
  Box,
  Button,
  Card,
  Grid,
  Group,
  Image,
  Input,
  Select,
  Table,
  Text,
} from '@mantine/core'
import { Alert } from '@/components/Elements'
import { Notifications, notifications } from '@mantine/notifications'
import { ConfirmModal } from '@/components/Elements'
import { Anchor, ButtonLink, ColorSchemeToggle, LogoIcon, Modal } from '@/components/Elements'
import { BsTrash } from 'react-icons/bs'
import { useInvitesLoadMutation, useInvitesCreateMutation, useInvitesDeleteMutation } from '@/features/invites/store'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useUser } from '@/features/user/hooks'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import {
  CheckboxFieldController,
  PasswordInputController,
  TextInputController,
} from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { useFormSubmit } from '@/hooks'
import { NormalizeUtils, ValidationsUtils } from '@/utils'
import { useNotify } from '@/hooks'

const { showNotification } = useNotify()

export type FormValues = {
  contact_name: string
  company_name: string
  city: string
  state: string
  zip_code: string
  street: string
  business_hours: string
  business_days: string
  address: string
  email: string
  phone: string
  password: string
  password_confirmation: string
  accept: boolean
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}


export const InvitesContainer = () => {
  const { user } = useUser()

  const {
    phone,
    company_name,
    contact_name,
    address,
    zip_code,
    street,
    state,
    city,
    ...restUserData
  } = user || {}

  const { t } = useTranslation()
  const [invites, setInvites] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState({ status: false, data: null })

  const [loadInvites,] = useInvitesLoadMutation()
  const [invitesCreate] = useInvitesCreateMutation()
  const [invitesDelete] = useInvitesDeleteMutation()

  const onDeleteInvite = (invite: any) => {
    setIsConfirmOpen({
      status: true,
      data: invite
    })
  }

  const handleRejectConfirm = () => {
    setIsConfirmOpen({
      status: false,
      data: null
    })
  }

  const handleDeleteInvite = async () => {
    try {
      await invitesDelete(isConfirmOpen.data).unwrap()

      showNotification({
        type: 'success',
        message: t('delete_successfully'),
      })
      await loadInvitesRequest()
    } catch (e) {
      showNotification({
        type: 'error',
        message: t('delete_error'),
      })
    } finally {
      loadInvitesRequest()
      setIsConfirmOpen({
        status: false,
        data: null
      })
    }
  }


  const rows = invites.map((element: any) => (
    <tr key={element.name}>
      <td>{element.user?.contact_name || element?.contact_name || '-'}</td>
      <td>{element.user?.company_name || element?.company_name || '-'}</td>
      <td>{element.email || element.user?.email || '-'}</td>
      <td>{element.phone || element.user?.phone || '-'}</td>
      <td>{element.password || element.user?.password || '-'}</td>
      <td>
        <span style={{ marginRight: 10 }}>{element.status?.toUpperCase() || element.user?.status?.toUpperCase() || '-'}</span>
        {element?.status === 'pending' ?
          <Button color="red" onClick={() => onDeleteInvite(element)}>
            <BsTrash />
          </Button> : null}
      </td>
    </tr>
  ))

  const loadInvitesRequest = async () => {
    try {
      setIsLoading(true)

      const res = await loadInvites(1).unwrap()
      setInvites(res?.data)
    } catch (e) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadInvitesRequest()
  }, [])

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      contact_name: '',
      company_name: company_name || '',
      address: address || '',
      city: city || '',
      state: state || '',
      zip_code: zip_code || '',
      street: street || '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        contact_name: Yup.string().required('field.error.required'),
        company_name: Yup.string().required('field.error.required'),
        city: Yup.string().required('field.error.required'),
        state: Yup.string().required('field.error.required'),
        zip_code: Yup.string().required('field.error.required'),
        street: Yup.string().required('field.error.required'),
        email: Yup.string().email('field.error.invalid').required('field.error.required'),
        phone: Yup.string()
          .test('phone', 'field.error.invalid', ValidationsUtils.phoneFormat)
          .required('field.error.required'),
        password: Yup.string()
          .required('field.error.required')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
        password_confirmation: Yup.string()
          .required('field.error.required')
          .oneOf([Yup.ref('password'), null], 'field.error.password.no_match')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
      })
    ),
  })

  const handleSubmitData = async (values: FormValues) => {
    await invitesCreate(values).unwrap()

    showNotification({
      type: 'success',
      message: t('create_successfully'),
    })


    await loadInvitesRequest()
    setIsOpen(false)
  }

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: handleSubmitData,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (err) { }
  }

  return (
    <Card>
      <ConfirmModal visible={isConfirmOpen.status} onClose={handleRejectConfirm} onConfirm={handleDeleteInvite} />
      <Modal size={'lg'} opened={isOpen} onClose={() => setIsOpen(false)} header={`New Invite`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {submitError && (
            <Alert type={'error'} mb={'sm'}>
              {submitError?.data?.message || t('error')}
            </Alert>
          )}

          <TextInputController
            control={control}
            name={'contact_name'}
            id={'contact_name'}
            label={t('contact_name')}
            placeholder={t('contact_name')}
            mb={'md'}
          />
{/* 
          <TextInputController
            control={control}
            name={'company_name'}
            id={'company_name'}
            label={t('company_name')}
            placeholder={t('company_name')}
            mb={'md'}
          />

          <TextInputController
            control={control}
            name={'state'}
            id={'state'}
            label={t('state')}
            placeholder={t('state')}
            mb={'md'}
          />

          <TextInputController
            control={control}
            name={'city'}
            id={'city'}
            label={t('city')}
            placeholder={t('city')}
            mb={'md'}
          />

          <TextInputController
            control={control}
            name={'street'}
            id={'street'}
            label={t('street')}
            placeholder={t('street')}
            mb={'md'}
          />

          <TextInputController
            control={control}
            name={'zip_code'}
            id={'zip_code'}
            label={t('zip_code')}
            placeholder={t('zip_code')}
            mb={'md'}
          /> */}

          <TextInputController
            control={control}
            name={'email'}
            id={'email'}
            label={t('email')}
            placeholder={t('email')}
            mb={'md'}
          />

          <TextInputController
            control={control}
            name={'phone'}
            id={'phone'}
            label={t('phone')}
            placeholder={t('phone')}
            mb={'md'}
            normalize={NormalizeUtils.phone}
          />

          <PasswordInputController
            control={control}
            name={'password'}
            id={'password'}
            label={t('password')}
            placeholder={t('password')}
            mb={'md'}
            translateParams={{ count: PASSWORD_MIN_LENGTH }}
          />

          <PasswordInputController
            control={control}
            name={'password_confirmation'}
            id={'password_confirmation'}
            label={t('confirm_password')}
            placeholder={t('confirm_password')}
            mb={'md'}
            translateParams={{ count: PASSWORD_MIN_LENGTH }}
          />
          <Button mt={'lg'} fullWidth type={'submit'} loading={isSubmitting}>
            {t('sign_up')}
          </Button>
        </form>
      </Modal>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginBottom: 30,
        }}
      >
        <Button mt={'md'} onClick={() => setIsOpen(true)} variant={'outline'}>
          {t('new_invite')}
        </Button>
      </div>
      <Table>
        <thead>
          <tr>
            <th>{t('contact_name')}</th>
            <th>{t('company_name')}</th>
            <th>{t('email')}</th>
            <th>{t('phone')}</th>
            <th>{t('password')}</th>
            <th>{t('status')}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      {invites?.length === 0 ? <p style={{ textAlign: 'center' }}>{t('no_invites_yet')}</p> : null}
    </Card>
  )
}
