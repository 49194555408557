import { rootApi } from '@/store/api'

export const liveFeedsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    liveFeedsLoad: builder.mutation<any, any>({
      query: (arg: any) => ({
        url: `posts?page=${arg.page}${arg?.search ? `&filters[q]=${arg?.search}` : ''}${
          arg?.customerType ? `&filters[customer_type]=${arg?.customerType}` : ''
        }`,
        method: 'GET',
      }),
    }),
    myFeedsLoad: builder.mutation<any, number>({
      query: (page) => ({
        url: `posts/my-listings?page=${page}`,
        method: 'GET',
      }),
    }),
    toggleFeedPurchase: builder.mutation<any, number>({
      query: (id) => ({
        url: `posts/${id}/mark-purchased`,
        method: 'POST',
      }),
    }),
    liveFeedsModelsLoad: builder.mutation<any, void>({
      query: () => ({
        url: 'car-makes',
        method: 'GET',
      }),
    }),
    liveFeedsCarYearsLoad: builder.mutation<any, void>({
      query: () => ({
        url: 'car-years',
        method: 'GET',
      }),
    }),
    liveFeedsMakesLoad: builder.mutation<any, void>({
      query: (year) => ({
        url: `car-makes/${year}`,
        method: 'GET',
      }),
    }),
    liveFeedsCarModelsLoad: builder.mutation<any, any>({
      query: (arg: any) => ({
        url: `car-models/${arg?.year}/${arg?.make}`,
        method: 'GET',
      }),
    }),
    liveFeedsCarTrimsLoad: builder.mutation<any, any>({
      query: (arg: any) => ({
        url: `car-trims/${arg?.year}/${arg?.model}`,
        method: 'GET',
      }),
    }),
    liveFeedsCreate: builder.mutation<any, any>({
      query: (arg) => ({
        url: 'posts',
        method: 'POST',
        body: arg,
      }),
    }),
    liveFeedAddPhoto: builder.mutation<any, any>({
      query: (arg) => {
        const formData = new FormData()
        formData.append('photos[0]', arg.photo)

        return {
          url: `posts/${arg?.id}/photos`,
          method: 'POST',
          body: formData,
        }
      },
    }),
    liveFeedDeletePhoto: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `posts/${data?.feedId}/photos/${data?.photoId}`,
          method: 'DELETE',
        }
      },
    }),
    liveFeedsUpdate: builder.mutation<any, any>({
      query: (arg) => ({
        url: `posts/${arg?.id}`,
        method: 'PATCH',
        body: arg,
      }),
    }),
    liveFeedLoad: builder.mutation<any, number>({
      query: (id) => ({
        url: `posts/${id}`,
        method: 'GET',
      }),
    }),
    liveFeedPostData: builder.query<any, number>({
      query: (id) => ({
        url: `posts/${id}`,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
})

export const {
  useLiveFeedsLoadMutation,
  useMyFeedsLoadMutation,
  useLiveFeedLoadMutation,
  useLiveFeedsCreateMutation,
  useLiveFeedsUpdateMutation,
  useLiveFeedsModelsLoadMutation,
  useLiveFeedsCarTrimsLoadMutation,
  useLiveFeedsCarModelsLoadMutation,
  useLiveFeedsMakesLoadMutation,
  useLiveFeedsCarYearsLoadMutation,
  useLiveFeedAddPhotoMutation,
  useToggleFeedPurchaseMutation,
  useLiveFeedDeletePhotoMutation,
  useLiveFeedPostDataQuery,
} = liveFeedsApi
