import PropTypes from 'prop-types'
import React, { Ref, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TbPaperclip } from 'react-icons/tb'
import { ActionIcon } from '@mantine/core'

interface IProps {
  disabled?: boolean
  onChange: (files: File[]) => void
}

export const FilesPicker = ({ disabled, onChange }: IProps) => {
  const { t } = useTranslation()

  const inputRef: Ref<any> = useRef()

  const handleFilesPicked = (event: any) => {
    onChange(event.target.files)

    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const pickFiles = () => {
    if (disabled) {
      return
    }

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <div className={'pointer-events-auto'}>
      <input
        type="file"
        className={'hidden'}
        onChange={handleFilesPicked}
        ref={inputRef}
        multiple
        accept="image/png, image/jpeg, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
      />

      <ActionIcon
        onClick={pickFiles}
        variant="transparent"
        disabled={disabled}
        color={'primary'}
        radius={'xl'}
      >
        <TbPaperclip size="24" />
      </ActionIcon>
    </div>
  )
}

FilesPicker.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
