import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '@mantine/core'
import { useBilling, useTariffStaticOptions } from '@/features/billing/hooks'
import { TariffModel } from '@/features/billing/types'
import { CommonUtils } from '@/utils'
import useStyles from './Table.styles'

interface IProps {
  data: TariffModel
}

export const TariffInfoTable = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { price, name, period } = data ?? {}

  const { isAnnual } = useTariffStaticOptions()

  const { getCurrency } = useBilling()

  return (
    <>
      <div className={'overflow-x-auto'}>
        <Table className={classes.table}>
          <tbody>
            <>
              <tr>
                <th scope="row">{t('subscription_plan')}:</th>
                <td>{name}</td>
              </tr>

              <tr>
                <th scope="row">{t('billed_period')}:</th>
                <td>{isAnnual(period) ? t('annually') : t('monthly')}</td>
              </tr>
            </>

            {!CommonUtils.isNil(price) && (
              <>
                <tr>
                  <th scope="row">{t('price')}:</th>
                  <td>
                    {price} {getCurrency()?.symbol}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </div>
    </>
  )
}
