import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Anchor } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import {
  CheckboxFieldController,
  PasswordInputController,
  TextInputController,
} from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { useFormSubmit } from '@/hooks'
import { NormalizeUtils, ValidationsUtils } from '@/utils'

export type FormValues = {
  contact_name: string
  company_name: string
  city: string
  state: string
  zip_code: string
  street: string
  business_hours: string
  business_days: string
  address: string
  email: string
  phone: string
  password: string
  password_confirmation: string
  accept: boolean
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}

export const RegisterForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      contact_name: '',
      company_name: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      street: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      accept: false,
    },
    resolver: yupResolver(
      Yup.object().shape({
        contact_name: Yup.string().required('field.error.required'),
        company_name: Yup.string().required('field.error.required'),
        city: Yup.string().required('field.error.required'),
        state: Yup.string().required('field.error.required'),
        zip_code: Yup.string().required('field.error.required'),
        street: Yup.string().required('field.error.required'),
        email: Yup.string().email('field.error.invalid').required('field.error.required'),
        phone: Yup.string()
          .test('phone', 'field.error.invalid', ValidationsUtils.phoneFormat)
          .required('field.error.required'),
        password: Yup.string()
          .required('field.error.required')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
        password_confirmation: Yup.string()
          .required('field.error.required')
          .oneOf([Yup.ref('password'), null], 'field.error.password.no_match')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
        accept: Yup.bool().oneOf([true], 'required'),
      })
    ),
  })

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (err) {}
  }

  const watchAccept = watch('accept')

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <TextInputController
          control={control}
          name={'contact_name'}
          id={'contact_name'}
          label={t('contact_name')}
          placeholder={t('contact_name')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'company_name'}
          id={'company_name'}
          label={t('company_name')}
          placeholder={t('company_name')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'state'}
          id={'state'}
          label={t('state')}
          placeholder={t('state')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'city'}
          id={'city'}
          label={t('city')}
          placeholder={t('city')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'street'}
          id={'street'}
          label={t('street')}
          placeholder={t('street')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'zip_code'}
          id={'zip_code'}
          label={t('zip_code')}
          placeholder={t('zip_code')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'email'}
          id={'email'}
          label={t('email')}
          placeholder={t('email')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'phone'}
          id={'phone'}
          label={t('phone')}
          placeholder={t('phone')}
          mb={'md'}
          normalize={NormalizeUtils.phone}
        />

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <PasswordInputController
          control={control}
          name={'password_confirmation'}
          id={'password_confirmation'}
          label={t('confirm_password')}
          placeholder={t('confirm_password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <CheckboxFieldController
          control={control}
          name={'accept'}
          id={'accept'}
          label={
            <>
              {t('register_terms_label')}{' '}
              <Anchor
                component={Link}
                to={'/terms'}
                className={'font-bold'}
                state={{ from: '/register' }}
              >
                {t('register_terms_text')}
              </Anchor>
            </>
          }
          mb={'md'}
        />

        <Button mt={'lg'} fullWidth type={'submit'} disabled={!watchAccept} loading={isSubmitting}>
          {t('sign_up')}
        </Button>
      </form>
    </>
  )
}
