import React from 'react'
import { useTranslation } from 'react-i18next'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { usePaymentMethodSetup, usePaymentMethodStaticOptions } from '@/features/billing/hooks'
import { useBillingPaymentMethodAddMutation } from '@/features/billing/store'
import { useStripe } from '@/features/stripe/hooks'
import { useNotify } from '@/hooks'
import { CardForm } from '../../PaymentMethodsForms'
import { SepaContainer } from './SepaContainer'

interface IProps {
  type: BILLING_PAYMENT_METHOD_TYPE
  onClose: () => void
  onSuccess?: () => void
}

export const PaymentMethodAddContainer = ({ type, onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const { stripe, elements } = useStripe()

  const [add] = useBillingPaymentMethodAddMutation()

  const { onSetup } = usePaymentMethodSetup()

  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    const setup = await onSetup({ stripe, elements, params })

    if (setup) {
      const { setupIntent, error } = setup

      if (error) {
        throw { data: error }
      } else {
        const { payment_method } = setupIntent

        const values = {
          ...params,
          payment_method,
        }

        await add({ params: values }).unwrap()

        showNotification({
          type: 'success',
          message: t('saved_successfully'),
        })

        onSuccess && onSuccess()
      }
    }
  }

  const { isCardType, isSepaType } = usePaymentMethodStaticOptions()

  return (
    <>
      {isCardType(type) ? (
        <CardForm onSubmit={onSubmit} onCancel={onClose} stripe={stripe} elements={elements} />
      ) : isSepaType(type) ? (
        <SepaContainer onSubmit={onSubmit} onCancel={onClose} stripe={stripe} elements={elements} />
      ) : null}
    </>
  )
}
