import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { SepaMandateInfo } from '@/features/stripe/components/Elements'
import { PaymentMethodForm } from '@/features/stripe/components/FormSections'

const { SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

type FormValues = {
  name: string
  email: string
  address: string
}

interface IProps {
  defaultValues?: {
    name: string
    email: string
    address: string
  }
  mandate?: boolean
  state: any
  onStripeChange: (state: any) => void
}

export const SepaStripeFormSection = ({
  defaultValues,
  mandate = true,
  state,
  onStripeChange,
}: IProps) => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormValues>()

  return (
    <>
      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'name'}
            id={'name'}
            label={t('name')}
            placeholder={t('name')}
            mb={'md'}
            defaultValue={defaultValues?.['name']}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'email'}
            id={'email'}
            label={t('email')}
            placeholder={t('email')}
            mb={'md'}
            defaultValue={defaultValues?.['email']}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col>
          <TextInputController
            control={control}
            name={'address'}
            id={'address'}
            label={t('address')}
            placeholder={t('address')}
            mb={'md'}
            defaultValue={defaultValues?.['address']}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col>
          <PaymentMethodForm state={state} type={SEPA_DEBIT} onChange={onStripeChange} />
        </Grid.Col>
      </Grid>

      {mandate && (
        <Grid mt={'lg'}>
          <Grid.Col>
            <SepaMandateInfo />
          </Grid.Col>
        </Grid>
      )}
    </>
  )
}
