import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Group } from '@mantine/core'
import { Alert, Button, DataShower, GeneralTabs } from '@/components/Elements'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { useBilling, usePaymentMethodStaticOptions } from '@/features/billing/hooks'
import { PaymentInfoType } from '@/features/billing/types/shared'
import { useStripe, useStripeForm } from '@/features/stripe/hooks'
import { useFormSubmit } from '@/hooks'
import {
  CardFormSection,
  CardSelectFormSection,
  PaymentMethodTypeFormSection,
  SepaFormSection,
  SepaSelectFormSection,
} from './FormSections'
import { PAYMENT_METHOD_MODE } from './consts'
import { usePaymentMethodEffect } from './hooks'
import { validationSchema } from './validation'

const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

interface IProps {
  paymentInfo?: PaymentInfoType
  onSubmit: (values: object, setup?: boolean) => Promise<void>
  initialValues?: any
  stripe: any
  elements: any
}

export const PaymentForm = ({ initialValues, ...props }: IProps) => {
  const { t } = useTranslation()

  const { elements, stripe } = useStripe()

  const [paymentMethodMode, setPaymentMethodMode] = useState(PAYMENT_METHOD_MODE.EXISTING)

  // Stripe form
  const stripeCardForm = useStripeForm({
    initialState: {
      cardNumber: undefined,
      cardExpiry: undefined,
      cardCvc: undefined,
    },
  })

  const stripeSepaForm = useStripeForm({
    initialState: {
      iban: undefined,
    },
  })

  const onStripeChange = (type: BILLING_PAYMENT_METHOD_TYPE, e: any) => {
    if (type === CARD) {
      stripeCardForm.onChange(e)
    } else if (type === SEPA_DEBIT) {
      stripeSepaForm.onChange(e)
    }
  }

  const onStripeDestroy = (type: BILLING_PAYMENT_METHOD_TYPE) => {
    if (type === CARD) {
      stripeCardForm.reset()
    } else if (type === SEPA_DEBIT) {
      stripeSepaForm.reset()
    }
  }
  // === //

  const methods = useForm({
    defaultValues: {
      payment_method_type: CARD,
      card: null,
      iban: null,
    },
    resolver: yupResolver(validationSchema),
    context: { paymentMethodMode },
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
    setValue,
  } = methods

  const paymentMethodType = watch('payment_method_type')

  const isDisabled = useMemo(() => {
    if (paymentMethodMode === PAYMENT_METHOD_MODE.NEW) {
      if (!stripe || !elements) {
        return true
      }

      if (paymentMethodType === CARD && !stripeCardForm.isValid) {
        return true
      }

      if (paymentMethodType === SEPA_DEBIT && !stripeSepaForm.isValid) {
        return true
      }
    }

    return false
  }, [
    paymentMethodMode,
    stripe,
    elements,
    stripeCardForm.isValid,
    stripeSepaForm.isValid,
    paymentMethodType,
  ])

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const { isCardType, isSepaType } = usePaymentMethodStaticOptions()

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      if (isDisabled) {
        return
      }

      const { payment_method_type, card, iban, name, email, address } = data

      let params: any = {
        payment_method_type,
      }

      const setup = paymentMethodMode === PAYMENT_METHOD_MODE.NEW

      if (setup) {
        if (payment_method_type === SEPA_DEBIT) {
          params = {
            ...params,
            billing_details: {
              name,
              email,
              address: {
                line1: address,
                country: stripeSepaForm.state?.['iban']?.['country'],
              },
            },
          }
        }
      } else {
        params = {
          ...params,
          payment_method: isCardType(payment_method_type) ? card : iban,
        }
      }

      await onFormSubmit(params, setup)
    } catch (error) {}
  }

  const { getCurrency } = useBilling()

  const { data, loading, isError, isSuccess, error } = usePaymentMethodEffect({ setValue })

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <DataShower isFetched={isSuccess} isLoading={loading} error={error} isFailed={isError}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={'md'}>
              <PaymentMethodTypeFormSection />
            </Box>

            {isCardType(paymentMethodType) && (
              <GeneralTabs
                variant={'pills'}
                tabs={[
                  {
                    label: t('your_cards'),
                    value: PAYMENT_METHOD_MODE.EXISTING,
                    render: () => <CardSelectFormSection data={data} />,
                    panelProps: { mt: 'md' },
                  },
                  {
                    label: t('new_card'),
                    value: PAYMENT_METHOD_MODE.NEW,
                    render: () => (
                      <CardFormSection
                        state={stripeCardForm.state}
                        onChange={(e) => onStripeChange(CARD, e)}
                        onDestroy={() => onStripeDestroy(CARD)}
                      />
                    ),
                    panelProps: { mt: 'md' },
                  },
                ]}
                onChange={setPaymentMethodMode}
                value={paymentMethodMode}
              />
            )}

            {isSepaType(paymentMethodType) && (
              <GeneralTabs
                variant={'pills'}
                tabs={[
                  {
                    label: t('sepa'),
                    value: PAYMENT_METHOD_MODE.EXISTING,
                    render: () => <SepaSelectFormSection data={data} />,
                    panelProps: { mt: 'md' },
                  },
                  {
                    label: t('new_sepa'),
                    value: PAYMENT_METHOD_MODE.NEW,
                    render: () => (
                      <SepaFormSection
                        state={stripeSepaForm.state}
                        onStripeChange={(e) => onStripeChange(SEPA_DEBIT, e)}
                        onDestroy={() => onStripeDestroy(SEPA_DEBIT)}
                      />
                    ),
                    panelProps: { mt: 'md' },
                  },
                ]}
                onChange={setPaymentMethodMode}
                value={paymentMethodMode}
              />
            )}

            <Box mt={'xl'}>
              <Group>
                <Button loading={isSubmitting} disabled={isDisabled} type={'submit'}>
                  {`${t('pay')} ${props.paymentInfo?.price || ''} ${getCurrency()?.symbol}`}
                </Button>
              </Group>
            </Box>
          </form>
        </DataShower>
      </FormProvider>
    </>
  )
}
