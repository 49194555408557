import React from 'react'
import { useTranslation } from 'react-i18next'
import { MessageType } from '@/features/chat/types/models'
import { Message } from '../Message'
import { Day } from './Day'
import useStyles from './MessageItem.styles'
import { Unread } from './Unread'

interface IProps {
  item: MessageType
  day: boolean
  unread: boolean
  avatar?: boolean
  forwardRef: any
  alignRight?: boolean
  messageProps?: any
}

export const MessageItem = ({
  item,
  day,
  unread,
  avatar = false,
  forwardRef,
  alignRight = false,
  messageProps,
}: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.root, {
        [`${classes.right}`]: alignRight,
      })}
      ref={forwardRef}
    >
      <div className={'w-full'}>
        {day && <Day date={item.created_at} />}

        {unread && <Unread />}

        <div
          className={cx(classes.messageBlock, {
            [`${classes.right}`]: alignRight,
          })}
        >
          {/*{avatar && !item.is_my && <Avatar avatar={item?.user?.avatar} />}*/}
          <Message item={item} alignRight={alignRight} {...messageProps} />
        </div>
      </div>
    </div>
  )
}
