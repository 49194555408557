import axios from 'axios'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import * as PusherTypes from 'pusher-js'

const KEY = process.env.REACT_APP_PUSHER_KEY
const CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER
const AUTH_URL = process.env.REACT_APP_BASE_URL + 'api/broadcasting/auth'

window.Pusher = Pusher

const options = {
  broadcaster: 'pusher',
  key: KEY,
  cluster: CLUSTER,
  forceTLS: true,
  authEndpoint: AUTH_URL,
  // authorizer: (channel: PusherTypes.Channel, options: any) => {
  //   return {
  //     authorize: (socketId: any, callback: any) => {
  //       axios
  //         .post('api/broadcasting/auth', {
  //           socket_id: socketId,
  //           channel_name: channel.name,
  //         })
  //         .then((response) => {
  //           callback(false, response.data)
  //         })
  //         .catch((error) => {
  //           callback(true, error)
  //         })
  //     },
  //   }
  // },
  authorizer: (channel: PusherTypes.Channel, options: any) => {
    return {
      authorize: (socketId: any, callback: any) => {
        axios
          .post('api/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((response) => {
            callback(false, response.data)
          })
          .catch((error) => {
            callback(true, error)
          })
      },
    }
  },
}

class EchoService {
  echo: any = null

  getEcho = () => {
    return this.echo
  }

  create = () => {
    this.echo = new Echo(options)
  }

  privateChannelAddListener = (channel: string, event: string, callback: any) => {
    const echo = this.getEcho()

    if (echo && channel && event && callback) {
      echo.channel(channel).listen(event, callback)
    }
  }

  privateChannelNotification = (channel: string, callback: any) => {
    const echo = this.getEcho()

    if (echo && channel && callback) {
      echo.channel(channel).notification(callback)
    }
  }

  privateChannelAddListeners = (channel: string, events: object) => {
    const echo = this.getEcho()

    if (echo && channel && events) {
      Object.entries(events).forEach((entry) => {
        const [event, callback] = entry
        this.privateChannelAddListener(channel, event, callback)
      })
    }
  }

  privateChannelStopListener = (channel: string, event: string) => {
    const echo = this.getEcho()

    if (echo && channel && event) {
      echo.channel(channel).stopListening(event)
    }
  }

  privateChannelStopListeners = (channel: string, events: string[]) => {
    const echo = this.getEcho()

    if (echo && channel && events) {
      events.forEach((event) => {
        this.privateChannelStopListener(channel, event)
      })
    }
  }

  channelLeave = (channel: string) => {
    const echo = this.getEcho()
    if (echo) {
      echo.leave(channel)
    }
  }

  channelLeaveAll = () => {
    const echo = this.getEcho()

    if (echo && echo.connector && echo.connector.channels) {
      for (const key in echo.connector.channels) {
        if (Object.prototype.hasOwnProperty.call(echo.connector.channels, key)) {
          echo.leave(key)
        }
      }
    }
  }

  destroy = () => {
    this.echo = null
  }
}

export const EchoClient = new EchoService()
