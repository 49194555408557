import { rootApi } from '@/store/api'
import { TariffModel } from '@/features/billing/types'

const enhanceApi = rootApi.enhanceEndpoints({ addTagTypes: ['BillingTariffs'] })

export const billingTariffsApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    billingTariffsList: builder.query<TariffModel[], any>({
      query: () => ({
        url: `billing/tariffs`,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
  }),
  overrideExisting: false,
})

export const { useBillingTariffsListQuery } = billingTariffsApi
