import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GeneralCard } from '@/components/Elements'
import { usePaymentMethodSetup } from '@/features/billing/hooks'
import { useBillingSubscriptionCreateMutation } from '@/features/billing/store'
import { PaymentInfoType } from '@/features/billing/types/shared'
import { useStripe } from '@/features/stripe/hooks'
import { PaymentForm } from './PaymentForm'

interface IProps {
  paymentInfo?: PaymentInfoType
}

export const PaymentContainer = (props: IProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { elements, stripe } = useStripe()

  const [subscriptionCreate] = useBillingSubscriptionCreateMutation()

  const { onSetup } = usePaymentMethodSetup()

  const onSubmit = async (data: any, setup?: boolean) => {
    const { tariff } = props.paymentInfo ?? {}

    if (!tariff) {
      return
    }

    let payment_method = data?.payment_method
    if (setup) {
      const setupResult = await onSetup({ stripe, elements, params: data })

      if (setupResult) {
        const { setupIntent, error } = setupResult
        if (error) {
          throw { data: error }
        } else {
          payment_method = setupIntent?.payment_method
        }
      }
    }

    const params = {
      tariff: tariff?.id,
      payment_method,
    }

    const result = await subscriptionCreate({ params }).unwrap()

    const { success, type, message, url } = result

    if (success) {
      navigate(`/billing?success=true&message=${t('billing.pay.subscription.success')}`)
    } else if (url) {
      // redirect to 3d secure page
      window.location.href = url
    } else if (message) {
      throw { data: { message } }
    }
  }

  return (
    <>
      <GeneralCard title={t('payment')}>
        <PaymentForm
          paymentInfo={props.paymentInfo}
          onSubmit={onSubmit}
          stripe={stripe}
          elements={elements}
        />
      </GeneralCard>
    </>
  )
}
