import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import {
  CancelControl,
  ResumeControl,
} from '@/features/billing/components/Subscription/ActionControls'
import { BILLING_SUBSCRIPTION_STATUS } from '@/features/billing/consts/subscription'
import { useSubscriptionStaticOptions } from '@/features/billing/hooks'
import { useUserSubscription } from '@/features/user/hooks'

interface IProps {
  status: BILLING_SUBSCRIPTION_STATUS
  tarrifID?: number | null | undefined
}

export const SubscriptionStatus = ({ status, tarrifID }: IProps) => {
  const { t } = useTranslation()

  const { isOnGracePeriod } = useUserSubscription()

  const { getStatus, isActiveStatus, isVIPStatus } = useSubscriptionStaticOptions()

  return (
    <div className={'flex'}>
      <Group className={'w-full'}>
        <div className={'flex grow'}>{getStatus(status)?.label}</div>
        {!isVIPStatus(tarrifID) ? (
          <>
            {isOnGracePeriod() ? (
              <ResumeControl />
            ) : isActiveStatus(status) ? (
              <CancelControl />
            ) : null}
          </>
        ) : null}
      </Group>
    </div>
  )
}
