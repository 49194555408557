import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@/components/Elements'
import { APP_NAME } from '@/const/core'

export const SepaMandateInfo = () => {
  const { t } = useTranslation()

  return (
    <>
      <Alert type={'info'}>
        By providing your payment information and confirming this payment, you authorise (A){' '}
        {APP_NAME} and Stripe, our payment service provider, to send instructions to your bank to
        debit your account and (B) your bank to debit your account in accordance with those
        instructions. As part of your rights, you are entitled to a refund from your bank under the
        terms and conditions of your agreement with your bank. A refund must be claimed within 8
        weeks starting from the date on which your account was debited. Your rights are explained in
        a statement that you can obtain from your bank. You agree to receive notifications for
        future debits up to 2 days before they occur.
      </Alert>
    </>
  )
}
