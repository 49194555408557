import { PayloadAction } from '@reduxjs/toolkit'
import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { billingInvoiceApi } from '@/features/billing/store'
import { billingInvoiceSocketInvoiceOnUpdate } from './actions'

function* onUpdate({ payload: { data } }: PayloadAction<{ data: any }>) {
  const { invoice } = data

  yield put(billingInvoiceApi.util.invalidateTags(['BillingInvoices']))
}

function* watchOnUpdate() {
  yield takeEvery(billingInvoiceSocketInvoiceOnUpdate, onUpdate)
}

export function* invoiceSaga() {
  yield all([fork(watchOnUpdate)])
}
