import { rootApi } from '@/store/api'
import { UpdateClientProfileDTO } from '@/features/profile/api/dto'
import { User } from '@/features/user/types'

export const profileApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    profileUpdateClientProfile: builder.mutation<User, UpdateClientProfileDTO>({
      query: (arg) => ({
        url: 'profile/supplier',
        method: 'POST',
        body: arg,
      }),
      // async onQueryStarted(id, { dispatch, queryFulfilled }) {
      //   const data = await queryFulfilled
      //   dispatch(userData.updateData(data.data))
      // },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    profileUpdatePassword: builder.mutation<any, any>({
      query: (arg) => ({
        url: 'profile/password',
        method: 'PUT',
        body: arg,
      }),
    }),
    profileDelete: builder.mutation<void, void>({
      query: () => ({
        url: 'profile/delete',
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useProfileUpdateClientProfileMutation,
  useProfileUpdatePasswordMutation,
  useProfileDeleteMutation,
} = profileApi
