import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Grid, Text } from '@mantine/core'
import { InvoiceModel } from '@/features/billing/types'
import { CommonUtils, DateUtils, FormatUtils, PaymentUtils } from '@/utils'

interface IProps {
  data: InvoiceModel
}

export const Info = ({ data }: IProps) => {
  const { t } = useTranslation()

  const customerLines = [
    {
      value: data.customer_name,
    },
    {
      value: data.customer_company,
    },
    {
      value: FormatUtils.formatPhone(data.customer_phone),
    },
    {
      value: data.customer_address_city,
    },
    {
      value: data.customer_address_line,
    },
    {
      value: data.customer_address_zip,
    },
    {
      value: data.customer_note,
    },
    {
      label: t('vat_code'),
      value: data.customer_tax_code,
    },
    {
      label: t('vat_id'),
      value: data.customer_tax_id,
    },
  ]

  const renderPaymentMethod = () => {
    const { card_brand, card_last4, sepa_bank_code, sepa_country, sepa_last4 } = data

    return card_brand && card_last4 ? (
      <>
        {card_brand && (
          <Text component={'span'} tt={'capitalize'}>
            {card_brand}&nbsp;
          </Text>
        )}
        {card_last4 ? PaymentUtils.paymentCardHiddenNumber(card_last4) : '-'}
      </>
    ) : sepa_bank_code && sepa_country && sepa_last4 ? (
      <>
        {t('sepa')}{' '}
        {PaymentUtils.paymentIbanHiddenNumber({
          country: sepa_country,
          bank_code: sepa_bank_code,
          last4: sepa_last4,
        })}
      </>
    ) : (
      '-'
    )
  }

  return (
    <>
      <Grid>
        <Grid.Col xs={6}>
          <Box>
            <Text weight={'bold'}>{t('billed_to')}:</Text>
            {customerLines.map(({ label, value }, i) =>
              !CommonUtils.isNil(value) ? (
                <Text key={i}>
                  {label ? `${label}: ` : ''}
                  {value}
                </Text>
              ) : null
            )}
          </Box>
        </Grid.Col>
      </Grid>

      <Grid mt={'lg'}>
        <Grid.Col sm={6}>
          <Box>
            <Text weight={'bold'}>{t('payment_method')}:</Text>

            {renderPaymentMethod()}

            <Text>{data.customer_email}</Text>
          </Box>
        </Grid.Col>
        <Grid.Col sm={6}>
          <Flex justify={'flex-end'}>
            <Text ta="right">
              <Text weight={'bold'}>{t('date')}:</Text>
              {DateUtils.formatDate(data.created_at)}
            </Text>
          </Flex>
        </Grid.Col>
      </Grid>
    </>
  )
}
