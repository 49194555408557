import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUserChatCreateMutation } from '@/features/chat/store'
import { useNotify } from '@/hooks'
import { MessageForm } from '../../MessageForm'

interface IProps {
  postId?: number
  id: number
  onClose: () => void
  onSuccess: (id: any) => void
}

export const UserChatCreateContainer = ({ id, postId, onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const [create] = useUserChatCreateMutation()

  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    if (postId) {
      params.post_id = postId
    }

    const res = await create({ id, params }).unwrap()

    console.log('UserChatCreateContainer: ', res)

    showNotification({
      type: 'success',
      message: t('send_successfully'),
    })

    onSuccess(res?.data?.chat_id)
  }

  return (
    <>
      <MessageForm onSubmit={onSubmit} onCancel={onClose} />
    </>
  )
}
