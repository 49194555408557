import React from 'react'
import { useTranslation } from 'react-i18next'
import { UnstyledButton } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { ChatModel } from '@/features/chat/types/models'
import { Body } from './Body'
import useStyles from './Item.styles'

interface IProps {
  data: ChatModel
  onSelect: (data: ChatModel) => void
  active: boolean
}

export const Item = ({ data, active, onSelect }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const { participant, post } = data

  const config = {
    avatar: true,
  }

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (active) {
      return
    }

    onSelect(data)
  }

  return (
    <UnstyledButton
      className={cx(classes.root, {
        [`${classes.rootActive}`]: active,
      })}
      onClick={onClick}
    >
      {config?.avatar && (
        <div className={classes.avatar}>
          <AvatarProvider size={48} avatar={participant?.avatar} />
        </div>
      )}

      <div className={classes.body}>
        <Body data={data} />
      </div>
    </UnstyledButton>
  )
}
