import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentMethodModel } from '@/features/billing/types'
import { PaymentUtils } from '@/utils'

interface IProps {
  data: PaymentMethodModel
}

export const IbanCell = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { country, bank_code, last4 } = data

  return (
    <>
      {PaymentUtils.paymentIbanHiddenNumber({
        country,
        bank_code,
        last4,
      })}
    </>
  )
}
