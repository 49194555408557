import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { LiveFeed } from '@/features/liveFeeds/components/liveFeeds/LiveFeedsContainer/LiveFeed'

export const LiveFeedPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'live_feed_page_title'} />

      <PageContent>
        <LiveFeed />
      </PageContent>
    </>
  )
}
