import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { FileInput, Select } from '@mantine/core'
import { Anchor, Title } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import {
  CheckboxFieldController,
  PasswordInputController,
  TextInputController,
} from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import {
  useLiveFeedsCarModelsLoadMutation,
  useLiveFeedsCarTrimsLoadMutation,
  useLiveFeedsCarYearsLoadMutation,
  useLiveFeedsCreateMutation,
  useLiveFeedsMakesLoadMutation,
  useLiveFeedsModelsLoadMutation,
} from '@/features/liveFeeds/store'
import { useNotify } from '@/hooks'

export const LiveFeedForm: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [createLiveFeeds] = useLiveFeedsCreateMutation()
  const [loadLiveFeedsModels] = useLiveFeedsModelsLoadMutation()
  const [liveFeedsCarYearsLoad] = useLiveFeedsCarYearsLoadMutation()
  const [liveFeedsMakesLoad] = useLiveFeedsMakesLoadMutation()
  const [liveFeedsCarModelsLoad] = useLiveFeedsCarModelsLoadMutation()
  const [liveFeedsCarTrimsLoad] = useLiveFeedsCarTrimsLoadMutation()

  const [models, setModels] = useState<any>([])
  const [files, setFiles] = useState<File[] | []>([])
  const [file, setFile] = useState<File | null>(null)

  const [carYears, setCarYears] = useState<any>([])
  const [carModels, setCarModels] = useState<any>([])
  const [carTrims, setCarTrims] = useState<any>([])
  const [photos, setPhotos] = useState<any[] | []>([])

  const [makes, setMakes] = useState<any>('')
  const [make, setMake] = useState<any>('')
  const [carYear, setCarYear] = useState<any>('')
  const [car, setCar] = useState<any>('')
  const [model, setModel] = useState<any>('')
  const [carTrim, setCarTrim] = useState<any>('')

  const { showNotification } = useNotify()

  const {
    control,
    handleSubmit,
    register,
    setError,
    clearErrors,
    formState: { isSubmitting },
  } = useForm<any>({
    defaultValues: {
      description: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        description: Yup.string().required(t('field_required')),
      })
    ),
  })
  const onSubmit: SubmitHandler<any> = async (data) => {
    const formData = new FormData()
    formData.append('car_model_id', model)
    formData.append('description', data.description)
    formData.append('year', carYear)
    formData.append('car_trim_id', carTrim)
    formData.append('car_make_id', make)

    photos.map((photo, idx) => {
      formData.append(`photos[${idx}]`, photo?.file)
    })

    const res = await createLiveFeeds(formData)

    showNotification({
      type: 'success',
      message: t('create_successfully'),
    })

    return navigate('/live-feeds')
  }

  const onLoadLiveFeedsModels = async () => {
    const res = await loadLiveFeedsModels()
    setModels(res || [])
  }

  const onLoadLiveFeedsTrims = async () => {
    const res = await loadLiveFeedsModels()
    setModels(res || [])
  }

  const onLoadLiveFeedsYears = async () => {
    const res: any = await liveFeedsCarYearsLoad()
    setCarYears(res?.data || [])
  }

  const onSelectCar = (e: any) => {
    setCar(e)
  }

  const handleSelectFile = (file: File) => {
    setFiles([...files, file])
    setFile(file)
  }

  const handleAddPhoto = (newPhoto: any) => {
    const reader = new FileReader(),
      files = newPhoto.target.files
    reader.onload = function () {
      setPhotos([...photos, { id: new Date().getTime(), data: reader.result, file: files[0] }])
      newPhoto.target.value = null
    }
    reader.readAsDataURL(files[0])
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const selectPhoto = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleDeletePhoto = (id: any) => {
    const newPhotos = photos.filter((el) => el.id !== id)
    setPhotos(newPhotos)
  }

  useEffect(() => {
    onLoadLiveFeedsYears()
  }, [])

  const onLoadMakes = async () => {
    const res: any = await liveFeedsMakesLoad(carYear)
    setMakes(res?.data?.data || [])
  }

  const onLoadModels = async () => {
    const data = {
      year: carYear,
      make,
    }

    const res: any = await liveFeedsCarModelsLoad(data)
    setCarModels(res?.data?.data || [])
  }

  useEffect(() => {
    if (carYear) {
      setMakes([])
      setMake('')
      onLoadMakes()
    }
  }, [carYear])

  useEffect(() => {
    if (carYear && make) {
      setModel('')
      setCarModels([])
      onLoadModels()
    }
  }, [carYear, make])

  const onLoadTrims = async () => {
    const data = {
      year: carYear,
      model,
    }

    const res: any = await liveFeedsCarTrimsLoad(data)
    setCarTrims(res?.data?.data || [])
  }

  useEffect(() => {
    if (carYear && make && model) {
      setCarTrim('')
      setCarTrims([])
      onLoadTrims()
    }
  }, [carYear, make, model])

  useEffect(() => {
    setModel('')

    if (models?.data?.data) {
      const newCarModels = models.data.data.find((model: any) => model.id == car)
      setCarModels(newCarModels.models)
    }
  }, [car])

  return (
    <>
      <h2 className={'m-0'}>{t('create_live_feed_title')}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h3 className="my-1">{t('photos_title')}</h3>
          <div>
            <input
              type="file"
              onChange={handleAddPhoto}
              ref={inputRef}
              accept="image/png, image/jpeg"
              className={'hidden'}
            />
            <Button onClick={selectPhoto}>{t('upload_title')}</Button>
          </div>
          <div>
            {photos
              ? photos.map((photo) => (
                  <div style={{ marginTop: 15 }}>
                    <img
                      style={{ maxWidth: 100, height: 'auto' }}
                      className="photo-item"
                      src={photo?.data}
                    />
                    <Button
                      size="md"
                      ml={'md'}
                      variant="light"
                      onClick={() => handleDeletePhoto(photo?.id)}
                    >
                      {t('delete_btn')}
                    </Button>
                  </div>
                ))
              : null}
          </div>
        </div>
        {carYears && (
          <Select
            value={carYear}
            onChange={(e: any) => setCarYear(e)}
            label={t('live_feed_form_year_label')}
            id={'year'}
            searchable
            placeholder={t('live_feed_form_year_label')}
            data={carYears.map((year: any) => {
              return { value: year.year, label: year.year }
            })}
          />
        )}
        {carYears && makes && (
          <Select
            value={make}
            onChange={(e: any) => setMake(e)}
            label={t('live_feed_form_car_id_label')}
            id={'car_make_id'}
            searchable
            placeholder={t('live_feed_form_car_id_placeholder')}
            data={makes.map((year: any) => {
              return { value: year.id, label: year.name }
            })}
          />
        )}
        {make && carModels && carYear && (
          <Select
            value={model}
            onChange={(e: any) => setModel(e)}
            id={'car_model_id'}
            searchable
            label={t('live_feed_form_car_model_id_label')}
            placeholder={t('live_feed_form_car_model_id_placeholder')}
            data={carModels.map((model: any) => {
              return { value: model.id, label: model.name }
            })}
          />
        )}
        {make && model && carTrims && carYear && (
          <Select
            value={carTrim}
            onChange={(e: any) => setCarTrim(e)}
            id={'car_trim_id'}
            searchable
            label={t('car_trim_label')}
            placeholder={t('car_trim_label')}
            data={carTrims.map((trim: any) => {
              return { value: trim.id, label: trim.name }
            })}
          />
        )}
        <TextInputController
          control={control}
          name={'description'}
          id={'description'}
          label={t('live_feed_form_description_label')}
          placeholder={t('live_feed_form_description_placeholder')}
          mb={'md'}
        />
        <Button
          mt={'lg'}
          fullWidth
          type={'submit'}
          loading={isSubmitting}
          disabled={!carTrim || !model}
        >
          {t('create_live_feed_btn')}
        </Button>
      </form>
    </>
  )
}
