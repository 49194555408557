import _ from 'lodash'
import { BILLING_BILLING_ADDRESS_TAX_ID_STATUS } from '@/features/billing/consts/billing-address'
import { BillingAddressModel } from '@/features/billing/types'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: BILLING_BILLING_ADDRESS_TAX_ID_STATUS
  color: string
}

export const getTaxIdStatuses = () => {
  return [
    {
      label: i18n.t('pending'),
      value: BILLING_BILLING_ADDRESS_TAX_ID_STATUS.PENDING,
      color: 'yellow',
    },
    {
      label: i18n.t('verified'),
      value: BILLING_BILLING_ADDRESS_TAX_ID_STATUS.VERIFIED,
      color: 'green',
    },
    {
      label: i18n.t('unverified'),
      value: BILLING_BILLING_ADDRESS_TAX_ID_STATUS.UNVERIFIED,
      color: 'red',
    },
  ]
}

export const getTaxIdStatus = (value: OptionType | string): OptionType | any => {
  const options = getTaxIdStatuses()
  return _.find(options, { value })
}

export const mapBillingAddress = (data: BillingAddressModel) => {
  let result = ''

  const { address, city, zip } = data

  if (address && city && zip) {
    result += `${address}, ${city}, ${zip}`
  }

  return result
}
