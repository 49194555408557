/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Avatar, Badge, Box, Button, Card, Grid, Group, Image, Text, Loader, Input } from '@mantine/core'
import { Notifications, notifications } from '@mantine/notifications'
import { TableView } from '@/components/Elements'
import { Anchor, ButtonLink, ColorSchemeToggle, LogoIcon, Modal } from '@/components/Elements'
import { UserChatCreateContainer } from '@/features/chat/components/Chat/UserChatCreate/UserChatCreateContainer'
import { useSuppliersLoadMutation } from '@/features/suppliers/store'
import { useUser } from '@/features/user/hooks'
import { useIsVisible, useNotify } from '@/hooks'
import { useDebouncedCallback } from 'use-debounce'

export const SuppliersContainer = () => {
  const { showNotification } = useNotify()

  const [search, setSearch] = useState('')

  const debounced = useDebouncedCallback(
    (value) => {
      loadSuppliers()
    },
    500
  )

  const navigate = useNavigate()

  const { t } = useTranslation()

  const [suppliersLoad] = useSuppliersLoadMutation()

  const [isOpen, setIsOpen] = useState(false)
  const [modalData, setModalData] = useState<any>(null)

  const { user } = useUser()

  const [suppliers, setSuppliers] = useState<any>({
    data: [],
    meta: null,
    links: null,
  })
  const [isLoading, setIsLoading] = useState(false)

  const loadSuppliers = async () => {
    setIsLoading(true)

    try {
      const res: any = await suppliersLoad(search)
      setSuppliers(res.data)
    } catch (e) {
    } finally {
      setIsLoading(false)
    }
  }

  const navigateToChat = (id: any) => {
    const participant = modalData || {}

    navigate('/chats', {
      state: {
        participant,
        id,
        post: null,
      },
    })
  }

  const navigateToPostChat = (participant: any, chatId: any, post: any) => {
    navigate('/chats', {
      state: {
        participant,
        id: chatId,
        post,
      },
    })
  }


  const userAddress = (user: any) => {
    let address = ''

    if (user?.city) address += `${user?.city} `
    if (user?.state) address += `${user?.state} `
    // if (user?.street) address += `${user?.street} `
    if (user?.zip_code) address += `${user?.zip_code} `

    if (address === '') return '-'

    return address
  }

  const handleView = (supplier: any) => {
    setModalData(supplier)
    setIsOpen(true)
  }

  useEffect(() => {
    loadSuppliers()
  }, [])

  return (
    <>
      <Modal
        opened={isOpen}
        onClose={() => setIsOpen(false)}
        header={`${modalData?.name} | ${modalData?.phone || ''}`}
      >
        <Grid>
          <Grid.Col span={12}>
            {modalData?.id !== user?.id && (
              <Box my={'md'}>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Avatar
                    src={modalData?.avatar?.thumb}
                    alt={`${modalData?.name}`}
                    style={{ marginRight: 10 }}
                  />
                  <Text size="lg" weight={500}>
                    {modalData?.name}
                  </Text>
                </div>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('street')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.street}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('address')}
                </Text>
                <Text size="md" weight={500}>
                  {userAddress(modalData)}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('phone')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.phone || '-'}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('email')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.email || '-'}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('business_hours')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.businessHours || '-'}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('business_days')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.businessDays || '-'}
                </Text>
                {modalData?.chatId === -1 ? (
                  <Button
                    onClick={() => navigateToPostChat(modalData, modalData?.chatId, modalData)}
                    variant="outline"
                    fullWidth
                    mt="md"
                    radius="md"
                  >
                    {t('open_post_chat')}
                  </Button>
                ) : (
                  <UserChatCreateContainer
                    id={modalData?.id}
                    onClose={() => setIsOpen(false)}
                    onSuccess={navigateToChat}
                  />
                )}
              </Box>
            )}
          </Grid.Col>
        </Grid>
      </Modal>
      <Notifications />
      <Input
        size="lg"
        placeholder={t('search')}
        defaultValue={search}
        onChange={(e) => {
          setSearch(e.target.value)
          debounced(e.target.value)
        }}
      />
      <Box style={{ width: '100%', justifyContent: 'center', display: 'flex' }} p={'md'}>
        {isLoading ? <Loader /> : null}
      </Box>
      {suppliers?.data?.filter((supplier: any) => supplier?.id !== user?.id)?.length === 0 ? <Box style={{ width: '100%', justifyContent: 'center', display: 'flex' }} p={'md'}>
        <Text weight={500}>{t('not_found')}</Text>
      </Box> : null}
      {suppliers?.data
        ?.filter((supplier: any) => supplier?.id !== user?.id)
        .map((supplier: any) => (
          <Card key={supplier.id} shadow="sm" padding="0" radius="md" className="mt-2" withBorder>
            <Group position="apart">
              <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap' }}>
                {supplier?.avatar?.thumb ? (
                  <Image
                    height={110}
                    fit="cover"
                    width={200}
                    alt={`${supplier.name}`}
                    src={supplier?.avatar?.thumb}
                  />
                ) : (
                  <Image
                    withPlaceholder
                    height={110}
                    fit="cover"
                    width={200}
                    alt={`${supplier.name}`}
                  />
                )}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ marginLeft: 10 }}>
                    <Text weight={500}>{supplier.name}</Text>
                    <Text weight={400}>{supplier.street || '-'}</Text>
                    <Text weight={400}>{userAddress(supplier)}</Text>
                    <Text weight={400}>{supplier.phone || '-'}</Text>
                  </div>
                </div>
              </div>
              <div style={{ padding: 10 }}>
                <Badge color="pink" variant="light">
                  {t('total_feeds')}: {supplier.active_posts_count}
                </Badge>
                <Button
                  mt={'md'}
                  onClick={() => handleView(supplier)}
                  fullWidth
                  variant={'outline'}
                >
                  {t('view_btn')}
                </Button>
              </div>
            </Group>
          </Card>
        ))}
    </>
  )
}
