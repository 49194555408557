import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Box } from '@mantine/core'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent, PageHeader } from '@/components/Layouts'
import { BillingAddressUpdate } from '@/features/billing/components/BillingAddress'
import { PaymentContainer, PaymentInfo } from '@/features/billing/components/Payment'
import { StripeElementsProvider } from '@/features/stripe/components'

export const Payment = () => {
  const { t } = useTranslation()

  const location = useLocation()

  return (
    <>
      <MegaTagTitle title={'payment'} />

      <PageContent>
        <PageHeader back />

        <PaymentInfo data={location.state?.paymentInfo} />

        <Box mt={'lg'}>
          <BillingAddressUpdate disabledMode />
        </Box>

        <Box mt={'lg'}>
          <StripeElementsProvider>
            <PaymentContainer paymentInfo={location.state?.paymentInfo} />
          </StripeElementsProvider>
        </Box>
      </PageContent>
    </>
  )
}
