import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StripeField } from '@/features/stripe/components/FormElements'

interface IProps {
  elementStyle: object
  state: any
  onChange: (state: object) => void
}

export const CardStripeGroupFields = ({ elementStyle, state, onChange }: IProps) => {
  const { t } = useTranslation()

  const options = {
    style: {
      ...elementStyle,
    },
  }

  return (
    <>
      <StripeField
        id={'cardNumber'}
        label={t('card_number')}
        meta={state?.['cardNumber']}
        options={options}
        onChange={onChange}
        component={CardNumberElement}
        wrapperProps={{
          mb: 'md',
        }}
      />

      <StripeField
        id={'expiry_date'}
        label={t('expiry_date')}
        meta={state?.['cardExpiry']}
        options={options}
        onChange={onChange}
        component={CardExpiryElement}
        wrapperProps={{
          mb: 'md',
        }}
      />

      <StripeField
        id={'cvc'}
        label={t('cvc')}
        meta={state?.['cardCvc']}
        options={options}
        onChange={onChange}
        component={CardCvcElement}
        wrapperProps={{
          mb: 'md',
        }}
      />
    </>
  )
}
