import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GeneralCard, OverlayDataShower } from '@/components/Elements'
import { GeneralInvoicesTable } from '@/features/billing/components/Invoice/Invoices/Tables'
import { useBillingInvoiceListQuery } from '@/features/billing/store'

export const LatestInvoicesContainer = () => {
  const { t } = useTranslation()

  const { data, isLoading, isFetching, error, isError } = useBillingInvoiceListQuery({
    params: {
      per_page: 5,
    },
  })

  const list = data?.data || []

  const loading = useMemo(() => {
    return isLoading || isFetching
  }, [isLoading, isFetching])

  return (
    <GeneralCard title={t('latest_invoices')}>
      <OverlayDataShower isLoading={loading} isFailed={isError} error={error}>
        <GeneralInvoicesTable keys={['number', 'date', 'amount']} items={list} loading={loading} />
      </OverlayDataShower>
    </GeneralCard>
  )
}
