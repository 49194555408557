import { createAction } from '@reduxjs/toolkit'

const BILLING_INVOICE_SOCKET_INVOICE_ON_UPDATE = 'billing/invoice-socket-tax-id-on-update'

export const billingInvoiceSocketInvoiceOnUpdate = createAction(
  BILLING_INVOICE_SOCKET_INVOICE_ON_UPDATE,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
