import React from 'react'
import { useTranslation } from 'react-i18next'
import { MessageType } from '@/features/chat/types/models'
import { Date } from './Date'
import { Read } from './Read'

interface IProps {
  data: MessageType
  config: {
    seen: boolean
  }
}

const defaultConfig = {
  seen: false,
}

export const Meta = ({ data, config }: IProps) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  return (
    <div className={'flex gap-2 items-center'}>
      <Date date={data?.created_at} />

      {config.seen && <Read seen={data?.seen} />}
    </div>
  )
}
