import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationControl } from '@/components/Controls'
import { Button } from '@/components/Elements'
import { useBillingSubscriptionCancelMutation } from '@/features/billing/store'
import { useNotify } from '@/hooks'

export const CancelControl = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [cancel, { isLoading }] = useBillingSubscriptionCancelMutation()

  const onConfirm = async () => {
    try {
      await cancel().unwrap()

      showNotification({
        type: 'success',
        message: t('subscribed_canceled'),
      })
    } catch (error: any) {
      showNotification({
        type: 'error',
        message: error?.data?.message || t('error'),
      })
    }
  }

  return (
    <>
      <ConfirmationControl
        onConfirm={onConfirm}
        render={({ onOpen }) => (
          <Button
            size={'xs'}
            variant={'outline'}
            color={'dark'}
            compact
            onClick={onOpen}
            loading={isLoading}
          >
            {t('cancel')}
          </Button>
        )}
      />
    </>
  )
}
