import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    minHeight: '56px',
    borderBottom:
      theme.colorScheme === 'dark'
        ? `1px solid ${theme.colors.dark[5]}`
        : `1px solid ${theme.colors.gray[4]}`,
    padding: '8px 16px',
  },
}))
