import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const color = theme.primaryColor
  const variant = 'light'

  const colors = theme.fn.variant({ variant, color })
  return {
    root: {
      backgroundColor: theme.fn.rgba(theme.colors.gray[3], 0.5),
      padding: '4px 12px',
      borderRadius: '12px',
    },

    highlight: {
      backgroundColor: colors.background,
    },
  }
})
