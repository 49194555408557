import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Attachment } from '@/components/Elements'

type ItemType = {
  id?: number
  attachment?: any
  file: File
}

interface IProps {
  id: string
  name: string
  className?: string
  value: File
  item: ItemType
  meta?: any
  onRemove?: () => void
  onUpload?: () => void
  download?: boolean
  remove?: boolean
  onDownload?: () => void
  loading?: boolean
  onValidate?: (name: string, onValid: () => void) => void
  translateParams?: any
}

export const FilesUploaderFile = ({
  id,
  name,
  className = '',
  value,
  meta = {},
  onRemove,
  onUpload,
  download,
  remove = true,
  onDownload,
  loading = false,
  ...props
}: IProps) => {
  const { error } = meta

  const showError = Boolean(error)

  const { t } = useTranslation()

  useEffect(() => {
    if (props.onValidate && value) {
      props.onValidate(name, onValid)
    }
  }, [])

  const onValid = () => {
    onUpload && onUpload()
  }

  const removeFile = () => {
    onRemove && onRemove()
  }

  const getErrorMessage = () => {
    if (!error) {
      return null
    }

    let message = null
    if (typeof error === 'string') {
      message = error
    }
    if (error?.message) {
      message = error.message
    }
    if (message) {
      message = t(message, props.translateParams ? { ...props.translateParams } : {})
    }
    return message
  }

  const file = useMemo(() => {
    if (value) {
      return value
    }

    return props?.item?.attachment
  }, [value, props.item])

  return (
    <div
      className={classNames({
        [className]: className,
      })}
    >
      <div className={classNames({})}>
        <div>
          <Attachment
            name={file?.name}
            onRemove={() => removeFile()}
            size={file?.size}
            download={download}
            remove={remove}
            onDownload={() => {
              onDownload && onDownload()
            }}
            loading={loading}
            error={getErrorMessage()}
            namePlaceholder={t('attached-file')}
          />
        </div>
      </div>
    </div>
  )
}
