import { useElements, useStripe as useStripeHook } from '@stripe/react-stripe-js'
import React from 'react'

export const useStripe = () => {
  const stripe = useStripeHook()
  const elements = useElements()

  return {
    stripe,
    elements,
  }
}
