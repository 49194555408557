import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { FileInput, Select } from '@mantine/core'
import { Anchor, Title } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import {
  CheckboxFieldController,
  PasswordInputController,
  TextInputController,
} from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import {
  useLiveFeedAddPhotoMutation,
  useLiveFeedDeletePhotoMutation,
  useLiveFeedLoadMutation,
  useLiveFeedsCarModelsLoadMutation,
  useLiveFeedsCarTrimsLoadMutation,
  useLiveFeedsCarYearsLoadMutation,
  useLiveFeedsCreateMutation,
  useLiveFeedsMakesLoadMutation,
  useLiveFeedsModelsLoadMutation,
  useLiveFeedsUpdateMutation,
} from '@/features/liveFeeds/store'
import { useNotify } from '@/hooks'

export const MyFeedForm: React.FC = () => {
  const { feedId } = useParams()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [updateLiveFeeds] = useLiveFeedsUpdateMutation()
  const [loadLiveFeed] = useLiveFeedLoadMutation()
  const [loadLiveFeedsModels] = useLiveFeedsModelsLoadMutation()
  const [liveFeedAddPhoto] = useLiveFeedAddPhotoMutation()
  const [liveFeedDeletePhoto] = useLiveFeedDeletePhotoMutation()

  const [liveFeedsCarYearsLoad] = useLiveFeedsCarYearsLoadMutation()
  const [liveFeedsMakesLoad] = useLiveFeedsMakesLoadMutation()
  const [liveFeedsCarModelsLoad] = useLiveFeedsCarModelsLoadMutation()
  const [liveFeedsCarTrimsLoad] = useLiveFeedsCarTrimsLoadMutation()

  const [models, setModels] = useState<any>([])
  const [files, setFiles] = useState<File[] | []>([])
  const [file, setFile] = useState<File | null>(null)
  const [carModels, setCarModels] = useState<any>([])
  const [photos, setPhotos] = useState<any[] | []>([])

  const [carYears, setCarYears] = useState<any>([])
  const [carTrims, setCarTrims] = useState<any>([])
  const [isMounted, setIsMounted] = useState<any>(false)

  const [makes, setMakes] = useState<any>('')
  const [make, setMake] = useState<any>('')
  const [carYear, setCarYear] = useState<any>('')
  const [carTrim, setCarTrim] = useState<any>('')

  const [car, setCar] = useState<any>('')
  const [model, setModel] = useState<any>('')

  const { showNotification } = useNotify()

  const {
    control,
    handleSubmit,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { isSubmitting },
  } = useForm<any>({
    defaultValues: {
      description: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        description: Yup.string().required(t('field_required')),
      })
    ),
  })
  const onSubmit: SubmitHandler<any> = async (data) => {
    const reqData = {
      ...data,
      year: carYear,
      car_trim_id: carTrim,
      car_make_id: make,
      car_model_id: model,
      id: feedId,
    }

    const res = await updateLiveFeeds(reqData)

    showNotification({
      type: 'success',
      message: t('update_successfully'),
    })
  }

  const onLoadLiveFeedsTrims = async () => {
    const res = await loadLiveFeedsModels()
    setModels(res || [])
  }
  const onLoadLiveFeedsYears = async () => {
    const res: any = await liveFeedsCarYearsLoad()
    setCarYears(res?.data || [])
  }

  const onLoadLiveFeedsModels = async () => {
    const res = await loadLiveFeedsModels()
    setModels(res || [])
  }

  const onLoadLiveFeed = async () => {
    const res: any = await loadLiveFeed(Number(feedId))

    setValue('year', res?.data?.data?.year)
    setValue('description', res?.data?.data?.description)

    setCar(res?.data?.data?.carMakeId)
    setModel(res?.data?.data?.carModelId)
    setPhotos(res?.data?.data?.photos)

    setCarYear(res?.data?.data?.year)
    setMake(res?.data?.data?.carMakeId)
    setCarTrim(res?.data?.data?.carTrimId)

    setIsMounted(true)
  }

  const onSelectCar = (e: any) => {
    setCar(e)
    setModel('')
  }

  const handleSelectFile = (file: File) => {
    setFiles([...files, file])
    setFile(file)
  }

  const onAddPhoto: SubmitHandler<any> = async (photo) => {
    try {
      const res: any = await liveFeedAddPhoto({
        photo,
        id: feedId,
      })

      if (res?.error?.data?.message) {
        showNotification({
          type: 'error',
          message: t(res?.error?.data?.message),
        })
      } else {
        const newPhotos = res?.data?.data || []
        setPhotos([...newPhotos])

        showNotification({
          type: 'success',
          message: t('update_successfully'),
        })
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }

  const handleAddPhoto = (newPhoto: any) => {
    const reader = new FileReader(),
      files = newPhoto.target.files
    reader.onload = function () {
      onAddPhoto(files[0])
      newPhoto.target.value = null
    }
    reader.readAsDataURL(files[0])
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const selectPhoto = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleDeletePhoto = async (id: any) => {
    try {
      const res: any = await liveFeedDeletePhoto({
        photoId: id,
        feedId,
      })

      const newPhotos = photos.filter((photo: any) => photo.id !== id)

      setPhotos([...newPhotos])

      showNotification({
        type: 'success',
        message: t('update_successfully'),
      })
    } catch (e) {
      console.log('error: ', e)
    }
  }

  useEffect(() => {
    onLoadLiveFeedsYears()
    onLoadLiveFeedsTrims()
    onLoadLiveFeedsModels()
    onLoadLiveFeed()
  }, [])

  useEffect(() => {
    if (models?.data?.data) {
      const newCarModels = models.data.data.find((model: any) => model.id == car)

      if (newCarModels?.models) {
        setCarModels(newCarModels.models)
      }
    }
  }, [car, models])

  const onLoadMakes = async () => {
    const res: any = await liveFeedsMakesLoad(carYear)
    setMakes(res?.data?.data || [])
  }
  const onLoadModels = async () => {
    const data = {
      year: carYear,
      make,
    }
    const res: any = await liveFeedsCarModelsLoad(data)
    setCarModels(res?.data?.data || [])
  }

  const handleSetYear = (e: any) => {
    setModel('')
    setModels([])

    setCarTrim('')
    setCarTrims([])

    setMake('')
    setMakes([])

    setCarYear(e)
  }

  const handleSetMake = (e: any) => {
    setModel('')
    setModels([])

    setCarTrim('')
    setCarTrims([])

    setMake(e)
  }

  const handleSetModel = (e: any) => {
    setCarTrim('')
    setCarTrims([])

    setModel(e)
  }

  useEffect(() => {
    if (carYear) {
      onLoadMakes()
    }
  }, [carYear])

  useEffect(() => {
    if (carYear && make) {
      onLoadModels()
    }
  }, [carYear, make])
  const onLoadTrims = async () => {
    const data = {
      year: carYear,
      model,
    }
    const res: any = await liveFeedsCarTrimsLoad(data)
    setCarTrims(res?.data?.data || [])
  }
  useEffect(() => {
    if (carYear && make && model) {
      onLoadTrims()
    }
  }, [carYear, make, model])

  return (
    <>
      <h2 className={'m-0'}>{t('edit_live_feed_title')}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h3 className="my-1">{t('photos_title')}</h3>
          <div>
            <input
              type="file"
              onChange={handleAddPhoto}
              ref={inputRef}
              accept="image/png, image/jpeg"
              className={'hidden'}
            />
            <Button onClick={selectPhoto}>{t('upload_title')}</Button>
          </div>
          <div>
            {photos
              ? photos.map((photo) => (
                  <div style={{ marginTop: 15 }}>
                    <img
                      style={{ maxWidth: 100, height: 'auto' }}
                      className="photo-item"
                      src={photo?.uri}
                    />
                    <Button
                      size="md"
                      ml={'md'}
                      color="red"
                      onClick={() => handleDeletePhoto(photo?.id)}
                    >
                      {t('delete_btn')}
                    </Button>
                  </div>
                ))
              : null}
          </div>
        </div>
        {carYears && (
          <Select
            value={carYear}
            onChange={(e: any) => handleSetYear(e)}
            label={t('live_feed_form_year_label')}
            id={'year'}
            searchable
            placeholder={t('live_feed_form_year_label')}
            data={carYears.map((year: any) => {
              return { value: year.year, label: year.year }
            })}
          />
        )}
        {carYears && makes && (
          <Select
            value={make}
            onChange={(e: any) => handleSetMake(e)}
            label={t('live_feed_form_car_id_label')}
            id={'car_make_id'}
            searchable
            placeholder={t('live_feed_form_car_id_placeholder')}
            data={makes.map((year: any) => {
              return { value: year.id, label: year.name }
            })}
          />
        )}
        {make && carModels && carYear && (
          <Select
            value={model}
            id={'car_model_id'}
            onChange={(e: any) => handleSetModel(e)}
            label={t('live_feed_form_car_model_id_label')}
            searchable
            placeholder={t('live_feed_form_car_model_id_placeholder')}
            data={carModels.map((model: any) => {
              return { value: model.id, label: model.name }
            })}
          />
        )}
        {make && model && carTrims && carYear && (
          <Select
            value={carTrim}
            onChange={(e: any) => setCarTrim(e)}
            id={'car_trim_id'}
            searchable
            label={t('car_trim_label')}
            placeholder={t('car_trim_label')}
            data={carTrims.map((trim: any) => {
              return { value: trim.id, label: trim.name }
            })}
          />
        )}
        <TextInputController
          control={control}
          name={'description'}
          id={'description'}
          label={t('live_feed_form_description_label')}
          placeholder={t('live_feed_form_description_placeholder')}
          mb={'md'}
        />
        <Button mt={'lg'} fullWidth type={'submit'} loading={isSubmitting} disabled={!model}>
          {t('edit_live_feed_btn')}
        </Button>
      </form>
    </>
  )
}
