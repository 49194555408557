import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, GeneralCard } from '@/components/Elements'
import { useLazyBillingSubscriptionPaymentLinkQuery } from '@/features/billing/store'
import { useNotify } from '@/hooks'

export const IncompletePayment = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [paymentLinkQuery, { isLoading, error }] = useLazyBillingSubscriptionPaymentLinkQuery()

  const onClick = async () => {
    try {
      const data = await paymentLinkQuery().unwrap()

      window.location.href = data?.url
    } catch (error: any) {
      showNotification({
        type: 'error',
        message: error?.data?.message || t('error'),
      })
    }
  }

  return (
    <GeneralCard title={t('incomplete_payment')}>
      <Alert mb={'lg'} type={'info'}>
        {t('billing.incomplete_payment.message')}
      </Alert>

      <Button onClick={onClick} loading={isLoading}>
        {t('complete_payment')}
      </Button>
    </GeneralCard>
  )
}
