import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    height: 'auto',
    padding: '12px 16px',
    display: 'flex',
    width: '100%',

    [':hover']: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[0],
    },
  },

  rootActive: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[0],
  },

  avatar: {
    marginRight: '16px',
  },

  body: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    padding: '4px 0',
  },

  meta: {
    height: '100%',
  },
}))
