import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModelTypes } from '@/types'
import { Pagination, PaginationProps } from '../Pagination'

interface IProps extends Omit<PaginationProps, 'total' | 'page'> {
  page?: number | null
  meta?: ModelTypes.PaginationResponseMeta
  onChange: (page: number) => void
}

export const MetaPagination = ({
  page = 1,
  meta,
  onChange,
  position = 'right',
  ...props
}: IProps) => {
  const { t } = useTranslation()

  if (!meta) {
    return null
  }

  const { last_page, current_page } = meta

  const total = meta ? meta.last_page : 0

  const visible = last_page > 1 || current_page > last_page

  return (
    <>
      {visible && page && (
        <Pagination {...props} position={position} value={page} total={total} onChange={onChange} />
      )}
    </>
  )
}
