import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { LiveFeedForm } from '@/features/liveFeeds/components/liveFeeds/LiveFeedsContainer/LiveFeedForm'

export const LiveFeed = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'create_live_feed_title'} />

      <PageContent>
        <LiveFeedForm />
      </PageContent>
    </>
  )
}
