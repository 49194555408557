import React from 'react'
import useStyles from './HeaderLayout.styles'

interface IProps {
  children: React.ReactNode
}

export const HeaderLayout = ({ children }: IProps) => {
  const { classes, cx } = useStyles()

  return <div className={cx(classes.root, 'flex flex-wrap gap-4 items-center')}>{children}</div>
}
