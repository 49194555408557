import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { usePrevious } from 'react-use'
import { MessageType } from '@/features/chat/types/models'
import { ScrollbarUtils } from '@/utils'

interface IProps {
  messages: MessageType[]
  messagesLoading: boolean
  messagesIsMoreLoading: boolean
  isGroup: boolean
  scrollRef: any
  messagesRef: any
}

export const useChatMessagesScroll = ({
  messages,
  messagesLoading,
  messagesIsMoreLoading,
  isGroup,
  scrollRef,
  messagesRef,
}: IProps) => {
  const prevLoading = usePrevious(messagesLoading)
  const prevIsMoreLoading = usePrevious(messagesIsMoreLoading)
  const prevMessages = usePrevious(messages)

  // save scroll position before messages rerender
  const scrollPosition = useMemo(() => {
    if (messages.length > 0 && scrollRef.current) {
      return {
        offsetTop: scrollRef.current.scrollTop,
        offsetBottom: scrollRef.current.scrollHeight - scrollRef.current.scrollTop,
        isCloseToBottom: ScrollbarUtils.isCloseToBottom(scrollRef.current, 100),
      }
    }

    return null
  }, [messages])

  useEffect(() => {
    if (messages.length > 0) {
      const scrollToBottom = () => {
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        }
      }

      if (prevLoading === true) {
        if (!isGroup) {
          const list = [...messages].reverse()

          // if has unread message => scroll to unread
          const unreadMessage = _.find(list, { is_my: false, seen: false })
          if (unreadMessage && messagesRef.current[unreadMessage.id]) {
            ScrollbarUtils.scrollToTarget(messagesRef.current[unreadMessage.id], scrollRef.current)
            return
          }
        }

        scrollToBottom()
        return
      }

      if (prevIsMoreLoading === true) {
        if (scrollPosition) {
          ScrollbarUtils.scrollToTarget(
            scrollRef.current.scrollHeight - scrollPosition.offsetBottom,
            scrollRef.current
          )
        }

        return
      }

      if (prevMessages && messages.length !== prevMessages.length) {
        const previousLastMessage = prevMessages[0]
        const currentLastMessage = messages[0]

        const isNewMessage = previousLastMessage && previousLastMessage.id !== currentLastMessage.id

        // scroll to bottom if receive new message and prev position was in bottom and in not my message
        if (isNewMessage && currentLastMessage.is_my === false) {
          if (scrollPosition && scrollPosition.isCloseToBottom) {
            scrollToBottom()

            return
          }
        }

        // scroll to bottom if new message and is my messages
        if (isNewMessage && currentLastMessage.is_my === true) {
          scrollToBottom()
        }
      }
    }
  }, [messages])
}
