import React from 'react'
import { useTranslation } from 'react-i18next'
import { TbPaperclip } from 'react-icons/tb'
import ImageGallery from 'react-image-gallery'
import { Avatar, Box, Flex, Text } from '@mantine/core'
import { ActionIconButton, Card, OverlayBlockLoading } from '@/components/Elements'
import { FieldError } from '@/components/FormElements'
import { FileUtils } from '@/utils'
import './Gallery.scss'

interface IProps {
  name: string
  namePlaceholder?: string
  size?: string | null
  remove?: boolean
  download?: boolean
  onRemove: () => void
  onDownload: () => void
  loading?: boolean
  card?: boolean
  translateParams?: any
  error?: any
  cardProps?: any
  url?: string
  isImg?: boolean
}

export const Attachment = ({
  name,
  namePlaceholder = '',
  size,
  remove,
  download,
  onRemove,
  onDownload,
  loading = false,
  card = true,
  translateParams,
  error,
  cardProps,
  isImg,
  url,
}: IProps) => {
  const { t } = useTranslation()

  const showError = Boolean(error)

  const render = () => (
    <>
      {isImg ? (
        <div style={{ maxHeight: 'auto', maxWidth: 150, marginBottom: 15 }}>
          <ImageGallery
            showNav={false}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton
            showBullets={false}
            items={[{ original: url || '', thumbnail: url }]}
          />
        </div>
      ) : null}
      <Flex>
        <Flex align={'center'} className={'w-full'}>
          <Box mr={'lg'}>
            <Avatar radius="xl" size={'lg'} color={'primary'}>
              <TbPaperclip size="24" />
            </Avatar>
          </Box>
          <Box className={'w-full'}>
            <Text className={'truncate'}>
              <Text c="dimmed" fw={'bold'}>
                {name ? name : namePlaceholder}
              </Text>

              {size && <Text fw={'bold'}>{FileUtils.formatBytes(size)}</Text>}
            </Text>
          </Box>
        </Flex>

        <Flex direction="column" ml={'sm'}>
          {remove && (
            <ActionIconButton
              name={'remove'}
              onClick={onRemove}
              disabled={loading}
              size={'sm'}
              mb={'sm'}
            />
          )}

          {download && (
            <ActionIconButton
              name={'download'}
              onClick={onDownload}
              disabled={loading}
              size={'sm'}
            />
          )}
        </Flex>
      </Flex>

      {!!error && <FieldError error={error} translateParams={translateParams} mt={'sm'} />}
    </>
  )
  return (
    <>
      <OverlayBlockLoading isLoading={loading}>
        {card ? (
          <Card withBorder {...cardProps}>
            {render()}
          </Card>
        ) : (
          render()
        )}
      </OverlayBlockLoading>
    </>
  )
}
