import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input, InputProps, InputWrapperProps } from '@mantine/core'
import { FormUtils } from '@/utils'
import useStyles from './StripeField.styles'

interface IProps extends InputProps {
  id?: string
  label?: any
  options: any
  onChange: (data: any) => void
  meta: any
  component: any
  translateParams?: any
  wrapperProps?: Omit<InputWrapperProps, 'children'>
}

export const StripeField = ({
  id,
  label,
  options,
  onChange,
  meta = {},
  component: Component,
  translateParams,
  wrapperProps,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return (
    <>
      <Input.Wrapper
        // id={id}
        label={label}
        error={showError ? getErrorMessage() : null}
        {...wrapperProps}
      >
        <Input<any>
          id={id}
          size={'md'}
          component={Component}
          options={options}
          onChange={onChange}
          className={classes.inputElement}
        />
      </Input.Wrapper>
    </>
  )
}
