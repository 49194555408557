import { CardNumberElement, IbanElement } from '@stripe/react-stripe-js'
import React, { useReducer } from 'react'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { useLazyBillingStripeSetupIntentQuery } from '@/features/billing/store'

const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

export interface State {
  loading: boolean
  error: any
}

interface Action {
  type: string
  payload?: any
}

const initialState = {
  data: null,
  loading: false,
  error: null,
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: true,
      }
    case 'success':
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case 'error':
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case 'reset':
      return initialState
    default:
      return state
  }
}

type ParamsType = {
  payment_method_type: BILLING_PAYMENT_METHOD_TYPE
  billing_details?: any
}

export const usePaymentMethodSetup = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const [setupIntentQuery, { isLoading, isFetching, error }] =
    useLazyBillingStripeSetupIntentQuery()

  const confirmSetup = async ({
    stripe,
    elements,
    params,
    clientSecret,
  }: {
    stripe: any
    elements: any
    params: ParamsType
    clientSecret: string
  }) => {
    const { payment_method_type, billing_details } = params

    if (payment_method_type === CARD) {
      const cardNumberElement = elements.getElement(CardNumberElement)
      return await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          // billing_details
        },
      })
    } else if (payment_method_type === SEPA_DEBIT) {
      const iban = elements.getElement(IbanElement)
      return await stripe.confirmSepaDebitSetup(clientSecret, {
        payment_method: {
          sepa_debit: iban,
          billing_details: billing_details,
        },
      })
    }

    return null
  }

  const onSetup = async ({
    stripe,
    elements,
    params,
  }: {
    stripe: any
    elements: any
    params: ParamsType
  }): Promise<{ error?: any; setupIntent?: any } | null> => {
    dispatch({ type: 'loading' })

    try {
      const data = await setupIntentQuery().unwrap()

      const clientSecret = data.client_secret

      const result = await confirmSetup({
        stripe,
        elements,
        params,
        clientSecret,
      })

      dispatch({ type: 'success', payload: result })

      return result
    } catch (error) {
      dispatch({ type: 'error', payload: error })

      throw error
    }
  }

  const resetState = () => {
    dispatch({ type: 'reset' })
  }

  return {
    ...state,
    error: error || state.error,
    loading: isLoading || isFetching,
    onSetup,
    resetState,
  }
}
