import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationControl } from '@/components/Controls'
import { Button } from '@/components/Elements'
import { useBillingPaymentMethodUpdateDefaultMutation } from '@/features/billing/store'
import { PaymentMethodModel } from '@/features/billing/types'
import { useNotify } from '@/hooks'

interface IProps {
  data: PaymentMethodModel
  onSuccess?: (data: any) => void
}

export const DefaultControl = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [update, { isLoading }] = useBillingPaymentMethodUpdateDefaultMutation()

  const onConfirm = async () => {
    try {
      await update({ params: { payment_method: data?.id } }).unwrap()
    } catch (error: any) {
      showNotification({
        type: 'error',
        message: error?.data?.message || t('error'),
      })
    }
  }

  return (
    <>
      <ConfirmationControl
        onConfirm={onConfirm}
        render={({ onOpen }) => (
          <Button variant={'outline'} onClick={onOpen} loading={isLoading} size={'xs'} compact>
            {t('default')}
          </Button>
        )}
      />
    </>
  )
}
