import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentMethodModel } from '@/features/billing/types'
import { PaymentUtils } from '@/utils'

interface IProps {
  data: PaymentMethodModel
}

export const CardNumberCell = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { last4 } = data

  return <>{PaymentUtils.paymentCardHiddenNumber(last4)}</>
}
