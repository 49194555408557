import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { TariffModel } from '@/features/billing/types'
import { SubscriptionChangeContainer } from './SubscriptionChangeContainer'

interface IProps {
  tariff: TariffModel
  visible: boolean
  onClose: () => void
  onSuccess?: () => void
}

export const SubscriptionChange = ({ tariff, visible, onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal opened={visible} onClose={onClose} title={t('change_subscription_plan')}>
        <SubscriptionChangeContainer tariff={tariff} onCancel={onClose} onSuccess={onSuccess} />
      </Modal>
    </>
  )
}
