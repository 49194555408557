import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextAreaController } from '@/components/FormElements'

export type NoteFormSectionValues = {
  note: string
}

interface IProps {
  isDisabled?: boolean
}

export const NoteFormSection = ({ isDisabled }: IProps) => {
  const { t } = useTranslation()

  const { control } = useFormContext<NoteFormSectionValues>()

  return (
    <>
      <Grid>
        <Grid.Col md={6}>
          <TextAreaController
            control={control}
            name={'note'}
            id={'note'}
            label={t('note')}
            placeholder={t('note')}
            mb={'md'}
            disabled={isDisabled}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
