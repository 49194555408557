import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { EntryToolbarLayout } from '@/features/chat-ui/components/Layouts/RoomLayout'
import {
  chatConversationMessagesSendPA,
  selectConversationChatId,
  selectConversationParticipantId,
} from '@/features/chat/store'
import { useNotify } from '@/hooks'
import { RootState } from '@/store'
import { EntryForm } from './EntryForm'

interface IProps {
  participantId: number | undefined
  chatId: number | undefined
  messagesLoading: boolean
}

const EntryToolbarComponent = (props: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    try {
      const params = {
        ...values,
      }

      await dispatch(
        chatConversationMessagesSendPA.request({
          userId: props.participantId as number,
          chatId: props.chatId as number,
          params,
        })
      )
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
      throw error
    }
  }

  return (
    <EntryToolbarLayout>
      <EntryForm
        participantId={props.participantId}
        chatId={props.chatId}
        disabled={props.messagesLoading}
        onSubmit={onSubmit}
      />
    </EntryToolbarLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  const { loading: messagesLoading } = state.chat.conversation.messages.list

  return {
    participantId: selectConversationParticipantId(state),
    chatId: selectConversationChatId(state),
    messagesLoading,
  }
}

export const EntryToolbar = connect(mapStateToProps)(EntryToolbarComponent)
