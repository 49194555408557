import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { BsChevronDown } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { Group, Menu, UnstyledButton } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { useLogout } from '@/features/auth/hooks'
import { useUser } from '@/features/user/hooks'
import useStyles from './ProfileMenu.styles'

export const ProfileMenu = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { logout } = useLogout()

  const { user, getName } = useUser()

  return (
    <>
      <Menu width={150}>
        <Menu.Target>
          <UnstyledButton>
            <Group spacing={'xs'}>
              <AvatarProvider avatar={user?.avatar} size={40} radius={40} />

              <span>{getName()}</span>

              <BsChevronDown size={16} />
            </Group>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item component={Link} to={'/profile'}>
            {t('profile')}
          </Menu.Item>
          <Menu.Item component={Link} to={'/invites'}>
            {t('invites')}
          </Menu.Item>
          <Menu.Item component={Link} to={'/billing'}>
            {t('billing')}
          </Menu.Item>
          <Menu.Item onClick={() => logout()}>{t('logout')}</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  )
}
