import { rootApi } from '@/store/api'

export const suppliersApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    suppliersLoad: builder.mutation<any, string>({
      query: (search) => ({
        url: `suppliers${search ? `?filters[q]=${search}` : ''}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useSuppliersLoadMutation } = suppliersApi
