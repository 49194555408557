import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { chatsUsersAPI } from '@/features/chat/api'
import { chatChatsList } from '@/features/chat/store/chats/list/slice'
import { FormTypes } from '@/types'
import {
  CHAT_CONVERSATION_MESSAGES_READ_FAILED,
  CHAT_CONVERSATION_MESSAGES_READ_REQUEST,
  CHAT_CONVERSATION_MESSAGES_READ_SUCCESS,
} from './actionTypes'

type ArgsType = {
  userId: number
  chatId?: number
}

export const chatConversationMessagesReadPA = createPromiseAction(
  CHAT_CONVERSATION_MESSAGES_READ_REQUEST,
  CHAT_CONVERSATION_MESSAGES_READ_SUCCESS,
  CHAT_CONVERSATION_MESSAGES_READ_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

export function* readChatSubmit(action: PromiseAction<string, ArgsType, any>) {
  // eslint-disable-next-line
  try {
    const { userId, chatId } = action.payload

    const response: AxiosResponse = yield call(chatsUsersAPI.seenUserMessages, chatId || 0)

    const { data } = response

    if (chatId) {
      yield put(chatChatsList.updateItem({ id: chatId, unread_count: 0 }))
    }

    return true
    // resolvePromiseAction(action, {})
  } catch (error: any) {
    throw error
    // rejectPromiseAction(action, error.response.data)
  }
}

export function* readSaga() {
  yield takeEvery(chatConversationMessagesReadPA.request, readChatSubmit)
}
