import axios from 'axios'
import Pusher from 'pusher-js'
import { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiSettings } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { Alert, Badge, Box, Button, Card, Group, Image, Switch, Text } from '@mantine/core'
import { Notifications, notifications } from '@mantine/notifications'
import { TableView } from '@/components/Elements'
import { Avatar, ButtonLink, ColorSchemeToggle, LogoIcon, Modal } from '@/components/Elements'
import { useMyFeedsLoadMutation } from '@/features/liveFeeds/store'
import { useToggleFeedPurchaseMutation } from '@/features/liveFeeds/store'
import { useIsVisible, useNotify } from '@/hooks'

export const MyFeedsContainer = () => {
  const navigate = useNavigate()
  const { showNotification } = useNotify()

  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [modalData, setModalData] = useState<any>(null)

  const [loadMyFeeds] = useMyFeedsLoadMutation()
  const [toggleFeedPurchase] = useToggleFeedPurchaseMutation()

  const [feeds, setFeeds] = useState<any>({
    data: [],
    meta: null,
    links: null,
  })
  const [isLoading, setIsLoading] = useState(false)

  const elRef = createRef<HTMLDivElement>()
  const isVisible = useIsVisible(elRef)

  const loadFeeds = async () => {
    setIsLoading(true)

    try {
      const res = await loadMyFeeds(1).unwrap()
      setFeeds(res)
    } catch (e) {
    } finally {
      setIsLoading(false)
    }
  }

  const loadMoreFeeds = async () => {
    const loadPage = feeds?.meta?.current_page

    if (!loadPage) return

    try {
      setIsLoading(true)
      const res = await loadMyFeeds(loadPage + 1).unwrap()

      const mergedData = [...feeds.data, ...res.data]

      const newFeeds = {
        data: mergedData,
        meta: res.meta,
        links: res.links,
      }

      setFeeds(newFeeds)
    } catch (e) {
    } finally {
      setIsLoading(false)
    }
  }

  const handlePurchase = async (feed: any) => {
    const res: any = await toggleFeedPurchase(feed?.id)
    const newFeed = res?.data?.data

    const newFeeds = {
      ...feeds,
      data: [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...feeds?.data.map((feed: { id: any }) => {
          if (newFeed?.id === feed?.id) {
            return newFeed
          }
          return feed
        }),
      ],
    }

    setFeeds(newFeeds)

    showNotification({
      type: 'success',
      message: t('update_successfully'),
    })
  }

  const handleChats = (data: any) => {
    setModalData(data)
    setIsOpen(true)
  }

  useEffect(() => {
    loadFeeds()
  }, [])

  useEffect(() => {
    if (feeds?.data && !isLoading && feeds?.data?.length < feeds?.meta?.total) {
      loadMoreFeeds()
    }
  }, [isVisible])

  const navigateToPostChat = (participant: any, id: any) => {
    navigate('/chats', {
      state: {
        id,
        post: modalData,
        participant,
      },
    })
  }

  return (
    <>
      <Modal opened={isOpen} onClose={() => setIsOpen(false)} header={t('chats')}>
        <Box my={'md'}>
          {modalData?.chats?.length
            ? modalData.chats.map((chat: any) => (
                <Button
                  onClick={() => navigateToPostChat(chat.participant, chat?.id)}
                  variant="outline"
                  fullWidth
                  mt="md"
                  radius="md"
                >
                  {t('open_post_chat')}:{' '}
                  <Avatar
                    style={{ marginLeft: 10, marginRight: 10 }}
                    size={30}
                    radius={50}
                    src={chat.participant?.avatar?.thumb || undefined}
                  />
                  {`${chat.participant?.first_name} ${chat.participant?.last_name}`}
                </Button>
              ))
            : null}
        </Box>
      </Modal>

      <div className={'flex justify-between items-center'}>
        <h2 className={'m-0'}>{t('my_listing_title')}</h2>
      </div>

      {isLoading ? <p>{t('loading')}</p> : null}

      {feeds?.meta?.total === 0 && !isLoading ? <p>{t('feeds_not_found')}</p> : null}
      {feeds?.meta?.total !== 0 && !isLoading ? (
        <Text weight={500}>
          {t('live_feeds_total')}: {feeds?.meta?.total}
        </Text>
      ) : null}

      {feeds?.data?.map((feed: any) => (
        <Card key={feed.id} shadow="sm" padding="0" radius="md" className="mt-2" withBorder>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', maxWidth: '80%' }}
            >
              {feed?.mainPhoto?.uri ? (
                <Image
                  src={feed?.mainPhoto?.uri}
                  height={110}
                  fit="cover"
                  width={200}
                  alt={`${feed.make} ${feed.model} | ${feed.year}`}
                />
              ) : (
                <Image
                  src={feed?.makeUrl}
                  height={110}
                  fit="contain"
                  width={200}
                  alt={`${feed.make} ${feed.model} | ${feed.year}`}
                />
              )}
              <div style={{ marginLeft: 10, maxWidth: '90%', padding: '10px 0' }}>
                <Text weight={500}>
                  {feed.year} {feed.make} {feed.model} {feed.trim}
                </Text>
                <Text size="sm">{feed.description}</Text>
                <Text size="sm" color="dimmed">
                  {feed?.user?.company_name || feed?.user?.first_name}{' '}
                  {feed?.user?.contact_name || feed?.user?.last_name}
                </Text>
              </div>
            </div>

            <div style={{ padding: 10 }}>
              <Badge color="pink" variant="light">
                {feed.createdAt}
              </Badge>

              {feed?.isNew ? (
                <Badge color="green" variant="light">
                  {t('new_feed_badge')}
                </Badge>
              ) : null}
              <Switch
                style={{ marginTop: 10 }}
                label={t('mark_purchased')}
                checked={feed?.markPurchased}
                onChange={(e) => handlePurchase(feed)}
              />
              <ButtonLink mt={'md'} to={`edit/${feed?.id}`} fullWidth variant={'outline'}>
                {t('edit_btn')}
              </ButtonLink>
              <ButtonLink mt={'md'} to={`details/${feed?.id}`} fullWidth variant={'outline'}>
                {t('detailed_btn')}
              </ButtonLink>
              {feed?.chats?.length ? (
                <Button mt={'md'} onClick={() => handleChats(feed)} fullWidth>
                  {t('show_chats')}: {feed?.chats?.length}
                </Button>
              ) : null}
            </div>
          </div>
        </Card>
      ))}
      <div ref={elRef}></div>
    </>
  )
}
