import React from 'react'
import { useMantineTheme } from '@mantine/core'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { IbanStripeField } from '@/features/stripe/components/FormElements'
import { CardStripeGroupFields } from '@/features/stripe/components/FormSections'

const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

interface IProps {
  type: BILLING_PAYMENT_METHOD_TYPE
  onChange: (state: any) => void
  state: any
}

export const PaymentMethodForm = ({ type, ...props }: IProps) => {
  const theme = useMantineTheme()

  const style = {
    // For more styling details, see https://stripe.com/docs/js/appendix/style
    base: {
      fontSize: '16px',
      fontWeight: '400',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      ...theme.fn.fontStyles(),
      '::placeholder': {
        color: theme.fn.placeholderStyles().color,
      },
    },
    invalid: {
      color: theme.fn.variant({ variant: 'filled', color: 'red' }).background,
    },
  }

  switch (type) {
    case CARD:
      return <CardStripeGroupFields elementStyle={style} {...props} />
    case SEPA_DEBIT:
      return <IbanStripeField elementStyle={style} {...props} />
    default:
      return null
  }
}
