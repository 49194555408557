import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { chatSocketOnChatSeen } from '@/features/chat/store'
import { ECHO_EVENT } from '@/features/socket/consts/echo'
import { useEchoSubscriptionEffect } from '@/features/socket/hooks'
import { useUser } from '@/features/user/hooks'

interface IProps {
  children: React.ReactNode
}

export const ChatSocketMiddleware = ({ children }: IProps) => {
  const dispatch = useDispatch()

  const { user } = useUser()

  const channel = `user.${user.id}`

  const events = useMemo(
    () => ({
      // on chat seen
      [ECHO_EVENT.CHAT_SEEN_EVENT]: (data: any) => dispatch(chatSocketOnChatSeen(data)),
    }),
    [dispatch]
  )

  useEchoSubscriptionEffect({ channel, events })

  return <>{children}</>
}
