import * as Yup from 'yup'
import { paymentMethodHelper } from '@/features/billing/helpers'
import { PAYMENT_METHOD_MODE } from './consts'

const { isCardType, isSepaType } = paymentMethodHelper.general

const { EXISTING, NEW } = PAYMENT_METHOD_MODE

export const validationSchema = Yup.object().shape({
  payment_method: Yup.string().nullable(),

  card: Yup.mixed()
    .nullable()
    .when(['payment_method_type', '$paymentMethodMode'], (...args: any) => {
      const [payment_method_type, paymentMethodMode, schema] = args
      return isCardType(payment_method_type) && paymentMethodMode === EXISTING
        ? schema.required('field.error.required')
        : schema
    }),

  iban: Yup.mixed()
    .nullable()
    .when(['payment_method_type', '$paymentMethodMode'], (...args: any) => {
      const [payment_method_type, paymentMethodMode, schema] = args
      return isSepaType(payment_method_type) && paymentMethodMode === EXISTING
        ? schema.required('field.error.required')
        : schema
    }),
  name: Yup.string()
    .nullable()
    .when(['payment_method_type', '$paymentMethodMode'], (...args: any) => {
      const [payment_method_type, paymentMethodMode, schema] = args
      return isSepaType(payment_method_type) && paymentMethodMode === NEW
        ? schema.required('field.error.required')
        : schema
    }),
  email: Yup.string()
    .email('field.error.invalid')
    .nullable()
    .when(['payment_method_type', '$paymentMethodMode'], (...args: any) => {
      const [payment_method_type, paymentMethodMode, schema] = args
      return isSepaType(payment_method_type) && paymentMethodMode === NEW
        ? schema.required('field.error.required')
        : schema
    }),
  address: Yup.string()
    .nullable()
    .when(['payment_method_type', '$paymentMethodMode'], (...args: any) => {
      const [payment_method_type, paymentMethodMode, schema] = args
      return isSepaType(payment_method_type) && paymentMethodMode === NEW
        ? schema.required('field.error.required')
        : schema
    }),
})
