import React from 'react'
import { Navigate } from 'react-router-dom'
import { useUser } from '@/features/user/hooks'
import { userData } from '@/features/user/store'
import { ROLE } from '@/features/users/types'
import { useFromPath } from '@/hooks'
import { PRIVAT_INITIAL_ROUTE } from '@/routes/const'
import { GUARD } from '../../guards'

interface IProps {
  children: React.ReactNode
  roles?: Array<string>
  guards?: Array<GUARD>

  [x: string]: any
}

export const RouteGuard: React.FC<IProps> = ({ children, roles, guards = [], ...props }) => {
  const from = useFromPath()

  const { user } = useUser()
  const withGuard = (type: GUARD) => {
    return guards.some((guard) => guard === type)
  }

  let expired = false
  const currentDate = new Date().getTime()

  if (user?.trial_ends_at && user?.subscription?.tariff?.stripe_price !== 'VIP') {
    const userTrialDate = new Date(user.trial_ends_at).getTime()

    if (userTrialDate < currentDate) {
      expired = true
    }
  }

  if (expired && !window.location.pathname.includes('/billing')) {
    window.subModalStatus = true
    return <Navigate to={'/billing'} />
  } else if (roles && user && !roles.includes(user.role)) {
    const path = PRIVAT_INITIAL_ROUTE
    return <Navigate to={path} state={{ from }} />
  }

  return <>{children}</>
}
