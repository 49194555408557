import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '@mantine/core'
import { GeneralCard } from '@/components/Elements'
import { useBilling, useTariffStaticOptions } from '@/features/billing/hooks'
import { PaymentInfoType } from '@/features/billing/types/shared'
import { CommonUtils } from '@/utils'
import useStyles from './Table.styles'

interface IProps {
  data: PaymentInfoType
}

export const PaymentInfo = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { tariff, price } = data ?? {}

  const { isAnnual } = useTariffStaticOptions()

  const { getCurrency } = useBilling()

  return (
    <>
      <GeneralCard title={t('payment_information')}>
        <div className={'overflow-x-auto'}>
          <Table className={classes.table}>
            <tbody>
              {tariff && (
                <>
                  <tr>
                    <th scope="row">{t('subscription_plan')}:</th>
                    <td>{tariff?.name}</td>
                  </tr>

                  <tr>
                    <th scope="row">{t('billed_period')}:</th>
                    <td>{isAnnual(tariff?.period) ? t('annually') : t('monthly')}</td>
                  </tr>
                </>
              )}

              {!CommonUtils.isNil(price) && (
                <>
                  <tr>
                    <th scope="row">{t('price')}:</th>
                    <td>
                      {price} {getCurrency()?.symbol}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </div>
      </GeneralCard>
    </>
  )
}
