import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    display: 'flex',
    minWidth: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 700,
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '1000px',
    color: theme.white,
    backgroundColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
    whiteSpace: 'nowrap',
  },

  active: {
    // color: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
    // backgroundColor: theme.white,
  },
}))
