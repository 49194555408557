import React, { useState } from 'react'

export const usePopup = () => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState<any>(null)

  const open = (data: any) => {
    data && setData(data)
    setVisible(true)
  }

  const close = () => {
    setVisible(false)

    resetData()
  }

  const resetData = () => {
    setData(null)
  }
  return { data, visible, open, close, resetData }
}
