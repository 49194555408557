import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { billingBillingAddressSocketTaxIdOnUpdate } from '@/features/billing/store'
import { chatSocketOnMessage } from '@/features/chat/store'
import { ECHO_EVENT } from '@/features/socket/consts/echo'
import { useEcho } from '@/features/socket/hooks'
import { useUser } from '@/features/user/hooks'
import { userSocketSubscriptionOnSubscriptionUpdate } from '@/features/user/store'

interface IProps {
  children: React.ReactNode
}

export const SocketMiddleware = ({ children }: IProps) => {
  const dispatch = useDispatch()

  const echo = useEcho()

  const { user } = useUser()

  useEffect(() => {
    echo.create()

    if (user?.id) {
      echo.privateChannelAddListeners(`user.${user.id}`, {
        // on chat received message
        [ECHO_EVENT.CHAT_MESSAGE_EVENT]: (data: any) => dispatch(chatSocketOnMessage(data)),

        // on subscription updated
        [ECHO_EVENT.UPDATE_SUBSCRIPTION_EVENT]: (data: any) =>
          dispatch(userSocketSubscriptionOnSubscriptionUpdate(data)),

        // on billing address tax id status changed
        [ECHO_EVENT.UPDATE_TAX_ID_EVENT]: (data: any) =>
          dispatch(billingBillingAddressSocketTaxIdOnUpdate(data)),
      })
    }

    return () => {
      echo.channelLeaveAll()

      echo.destroy()
    }
  }, [])

  return <>{children}</>
}
