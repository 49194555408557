import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { PaymentMethodForm } from '@/features/stripe/components/FormSections'

interface IProps {
  state: any
  onChange: (state: any) => void
  onDestroy: () => void
}

export const CardFormSection = ({ onDestroy, ...props }: IProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      onDestroy()
    }
  }, [])

  return (
    <>
      <PaymentMethodForm type={BILLING_PAYMENT_METHOD_TYPE.CARD} {...props} />
    </>
  )
}
