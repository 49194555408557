import React, { useState } from 'react'

interface IProps {
  initialState: object
}

export const useStripeForm = ({ initialState }: IProps) => {
  const [state, setState] = useState<any>(initialState)

  const isValid = Object.keys(state).every((key) => state[key]?.complete === true)

  const onChange = (data: any) => {
    setState((prevState: any) => ({
      ...prevState,
      [data.elementType]: data,
    }))
  }

  const onStateSet = (state: any) => {
    setState(state)
  }

  const reset = () => {
    setState(initialState)
  }

  return {
    state,
    isValid,
    onChange,
    onStateSet,
    reset,
  }
}
