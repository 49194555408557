import React from 'react'
import { useTranslation } from 'react-i18next'
import { BILLING_SUBSCRIPTION_STATUS } from '@/features/billing/consts/subscription'
import { subscriptionHelper } from '@/features/billing/helpers'

export const useSubscriptionStaticOptions = () => {
  const { t } = useTranslation()

  const getStatus = (value: BILLING_SUBSCRIPTION_STATUS) => {
    return subscriptionHelper.status.getStatus(value)
  }

  const isActiveStatus = (value: BILLING_SUBSCRIPTION_STATUS) => {
    return subscriptionHelper.status.isActive(value)
  }

  const isVIPStatus = (value: number | null | undefined) => {
    return subscriptionHelper.status.isVIP(value)
  }

  const isPausedStatus = (value: BILLING_SUBSCRIPTION_STATUS) => {
    return subscriptionHelper.status.isPaused(value)
  }

  const isIncompleteStatus = (value: BILLING_SUBSCRIPTION_STATUS) => {
    return subscriptionHelper.status.isIncomplete(value)
  }

  return {
    getStatus,
    isActiveStatus,
    isPausedStatus,
    isIncompleteStatus,
    isVIPStatus,
  }
}
