import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '@mantine/core'
import { Box, Space } from '@mantine/core'
import { InvoiceModel } from '@/features/billing/types'
import { Header, Info, Summary } from './Blocks'

interface IProps {
  data: InvoiceModel
  contentRef: any
}

export const InvoiceContent = ({ data, contentRef }: IProps) => {
  const { t } = useTranslation()

  return (
    <div ref={contentRef}>
      <Header data={data} />

      <Divider my={'lg'} />

      <Info data={data} />

      <Space h={'xl'} />

      <Box pt={'xl'}>
        <Summary data={data} />
      </Box>
    </div>
  )
}
