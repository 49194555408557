import React from 'react'
import { Participant } from '@/features/chat/types/models'
import { ROLE, UserModel } from '@/features/users/types'

export const useUsersModel = () => {
  const isClient = (user: UserModel) => {
    return user?.role === ROLE.Client || ROLE.SubClient
  }

  const getName = (user: UserModel | Participant | null | undefined | any) => {
    if (user) {
      if (user?.name) {
        return user?.name
      }

      if (user?.company_name || user?.contact_name) {
        let result = user?.company_name

        if (user?.contact_name) {
          result = result ? `${result} ${user?.contact_name}` : user?.contact_name
        }

        return result
      }

      let result = user?.first_name

      if (user?.last_name) {
        result = result ? `${result} ${user?.last_name}` : user?.last_name
      }

      return result
    }
    return null
  }

  return {
    isClient,
    getName,
  }
}
