import { PayloadAction } from '@reduxjs/toolkit'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'
import { showNotification } from '@mantine/notifications'
import { BILLING_SUBSCRIPTION_STATUS } from '@/features/billing/consts/subscription'
import { User } from '@/features/user'
import { selectUserData, userData } from '@/features/user/store'
import i18n from '@/i18n'
import { RootState } from '@/store'
import { userSocketSubscriptionOnSubscriptionUpdate } from './actions'

const { ACTIVE, TRIALING, INCOMPLETE } = BILLING_SUBSCRIPTION_STATUS

const userDataState = (state: RootState) => selectUserData(state)

function* onUpdate({ payload: { data } }: PayloadAction<{ data: any }>) {
  const { subscription } = data

  const user: User = yield select(userDataState)

  const userSubscription = user?.subscription

  let message = ''

  if (subscription) {
    const { status } = subscription ?? {}
    if (status === ACTIVE) {
      if (userSubscription === null || userSubscription?.status === TRIALING) {
        // if current no sub or was trialing and pay new subscription
        message = 'subscribed_successfully'
      } else if (
        userSubscription?.status === ACTIVE &&
        userSubscription?.tariff?.id !== subscription?.tariff?.id
      ) {
        // if swap subscription
        message = 'subscription_plan_changed'
      }
    } else if (
      status === INCOMPLETE &&
      (userSubscription === null || userSubscription?.status === TRIALING)
    ) {
      // if current no sub or trialing and pay new subscription with 3d-secure payment method
      message = 'subscribed_successfully'
    }
  }

  if (message) {
    showNotification({ color: 'green', message: i18n.t(message) })
  }

  // update user subscription
  yield put(userData.updateSubscription(subscription))
}

function* watchOnUpdate() {
  yield takeEvery(userSocketSubscriptionOnSubscriptionUpdate, onUpdate)
}

export function* subscriptionSaga() {
  yield all([fork(watchOnUpdate)])
}
