import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FilesUploaderControlController } from '@/components/FormElements'
import { chatConversationAttachmentUploadPA } from '@/features/chat/store'
import { FileUtils } from '@/utils'

interface IProps {
  control: any
  addFileToProcessing: (index: number) => void
  removeFileFromProcessing: (index: number) => void
  setError: (...arg: any) => void
  trigger: (...arg: any) => Promise<boolean>
  fileMaxSize: number
  processing?: any[]
}

export const AttachmentsSection = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const onUploadError = (field: any, payload: any) => (errors: any) => {
    const { index, fieldName } = payload

    props.removeFileFromProcessing(index)

    const message = errors?.['file'] ? errors?.['file'].join(' ') : t('error')

    props.setError(fieldName, {
      type: 'server',
      message: message,
    })
  }

  const onUploadSuccess = (field: any, payload: any) => (data: any) => {
    const { index } = payload

    props.removeFileFromProcessing(index)

    const { onSuccess } = payload
    onSuccess(
      {
        ...field,
        id: data?.id,
        attachment: {
          src: data?.url,
        },
      },
      index
    )
  }

  const handleUpload = async (fileData: any, payload: any) => {
    const { index } = payload

    props.addFileToProcessing(index)
    const params = {
      file: fileData?.file,
    }

    try {
      const data = await dispatch(
        chatConversationAttachmentUploadPA.request({
          params,
        })
      )

      onUploadSuccess(fileData, payload)(data)
    } catch (error: any) {
      onUploadError(fileData, payload)(error)
    }
  }

  const onRemoveError = (field: any, payload: any) => () => {
    const { index } = payload
    props.removeFileFromProcessing(index)
  }

  const onRemoveSuccess = (field: any, payload: any) => (data: any) => {
    const { index } = payload

    props.removeFileFromProcessing(index)

    const { onSuccess } = payload
    onSuccess(
      {
        ...field,
      },
      index
    )
  }

  const handleRemove = (fileData: any, payload: any) => {
    const { index } = payload

    props.addFileToProcessing(index)

    onRemoveSuccess(fileData, payload)(null)
  }

  const onValidate = async (name: string, onValid: any) => {
    const result = await props.trigger(name)
    if (result && onValid) {
      onValid()
    }
  }

  const onDownload = (field: any) => {
    FileUtils.downloadFileByLink(field?.attachment?.src)
  }

  return (
    <FilesUploaderControlController
      control={props.control}
      id={'files'}
      name={'files'}
      pickerLabelText={t('attach-files')}
      onUpload={handleUpload}
      onRemove={handleRemove}
      onDownload={onDownload}
      processing={props.processing}
      onValidate={onValidate}
      accept="image/png, image/jpeg, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
      translateParams={{ text: `${FileUtils.formatBytes(props.fileMaxSize)}` }}
      filesUploaderRef={null}
    />
  )
}
