import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { BillingPanelShell } from '@/features/billing/components/BillingPanel/Layouts'
import { BillingInformation, General, InvoicesRoot, PaymentMethods, Subscriptions } from '../panel'

export const PanelRoot = () => {
  return (
    <Routes>
      <Route element={<BillingPanelShell />}>
        <Route index element={<General />} />
        <Route path={'subscriptions'} element={<Subscriptions />} />
        <Route path={'billing-information'} element={<BillingInformation />} />
        <Route path={'payment-methods'} element={<PaymentMethods />} />
        <Route path={'invoices/*'} element={<InvoicesRoot />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
