import * as Yup from 'yup'
import { CHAT_FILE_MAX_SIZE } from '@/const/form'
import { ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object().shape({
  files: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().test('maxSize', 'field.error.file-size.max', (value) =>
        ValidationsUtils.fileMaxSize(value, CHAT_FILE_MAX_SIZE)
      ),
    })
  ),
  body: Yup.string()
    .nullable()
    .trim()
    .when('files', {
      is: (files: any[]) => files.length === 0,
      then: Yup.string().trim().required('field.error.required'),
    }),
})
