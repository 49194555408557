import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useUser } from '@/features/user/hooks'

interface IProps {
  isLoggedIn: boolean

  [x: string]: any
}

export const AuthGuard: React.FC<IProps> = ({ children, ...props }) => {
  const location = useLocation()

  const user = useUser()
  const { isLoggedIn } = props

  if (isLoggedIn) {
    if (user?.user?.role === 'supplier') {
      return <Navigate to={'/live-feeds'} state={{ from: location }} />
    }

    return <Navigate to={'/'} state={{ from: location }} />
  }

  return <Outlet />
}
