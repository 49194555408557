import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Invoice } from '../invoice'
import { Invoices } from '../invoices'

export const InvoicesRoot = () => {
  return (
    <Routes>
      <Route index element={<Invoices />} />

      <Route path={':id/*'} element={<Invoice />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
