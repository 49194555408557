import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationControl } from '@/components/Controls'
import { Button } from '@/components/Elements'
import { useBillingSubscriptionResumeMutation } from '@/features/billing/store'
import { useNotify } from '@/hooks'

export const ResumeControl = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [resume, { isLoading }] = useBillingSubscriptionResumeMutation()

  const onConfirm = async () => {
    try {
      await resume().unwrap()

      showNotification({
        type: 'success',
        message: t('subscribed_resumed'),
      })
    } catch (error: any) {
      showNotification({
        type: 'error',
        message: error?.data?.message || t('error'),
      })
    }
  }

  return (
    <>
      <ConfirmationControl
        onConfirm={onConfirm}
        render={({ onOpen }) => (
          <Button size={'xs'} compact onClick={onOpen} loading={isLoading}>
            {t('resume')}
          </Button>
        )}
      />
    </>
  )
}
