import _ from 'lodash'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: BILLING_PAYMENT_METHOD_TYPE
}

export const getTypes = () => {
  return [
    {
      label: i18n.t('card'),
      value: BILLING_PAYMENT_METHOD_TYPE.CARD,
    },
    {
      label: i18n.t('sepa'),
      value: BILLING_PAYMENT_METHOD_TYPE.SEPA_DEBIT,
    },
  ]
}

export const getType = (value: OptionType | string): OptionType | any => {
  const options = getTypes()
  return _.find(options, { value })
}

export const isCardType = (value: BILLING_PAYMENT_METHOD_TYPE): boolean => {
  return value === BILLING_PAYMENT_METHOD_TYPE.CARD
}

export const isSepaType = (value: BILLING_PAYMENT_METHOD_TYPE): boolean => {
  return value === BILLING_PAYMENT_METHOD_TYPE.SEPA_DEBIT
}
