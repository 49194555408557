import { rootApi } from '@/store/api'

export const invitesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    invitesLoad: builder.mutation<any, any>({
      query: (arg: any) => ({
        url: `invites`,
        method: 'GET',
      }),
    }),
    invitesCreate: builder.mutation<any, any>({
      query: (arg) => ({
        url: 'invites',
        method: 'POST',
        body: arg,
      }),
    }),
    invitesDelete: builder.mutation<any, any>({
      query: (arg) => ({
        url: `invites/${arg?.hash}`,
        method: 'DELETE',
      }),
    }),
    invitesAccept: builder.mutation<any, any>({
      query: (arg) => ({
        url: `invites/${arg}/accept`,
        method: 'GET',
      }),
    }),
    invitesReject: builder.mutation<any, any>({
      query: (arg) => ({
        url: `invites/${arg}/reject`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useInvitesLoadMutation,
  useInvitesCreateMutation,
  useInvitesAcceptMutation,
  useInvitesRejectMutation,
  useInvitesDeleteMutation,
} = invitesApi
