import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Title } from '@mantine/core'
import { BackButton } from '@/components/Elements'

interface IProps {
  back?: boolean
  onBack?: () => void
  title?: React.ReactNode
  rightSection?: React.ReactNode
}

export const PageHeader = ({ back, onBack, title, rightSection }: IProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleBack = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (onBack) {
      onBack()
    } else {
      navigate(-1)
    }
  }

  const renderTitle = () => {
    if (typeof title === 'string') {
      return <Title order={4}>{title}</Title>
    }

    return title
  }

  return (
    <div className={'flex mb-4 items-center justify-between'}>
      <div className={'flex'}>
        {back && <BackButton />}

        {title && renderTitle()}
      </div>

      {rightSection && <div>{rightSection}</div>}
    </div>
  )
}
