import classNames from 'classnames'
import React, { Ref, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TbPaperclip } from 'react-icons/tb'
import { Box } from '@mantine/core'
import { FilesUploaderControlProps } from '@/components/Controls'
import { Button } from '@/components/Elements'
import { FilesUploaderFile } from './FilesUploaderFile'

type FieldValueType = {
  id?: number
  attachment?: any
  file: File
}

// eslint-disable-next-line
interface IProps
  extends Omit<
    FilesUploaderControlProps,
    'onChange' | 'filesUploaderRef' | 'onRemove' | 'onUpload' | 'onDownload'
  > {
  onAdd: (files: File[]) => void
  fileFieldName?: string
  onRemove: (item: FieldValueType, index: number, fieldName: string) => void
  onUpload: (item: FieldValueType, index: number, fieldName: string) => void
  onDownload: (item: FieldValueType) => void
}

export const FilesUploaderContainer = ({
  id,
  pickerLabelText,
  className = '',
  value = [],
  meta = {},
  accept,
  onRemove,
  onAdd,
  onUpload,
  onDownload,
  name = 'files',
  fileFieldName = 'file',
  limit,
  disabled,
  processing = [],
  picker = true,
  ...props
}: IProps) => {
  const { error } = meta

  const showError = Boolean(error)

  const { t } = useTranslation()

  const inputRef: Ref<any> = useRef()

  const handleFilesPicked = (event: any) => {
    const files = event.target.files

    onAdd(files)

    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const pickFiles = (event: any) => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const renderPicker = () => (
    <Box my={'lg'}>
      <input
        type="file"
        id={id}
        className="hidden"
        onChange={handleFilesPicked}
        ref={inputRef}
        accept={accept}
        multiple
      />

      <Button variant={'subtle'} onClick={pickFiles} leftIcon={<TbPaperclip size="24" />}>
        {pickerLabelText}
      </Button>
    </Box>
  )

  const removeFile = (field: FieldValueType, fieldIndex: number) => {
    const fieldName = getFileFieldName(field, fieldIndex)
    onRemove(field, fieldIndex, fieldName)
  }

  const uploadFile = (field: FieldValueType, fieldIndex: number) => {
    const fieldName = getFileFieldName(field, fieldIndex)
    onUpload(field, fieldIndex, fieldName)
  }

  const getFileFieldName = (item: FieldValueType, index: number) =>
    `${name}.${index}.${fileFieldName}`

  return (
    <div
      className={classNames({
        [className]: className,
      })}
    >
      <div className={classNames({})}>
        <div>
          <div>
            {!!value.length &&
              value.map((item, index) => (
                <Box my={'sm'} key={index}>
                  <div>
                    <FilesUploaderFile
                      value={item.file}
                      id={getFileFieldName(item, index)}
                      name={getFileFieldName(item, index)}
                      onRemove={() => removeFile(item, index)}
                      onUpload={() => uploadFile(item, index)}
                      download={Boolean(item?.id)}
                      onDownload={() => onDownload(item)}
                      loading={processing.includes(index)}
                      onValidate={props.onValidate}
                      item={item}
                      meta={{ error: error?.[index]?.[fileFieldName] }}
                      translateParams={props.translateParams}
                    />
                  </div>
                </Box>
              ))}
          </div>

          {picker ? (limit ? value.length < limit && renderPicker() : renderPicker()) : null}
        </div>
      </div>
    </div>
  )
}
