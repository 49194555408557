import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableProps } from '@mantine/core'
import { GeneralTable } from '@/components/Elements'

interface IProps {
  columns: any[]
  data: any[]
  loading: boolean
  tableProps?: TableProps
  state?: any
}

export const TableView = ({ columns, data, loading, tableProps, state }: IProps) => {
  const { t } = useTranslation()

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state,
  })

  return (
    <div className={'relative overflow-x-auto'}>
      <GeneralTable {...tableProps}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const __headerProps = (header.column.columnDef as any)?.__headerProps

                return (
                  <th key={header.id} {...__headerProps}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>
                {!loading ? t('empty') : <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
              </td>
            </tr>
          )}
        </tbody>
      </GeneralTable>
    </div>
  )
}
