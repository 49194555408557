import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { FileInput, Select } from '@mantine/core'
import { Anchor, Title } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import {
  CheckboxFieldController,
  PasswordInputController,
  TextInputController,
} from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import {
  useLiveFeedsCreateMutation,
  useLiveFeedsModelsLoadMutation,
} from '@/features/liveFeeds/store'
import { useNotify } from '@/hooks'

export const LiveFeed: React.FC = () => {
  const { t } = useTranslation()

  const [loadLiveFeedsModels] = useLiveFeedsModelsLoadMutation()

  useEffect(() => {}, [])

  return (
    <>
      <h2 className={'m-0'}>{t('live_feed_page_title')}</h2>
    </>
  )
}
