import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Group, Footer as MantineFooter } from '@mantine/core'
import { ButtonLink } from '@/components/Elements'
import { APP_NAME } from '@/const/core'
import { LANDING_FOOTER_HEIGHT } from '@/const/layout'
import useStyles from './Footer.styles'

export const Footer = () => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  return (
    <>
      <MantineFooter className={classes.root} height={LANDING_FOOTER_HEIGHT} zIndex={1}>
        <Container className={classes.wrap}>
          <Group className={'w-full flex-nowrap'}>
            <div>
              © {new Date().getFullYear()} {APP_NAME}. {t('all_rights_reserved')}.
            </div>
            <ButtonLink to={'/privacy-policy'} variant={'subtle'}>
              {t('privacy_policy')}
            </ButtonLink>
          </Group>
        </Container>
      </MantineFooter>
    </>
  )
}
