import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { CheckboxFieldController } from '@/components/FormElements'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { PaymentMethodForm } from '@/features/stripe/components/FormSections'
import { useStripeForm } from '@/features/stripe/hooks'
import { useFormSubmit } from '@/hooks'
import { validationSchema } from './validation'

interface IProps {
  stripe: any
  elements: any
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
}

export const CardForm = ({ stripe, elements, onCancel, ...props }: IProps) => {
  const { t } = useTranslation()

  const stripeInitialState = {
    cardNumber: undefined,
    cardExpiry: undefined,
    cardCvc: undefined,
  }

  const stripeForm = useStripeForm({
    initialState: stripeInitialState,
  })

  const methods = useForm({
    defaultValues: {
      default: false,
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const isDisabled = useMemo(() => {
    return !stripe || !elements || !stripeForm.isValid
  }, [stripe, elements, stripeForm.isValid])

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      if (isDisabled) {
        return
      }

      const values = {
        ...data,
        payment_method_type: BILLING_PAYMENT_METHOD_TYPE.CARD,
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <PaymentMethodForm
            state={stripeForm.state}
            type={BILLING_PAYMENT_METHOD_TYPE.CARD}
            onChange={stripeForm.onChange}
          />

          <CheckboxFieldController
            control={control}
            name={'default'}
            id={'default'}
            label={t('default')}
            mb={'md'}
          />

          <div className={'mt-4 pt-4'}>
            <Group>
              <Button variant="outline" color="dark" onClick={onCancel}>
                {t('cancel')}
              </Button>

              <Button loading={isSubmitting} type={'submit'} disabled={isDisabled}>
                {t('save')}
              </Button>
            </Group>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
