import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useAppState } from '@/features/app/hooks'
import { MessagesLayout } from '@/features/chat-ui/components/Layouts/RoomLayout'
import { MessageItem, MessagesList } from '@/features/chat-ui/components/Messages'
import { MessageItemType, useMessages } from '@/features/chat-ui/hooks'
import { useChatMessagesScroll } from '@/features/chat/hooks'
import {
  chatConversationMessagesList,
  selectConversationChatId,
  selectConversationParticipantId,
} from '@/features/chat/store'
import { MessageType } from '@/features/chat/types/models'
import { useNotify } from '@/hooks'
import { RootState } from '@/store'

interface IProps {
  list: MessageType[]
  loading: boolean
  error: any
  isMoreLoading: boolean
  meta: any
  participantId: number | undefined
  chatId: number | undefined
  isGroup: boolean
}

const MessagesContainerComponent = (props: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { showNotification } = useNotify()

  const messagesRef = useRef<any>([])
  const scrollRef = useRef<any>(null)

  // messages scroll logic (scroll to unread message and etc.)
  useChatMessagesScroll({
    messages: props.list,
    messagesLoading: props.loading,
    messagesIsMoreLoading: props.isMoreLoading,
    isGroup: props.isGroup,
    scrollRef,
    messagesRef,
  })

  useEffect(() => {
    fetchData({})

    return () => {
      dispatch(chatConversationMessagesList.cleanState())
    }
  }, [props.participantId, props.chatId])

  useEffect(() => {
    if (props.error) {
      const message = props.error || t('error')
      showNotification({ type: 'error', message: message })
    }
  }, [props.error])

  const {
    appState: { windowFocus },
  } = useAppState()

  useEffect(() => {
    // if app set in background
    if (!windowFocus) {
      // mark received messages seen
      dispatch(chatConversationMessagesList.markReceivedMessagesSeen())
    }
  }, [windowFocus])

  const fetchData = (params = {}, state = {}) => {
    const payload = {
      userId: props.participantId,
      chatId: props.chatId,
      params,
      state,
    }
    dispatch(chatConversationMessagesList.getList(payload))
  }

  const hasMore = useMemo(() => {
    const { meta } = props

    if (!meta) {
      return false
    }

    const { current_first_id, chat_first_id } = meta

    return current_first_id && chat_first_id && current_first_id !== chat_first_id
  }, [props.meta])

  const onLoadMore = () => {
    if (!hasMore) {
      return
    }

    const { meta } = props

    if (hasMore && !props.loading && !props.isMoreLoading) {
      fetchData({ last_message_id: meta.current_first_id }, { isMoreLoading: true })
    }
  }

  const { getMessagesItems } = useMessages()

  const renderMessages = () => {
    if (messagesRef.current) {
      messagesRef.current = []
    }

    const messages: MessageItemType[] = getMessagesItems(props.list)

    return messages.map(({ message, day, unread }) => (
      <MessageItem
        item={message}
        key={`message-${message.id}`}
        day={day}
        unread={unread}
        avatar={false}
        forwardRef={(el: any) => (messagesRef.current[message.id] = el)}
        alignRight={message.is_my}
        messageProps={{
          config: {
            seen: message.is_my,
          },
        }}
      />
    ))
  }

  return (
    <MessagesLayout>
      <MessagesList
        scrollRef={scrollRef}
        items={props.list}
        loading={props.loading}
        isMoreLoading={props.isMoreLoading}
        onLoadMore={onLoadMore}
        renderMessages={renderMessages}
        hasMore={hasMore}
      />
    </MessagesLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error, isMoreLoading, meta } = state.chat.conversation.messages.list
  return {
    list,
    loading,
    error,
    isMoreLoading,
    meta,
    participantId: selectConversationParticipantId(state),
    chatId: selectConversationChatId(state),
    isGroup: false,
  }
}

export const MessagesContainer = connect(mapStateToProps)(MessagesContainerComponent)
