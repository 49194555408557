import React, { useEffect, useState } from 'react'
import logoDark from '@/assets/images/logo/logo-light.svg'
import logoLight from '@/assets/images/logo/logo-light.svg'

type SizeType = 'sm'

type AttrsType = {
  height?: string
}

interface IProps {
  dark?: boolean
  size?: SizeType | undefined
}

export const Logo = ({ dark, size, ...props }: IProps) => {
  const [logoColor, setLogoColor] = useState<any>('#a3a3a3')

  let attrs: AttrsType = {}

  if (size === 'sm') {
    attrs = {
      ...attrs,
      height: '40px',
    }
  }

  useEffect(() => {
    if (!window.localStorage.getItem('logoColor')) {
      window.localStorage.setItem('logoColor', '#a3a3a3')
    } else {
      setLogoColor(window.localStorage.getItem('logoColor'))
    }
  }, [])

  return (
    <div style={{ display: 'flex' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="246"
        height="42"
        viewBox="0 0 246 42"
        fill="none"
      >
        <g clip-path="url(#clip0_215_377)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.83173 16.4039H8.06889V19.9296H8.06885V21.6925H8.06889V25.6588H9.83173V21.6925H15.1202V25.6588H16.8831V21.6925H22.1715V25.6589H23.9344V21.6925H23.9344V19.9296H23.9344H22.1715H16.8831V16.4039H15.1202V19.9296H9.83173V16.4039Z"
            fill={logoColor}
          />
          <circle cx="8.95034" cy="27.1096" r="1.76284" fill={logoColor} />
          <circle cx="16.0016" cy="27.1096" r="1.76284" fill={logoColor} />
          <circle cx="23.0531" cy="27.1096" r="1.76284" fill={logoColor} />
          <circle cx="8.95034" cy="14.9531" r="1.76284" fill={logoColor} />
          <circle cx="16.0016" cy="14.9533" r="1.76284" fill={logoColor} />
          <circle cx="16" cy="21" r="14.5" stroke={logoColor} stroke-width="3" />
        </g>
        <path
          d="M48.176 32V9.6H56.464C58.256 9.6 59.7387 9.89867 60.912 10.496C62.0853 11.0933 62.96 11.9147 63.536 12.96C64.112 14.0053 64.4 15.1787 64.4 16.48C64.4 17.7173 64.1227 18.8587 63.568 19.904C63.0133 20.928 62.1493 21.76 60.976 22.4C59.8027 23.0187 58.2987 23.328 56.464 23.328H52.272V32H48.176ZM52.272 20H56.208C57.6373 20 58.6613 19.6907 59.28 19.072C59.92 18.432 60.24 17.568 60.24 16.48C60.24 15.3707 59.92 14.5067 59.28 13.888C58.6613 13.248 57.6373 12.928 56.208 12.928H52.272V20ZM71.4473 32L79.6393 9.6H84.2473L92.4393 32H88.0873L86.2953 26.816H77.5593L75.7353 32H71.4473ZM78.6793 23.616H85.1753L81.9113 14.304L78.6793 23.616ZM103.005 32V9.6H111.229C113.021 9.6 114.493 9.90933 115.645 10.528C116.818 11.1253 117.693 11.9467 118.269 12.992C118.845 14.016 119.133 15.1573 119.133 16.416C119.133 17.7813 118.77 19.0293 118.045 20.16C117.341 21.2907 116.231 22.112 114.717 22.624L119.357 32H114.653L110.493 23.168H107.101V32H103.005ZM107.101 20.16H110.973C112.338 20.16 113.341 19.8293 113.981 19.168C114.621 18.5067 114.941 17.632 114.941 16.544C114.941 15.4773 114.621 14.624 113.981 13.984C113.362 13.344 112.349 13.024 110.941 13.024H107.101V20.16ZM135.838 32V12.896H129.31V9.6H146.494V12.896H139.934V32H135.838ZM157.209 32V9.6H161.305V28.8H171.225V32H157.209ZM182.513 32V9.6H186.609V32H182.513ZM198.631 32V9.6H202.727L213.255 25.376V9.6H217.351V32H213.255L202.727 16.256V32H198.631ZM229.373 32V9.6H243.997V12.896H233.469V19.04H243.037V22.24H233.469V28.704H243.997V32H229.373Z"
          fill={logoColor}
        />
        <defs>
          <clipPath id="clip0_215_377">
            <rect width="32" height="32" fill={logoColor} transform="translate(0 5)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )

  return <img src={dark ? logoLight : logoDark} alt={'logo'} {...attrs} {...props} />
}
