import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SegmentedControlController } from '@/components/FormElements'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { usePaymentMethodStaticOptions } from '@/features/billing/hooks'

type FormSectionValues = {
  payment_method_type: BILLING_PAYMENT_METHOD_TYPE
}

export const PaymentMethodTypeFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormSectionValues>()

  const { getTypes } = usePaymentMethodStaticOptions()

  return (
    <>
      <SegmentedControlController
        label={t('payment_method')}
        data={getTypes()}
        control={control}
        name={'payment_method_type'}
        mb={'md'}
      />
    </>
  )
}
