import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ChatSendMessage } from '@/features/chat/components/Chat'
import { useFormSubmit, usePopup } from '@/hooks'
import { InputSection } from './InputSection'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  participantId: number | undefined
  chatId: number | undefined
  disabled: boolean
  initialValues?: {
    files?: File[]
    body?: string
  }
}

export const EntryForm = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const inputRef = useRef<any>()

  const methods = useForm({
    defaultValues: {
      body: '',
      files: [],
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
    setError,
    getValues,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onReset = () => {
    reset()

    inputFocus()
  }

  const inputFocus = () => {
    // set input focus
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus()
      }, 0)
    }
  }

  useEffect(() => {
    onReset()
  }, [props.participantId])

  const isDisabled = useMemo(() => {
    return props.disabled || isSubmitting || !isValid
  }, [props.disabled, isSubmitting, isValid])

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (isDisabled) {
      return
    }

    try {
      const { body } = data

      let values: any = {}

      if (body !== '') {
        values = {
          ...values,
          body,
        }
      }

      await onFormSubmit(values)

      onReset()
    } catch (error) {
      inputFocus()
    }
  }

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)()
  }

  // Attachments
  const { visible, open, close, data: popupData } = usePopup()

  const attachmentsMessagePopupData = {
    initialValues: {
      ...popupData?.initialValues,
    },
  }

  const onFilesPicked = (files: File[]) => {
    const initialValues = {
      files: [...files].map((file) => ({
        file,
      })),
      body: getValues('body'),
    }

    open({ initialValues })
  }

  const onAttachmentsMessageSendSuccess = () => {
    close()

    onReset()
  }
  // --- //

  return (
    <>
      <FormProvider {...methods}>
        <InputSection onFilesPicked={onFilesPicked} onSubmit={onHandleSubmit} inputRef={inputRef} />
      </FormProvider>

      <ChatSendMessage
        userId={props.participantId as number}
        chatId={props.chatId as number}
        onSuccess={onAttachmentsMessageSendSuccess}
        onClose={close}
        visible={visible}
        initialValues={attachmentsMessagePopupData?.initialValues}
      />
    </>
  )
}
