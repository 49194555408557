import React from 'react'
import { useTranslation } from 'react-i18next'
import { BILLING_BILLING_ADDRESS_TAX_ID_STATUS } from '@/features/billing/consts/billing-address'
import { billingAddressHelper } from '@/features/billing/helpers'
import { BillingAddressModel } from '@/features/billing/types'

export const useBillingAddressStaticOptions = () => {
  const { t } = useTranslation()

  const getTaxIdStatus = (value: BILLING_BILLING_ADDRESS_TAX_ID_STATUS) => {
    return billingAddressHelper.general.getTaxIdStatus(value)
  }

  const mapBillingAddress = (data: BillingAddressModel) => {
    return billingAddressHelper.general.mapBillingAddress(data)
  }

  return {
    getTaxIdStatus,
    mapBillingAddress,
  }
}
