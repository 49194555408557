import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '@/components/Elements'
import useStyles from './NoData.styles'

export const NoData = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Badge color={'primary'} variant={'filled'} size={'lg'}>
        {t('no_messages')}
      </Badge>
    </div>
  )
}
