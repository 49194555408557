import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '@mantine/core'
import { PrintControl } from './Controls'

interface IProps {
  onPrint: () => void
}

export const Action = ({ onPrint }: IProps) => {
  const { t } = useTranslation()

  return (
    <Flex justify={'flex-end'} mt={'lg'}>
      <PrintControl onPrint={onPrint} />
    </Flex>
  )
}
