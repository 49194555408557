import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RoomLayout, SplashScreen } from '@/features/chat-ui/components/Layouts/RoomLayout'
import { useChatBackgroundEffect, useChatConversationData } from '@/features/chat/hooks'
import { chatConversationData } from '@/features/chat/store'
import { EntryToolbar } from './EntryToolbar'
import { Header } from './Header'
import { MessagesContainer } from './MessagesContainer'

export const Room = () => {
  const dispatch = useDispatch()

  const { conversation } = useChatConversationData()

  useChatBackgroundEffect()

  useEffect(() => {
    return () => {
      dispatch(chatConversationData.cleanState())
    }
  }, [])

  return (
    <RoomLayout>
      {conversation ? (
        <>
          <Header />

          <MessagesContainer />

          <EntryToolbar />
        </>
      ) : (
        <SplashScreen />
      )}
    </RoomLayout>
  )
}
