import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '@/components/Elements'
import { BILLING_BILLING_ADDRESS_TAX_ID_STATUS } from '@/features/billing/consts/billing-address'
import { useBillingAddressStaticOptions } from '@/features/billing/hooks'

interface IProps {
  value: BILLING_BILLING_ADDRESS_TAX_ID_STATUS | null
}

export const TaxIdStatusBadge = ({ value }: IProps) => {
  const { t } = useTranslation()

  if (!value) {
    return null
  }

  const { getTaxIdStatus } = useBillingAddressStaticOptions()

  const { label, color } = getTaxIdStatus(value) ?? {}

  return (
    <>
      <Badge color={color}>{label}</Badge>
    </>
  )
}
