import moment from 'moment'
import React from 'react'
import { MessageType } from '@/features/chat/types/models'

export type MessageItemType = {
  message: MessageType
  day: boolean
  unread: boolean
}

type messageItemConfigType = {
  isGroup?: boolean
  withUnread?: boolean
  withForceUnread?: boolean
}

const messageItemDefaultConfig = {
  isGroup: false,
  withUnread: true,
  withForceUnread: false,
}

export const useMessages = () => {
  const getMessagesItems = (items: MessageType[], messageConfig?: messageItemConfigType) => {
    const config = {
      ...messageItemDefaultConfig,
      ...messageConfig,
    }

    const list = [...items].reverse()

    const messages: any[] = []

    for (let i = 0; i < list.length; i++) {
      const message = list[i]

      const prevMessage = list[i - 1]

      let withDay = true
      if (prevMessage) {
        // check if prev message has day separator
        withDay = !moment(prevMessage.created_at).isSame(moment(message.created_at), 'day')
      }

      let withUnread = false

      if (config.withForceUnread && !message.seen) {
        if (prevMessage) {
          withUnread = prevMessage.seen
        }
      } else {
        // if message not mine and user not seen the message
        withUnread = config.withUnread && !message.is_my && !message.seen
        if (prevMessage && withUnread) {
          // check if prev message has unread separator
          if (!prevMessage.is_my) {
            withUnread = prevMessage.seen
          }
        }
      }

      const item = {
        message,
        day: withDay,
        unread: withUnread,
      }

      messages.push(item)
    }

    return messages
  }

  return {
    getMessagesItems,
  }
}
