import { rootApi } from '@/store/api'

const enhanceApi = rootApi.enhanceEndpoints({
  addTagTypes: ['BillingStripe'],
})

export const billingStripeApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    billingStripeSetupIntent: builder.query<any, void>({
      query: (id) => ({
        url: `billing/setup-intent`,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
})

export const { useBillingStripeSetupIntentQuery, useLazyBillingStripeSetupIntentQuery } =
  billingStripeApi
