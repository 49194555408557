import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { BsBarChart, BsCardImage, BsCardList, BsChatText, BsPeople } from 'react-icons/bs'
import { ChatUnreadBadge } from '../Badges'
import { NavLink, NavLinkType } from '../NavLink'

interface IProps {
  onLinkClick: () => void
}

export const GeneralLinks = ({ onLinkClick }: IProps) => {
  const { t } = useTranslation()

  const links: Array<NavLinkType> = [
    // {
    //   path: '/dashboard',
    //   label: t('dashboard'),
    //   icon: BsBarChart,
    // },
    // {
    //   path: '/invites',
    //   label: t('invites'),
    //   icon: BsInboxes,
    // },
    {
      path: '/live-feeds',
      label: t('live_feeds_label'),
      icon: BsCardImage,
    },
    {
      path: '/my-listings',
      label: t('my_listings_label'),
      icon: BsCardList,
    },
    {
      path: '/chats',
      label: t('chats'),
      icon: BsChatText,
      renderRightSection: ({ isActive }: { isActive: boolean }) => (
        <ChatUnreadBadge isActive={isActive} />
      ),
    },
    {
      path: '/suppliers',
      label: t('suppliers'),
      icon: BsPeople,
    },
  ]

  return (
    <>
      {links.map((item) => {
        return <NavLink key={item.path} onClick={onLinkClick} {...item} />
      })}
    </>
  )
}
