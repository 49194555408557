import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SelectController } from '@/components/FormElements'
import { usePaymentMethodStaticOptions } from '@/features/billing/hooks'
import { PaymentMethodModel } from '@/features/billing/types'
import { PaymentUtils } from '@/utils'

type FormSectionValues = {
  iban: any
}

interface IProps {
  data?: PaymentMethodModel[]
}

export const SepaSelectFormSection = ({ data = [] }: IProps) => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormSectionValues>()

  const { isSepaType } = usePaymentMethodStaticOptions()

  const options = useMemo(() => {
    return data
      .filter((item) => isSepaType(item.type))
      .map(({ id, last4, country, bank_code }) => {
        const label = PaymentUtils.paymentIbanHiddenNumber({
          country,
          bank_code,
          last4,
        })

        return {
          label: label,
          value: id,
        }
      })
  }, [data])

  return (
    <>
      <SelectController
        control={control}
        name={'iban'}
        id={'iban'}
        label={t('iban')}
        placeholder={t('iban')}
        mb={'md'}
        data={options}
        clearable
      />
    </>
  )
}
