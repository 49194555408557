import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { useLatest } from 'react-use'
import { FilesUploaderContainer } from './FilesUploaderContainer'

type FieldValueType = {
  id?: number
  attachment?: any
  file: File
}

export interface FilesUploaderControlProps {
  value: FieldValueType[]
  onChange: (data: FieldValueType[]) => void
  id: string
  name: string
  meta?: any
  filesUploaderRef: ForwardedRef<any>
  className?: string
  accept: string
  picker?: boolean
  limit?: number
  pickerLabelText?: string
  processing?: any[]
  onValidate: (name: string, onValid: any) => Promise<void>
  translateParams?: any
  onUpload?: (
    item: FieldValueType,
    params: {
      onSuccess: (data: FieldValueType, index: number) => void
      index: number
      fieldName: string
    }
  ) => void
  onRemove?: (
    item: FieldValueType,
    params: {
      onSuccess: (data: FieldValueType, index: number) => void
      index: number
    }
  ) => void
  onDownload?: (field: FieldValueType) => void
  disabled?: boolean
}

const FilesUploaderControlComponent = ({
  value,
  onChange,
  id,
  name,
  meta = {},
  filesUploaderRef,
  className,
  accept,
  picker,
  limit,
  pickerLabelText,
  ...props
}: FilesUploaderControlProps) => {
  const fieldName = name
  const error = meta?.error

  // need refactoring
  const valueRef = useLatest(value)

  useImperativeHandle(filesUploaderRef, () => ({
    addFiles(files: File[]) {
      onAdd(files)
    },
  }))

  const onAdd = (files: File[]) => {
    const result = [...files].map((file) => ({
      file,
    }))

    onChange([...value, ...result])
  }

  // Upload
  const onUpload = (item: FieldValueType, index: number, fieldName: string) => {
    const params = {
      onSuccess: (data: FieldValueType, index: number) => update(data, index),
      index,
      fieldName,
    }
    props.onUpload && props.onUpload(item, params)
  }

  const update = (data: FieldValueType, index: number) => {
    const currentValue = valueRef.current

    const items = currentValue.map((item, i) => {
      if (i !== index) {
        return item
      }
      return {
        ...item,
        ...data,
      }
    })

    onChange([...items])
  }
  // ==== //

  // Remove
  const onRemove = (item: FieldValueType, index: number, fieldName: string) => {
    if (item?.id) {
      const params = {
        onSuccess: (data: FieldValueType, index: number) => remove(data, index),
        index,
        fieldName,
      }
      props.onRemove && props.onRemove(item, params)
    } else {
      remove(item, index)
    }
  }

  const remove = (data: FieldValueType, index: number) => {
    const currentValue = valueRef.current

    const items = currentValue.filter((_, i) => i !== index)
    onChange([...items])
  }
  // ==== //

  return (
    <FilesUploaderContainer
      id={id}
      className={className}
      name={fieldName}
      value={value}
      accept={accept}
      pickerLabelText={pickerLabelText}
      onRemove={onRemove}
      onAdd={onAdd}
      onUpload={onUpload}
      onDownload={(data: FieldValueType) => {
        props.onDownload && props.onDownload(data)
      }}
      limit={limit}
      processing={props.processing}
      onValidate={props.onValidate}
      picker={picker}
      meta={meta}
      translateParams={props.translateParams}
    />
  )
}

// eslint-disable-next-line
interface IComponentProps extends Omit<FilesUploaderControlProps, 'filesUploaderRef'> {}

export const FilesUploaderControl = forwardRef((props: IComponentProps, ref) => (
  <FilesUploaderControlComponent {...props} filesUploaderRef={ref} />
))
