import React from 'react'
import { useAppSelector } from '@/store'

export const useChatConversationData = () => {
  const { data, backgroundUnreadMessages } = useAppSelector((state) => state.chat.conversation.data)

  const getParticipant = () => data?.participant
  const getChatData = () => data

  return {
    conversation: data,
    backgroundUnreadMessages,
    getParticipant,
    getChatData,
  }
}
