import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Title } from '@mantine/core'
import { LogoIcon } from '@/components/Elements'
import { InvoiceModel } from '@/features/billing/types'

interface IProps {
  data: InvoiceModel
}

export const Header = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { number } = data

  return (
    <div className={'flex'}>
      <Group className={'w-full'}>
        <div className={'flex grow'}>
          <LogoIcon size={'sm'} />
        </div>

        <Title order={5}>
          {t('invoice')} {number}
        </Title>
      </Group>
    </div>
  )
}
