import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'
import { Box } from '@mantine/core'
import { PageContent, PageHeader } from '@/components/Layouts'
import { BillingSocketMiddleware } from '@/features/billing/components/BillingSocketMiddleware'
import { useNotify, useQuery } from '@/hooks'
import { Tabs } from './Tabs'

export const BillingPanelShell = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const query = useQuery()

  const { showNotification } = useNotify()

  const historyReplace = () => {
    navigate(
      {
        search: '',
      },
      { replace: true }
    )
  }

  useEffect(() => {
    if (query.get('success')) {
      const success = query.get('success') === 'true'
      let message = ''

      if (success) {
        message = query.get('message') || t('payment_was_successful')
      } else {
        message = query.get('message') || t('payment_error')
      }

      showNotification({
        type: success ? 'success' : 'error',
        message,
      })

      historyReplace()
    }
  }, [])

  return (
    <BillingSocketMiddleware>
      <PageContent>
        <PageHeader title={t('billing')} />

        <Tabs />

        <Box mt={'lg'}>
          <Outlet />
        </Box>
      </PageContent>
    </BillingSocketMiddleware>
  )
}
