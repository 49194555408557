import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Elements'
import { PaymentMethodAdd } from '@/features/billing/components/PaymentMethod/PaymentMethod'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { usePopup } from '@/hooks'

interface IProps {
  type: BILLING_PAYMENT_METHOD_TYPE
}

export const PaymentMethodAddControl = ({ type }: IProps) => {
  const { t } = useTranslation()

  const { visible, open, close } = usePopup()

  const onClose = () => {
    close()
  }

  const onSubmitSuccess = () => {
    onClose()
  }

  return (
    <>
      <Button size={'sm'} onClick={open} variant={'outline'}>
        {t('add')}
      </Button>

      <PaymentMethodAdd
        type={type}
        visible={visible}
        onClose={onClose}
        onSuccess={onSubmitSuccess}
      />
    </>
  )
}
