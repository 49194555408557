import React from 'react'
import useStyles from './RoomLayout.styles'

interface IProps {
  children: React.ReactNode
  className?: string
}

export const RoomLayout = ({ children, className }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.root, {
        [`${className}`]: className,
      })}
    >
      {children}
    </div>
  )
}
