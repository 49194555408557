import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Group } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { CheckboxFieldController } from '@/components/FormElements'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { SepaMandateInfo } from '@/features/stripe/components/Elements'
import { SepaStripeFormSection } from '@/features/stripe/components/FormSections'
import { useStripeForm } from '@/features/stripe/hooks'
import { useFormSubmit } from '@/hooks'
import { validationSchema } from './validation'

interface IProps {
  stripe: any
  elements: any
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
  initialValues?: object
}

export const SepaForm = ({ stripe, elements, initialValues, onCancel, ...props }: IProps) => {
  const { t } = useTranslation()

  const stripeInitialState = {
    iban: undefined,
  }

  const stripeForm = useStripeForm({
    initialState: stripeInitialState,
  })

  const methods = useForm({
    defaultValues: {
      default: false,
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const isDisabled = useMemo(() => {
    return !stripe || !elements || !stripeForm.isValid
  }, [stripe, elements, stripeForm.isValid])

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      if (isDisabled) {
        return
      }

      const values = {
        ...data,
        billing_details: {
          name: data.name,
          email: data.email,
          address: {
            line1: data.address,
            country: stripeForm.state?.['iban']?.['country'],
          },
        },
        payment_method_type: BILLING_PAYMENT_METHOD_TYPE.SEPA_DEBIT,
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <SepaStripeFormSection
            state={stripeForm.state}
            onStripeChange={stripeForm.onChange}
            mandate={false}
          />

          <CheckboxFieldController
            control={control}
            name={'default'}
            id={'default'}
            label={t('default')}
            mb={'md'}
          />

          <div className={'mt-4 pt-4'}>
            <Group>
              <Button variant="outline" color="dark" onClick={onCancel}>
                {t('cancel')}
              </Button>

              <Button loading={isSubmitting} type={'submit'} disabled={isDisabled}>
                {t('save')}
              </Button>
            </Group>
          </div>

          <Box mt={'xl'}>
            <SepaMandateInfo />
          </Box>
        </form>
      </FormProvider>
    </>
  )
}
