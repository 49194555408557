/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ScrollArea, Space } from '@mantine/core'
import { Item } from './Item'
import { ChatModel } from '@/features/chat/types/models'
import useStyles from './ChatsList.styles'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import ImageGallery from 'react-image-gallery'
import { Alert, Badge, Box, Button, Card, Grid, Group, Image, Text } from '@mantine/core'

import {
  useLiveFeedLoadMutation
} from '@/features/liveFeeds/store'

import './Gallery.scss'

interface IProps {
  items: ChatModel[]
  onSelect: (data: ChatModel) => void
  participantId: number | undefined
  chatId?: number | undefined
}

export const ChatsList = ({ items, onSelect, participantId, chatId }: IProps) => {
  const { classes } = useStyles()

  const [loadLiveFeed] = useLiveFeedLoadMutation()
  const { t } = useTranslation()

  const [post, setPost] = useState<any>(null)

  const { feedId } = useParams()

  const isActive = useCallback(
    (chat: ChatModel) => {
      if (chatId) {
        return chatId === chat?.id
      }
      return false
    },
    [chatId]
  )

  const onLoadLiveFeed = async () => {
    const res: any = await loadLiveFeed(Number(feedId))

    setPost(res?.data?.data || null)
  }

  useEffect(() => {
    onLoadLiveFeed()
  }, [feedId])

  return (
    <ScrollArea className={'h-full'} mx="-xs" px="xs">
      <Space h={'xs'} />

      {feedId && post ? (
        <div className={'flex wrap justify-start items-center'} style={{ margin: 10, maxWidth: 300 }}>
          <Box style={{ margin: 10, maxWidth: '300px' }}>
            <div>
              {post?.photos?.length ? (
                <ImageGallery
                  infinite
                  showNav
                  showThumbnails
                  showFullscreenButton
                  showPlayButton={false}
                  showBullets={false}
                  isRTL
                  items={post?.photos.map((photo: any) => {
                    return { original: photo?.uri, thumbnail: photo?.uri }
                  })}
                />
              ) : null}
            </div>
            <div className={'flex wrap justify-start items-center'} style={{ margin: 10, maxWidth: 300 }}>
              {!post?.photos?.length ? (
                <Image src={post?.makeUrl}  height={250} fit="contain" width={250} alt={`empty`} />
              ) : null}
            </div>
            <Text size="md" weight={500}>
              {`${post?.make} ${post?.model} | ${post?.year}`}
            </Text>
            <Text color="#b3b3b3" mt={'lg'} size="md" weight={500}>
              {t('description_title')}
            </Text>
            <Text size="md" weight={500}>
              {post?.description}
            </Text>
            <Text color="#b3b3b3" mt={'lg'} size="md" weight={500}>
              {t('trim_title')}
            </Text>
            <Text size="md" weight={500}>
              {post?.trim || '-'}
            </Text>
          </Box>
        </div>
      ) : null}

      {feedId ? (
        <>
          {items
            .filter((item) => item?.post?.id === +feedId)
            .map((item, i) => (
              <Item key={i} data={item} onSelect={onSelect} active={isActive(item)} />
            ))}
        </>
      ) : (
        <>
          {items.map((item, i) => (
            <Item key={i} data={item} onSelect={onSelect} active={isActive(item)} />
          ))}
        </>
      )}

      <Space h={'xs'} />
    </ScrollArea>
  )
}
