import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { chatConversationMessagesSendPA } from '@/features/chat/store'
import { useNotify } from '@/hooks'
import { MessageForm } from '../../MessageForm'

interface IProps {
  userId: number
  chatId: number
  onClose: () => void
  onSuccess: () => void
  initialValues?: {
    files?: File[]
    body?: string
  }
}

export const ChatSendMessageContainer = ({
  userId,
  chatId,
  onClose,
  onSuccess,
  initialValues,
}: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    try {
      const params = {
        ...values,
      }

      await dispatch(chatConversationMessagesSendPA.request({ userId, chatId, params }))

      onSuccess()
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
      throw error
    }
  }

  return (
    <>
      <MessageForm onSubmit={onSubmit} onCancel={onClose} initialValues={initialValues} />
    </>
  )
}
