import { rootApi } from '@/store/api'

// move to usersChatApi
export const chatApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    userChatCreate: builder.mutation<any, { id: number; params: any }>({
      query: (arg) => {
        const { id, params } = arg

        return {
          url: `chats/users/${id}/messages`,
          method: 'POST',
          body: params,
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const { useUserChatCreateMutation } = chatApi
