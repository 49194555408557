/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Badge,
    Box,
    Button,
    Card,
    Grid,
    Group,
    Image,
    Input,
    Select,
    Table,
    Text,
} from '@mantine/core'
import { Alert } from '@/components/Elements'
import { Notifications, notifications } from '@mantine/notifications'
import { Anchor, ButtonLink, ColorSchemeToggle, LogoIcon, Modal } from '@/components/Elements'
import { useInvitesRejectMutation, useInvitesAcceptMutation } from '@/features/invites/store'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import {
    CheckboxFieldController,
    PasswordInputController,
    TextInputController,
} from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { useFormSubmit } from '@/hooks'
import { NormalizeUtils, ValidationsUtils } from '@/utils'
import { useNotify } from '@/hooks'

const { showNotification } = useNotify()


export const InviteContainer = () => {
    const { inviteHash } = useParams()

    const { t } = useTranslation()
    const [invite, setInvite] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const [invitesAccept] = useInvitesAcceptMutation()
    const [invitesReject] = useInvitesRejectMutation()


    const handleAccept = async () => {
        try {
            setIsLoading(true)
            const res = await invitesAccept(inviteHash).unwrap()

            showNotification({
                type: 'success',
                message: t('create_accepted'),
            })

            setInvite(res)
        } catch (e) {
        } finally {
            setIsLoading(false)
        }
    }


    const handleReject = async () => {
        try {
            setIsLoading(true)
            const res = await invitesReject(inviteHash).unwrap()

            showNotification({
                type: 'success',
                message: t('create_rejected'),
            })

            setInvite(null)
        } catch (e) {
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Card>
            <h2 style={{ textAlign: 'center' }}>{t('accept_invite')}</h2>
            {invite ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <p>{t('password')}: {invite?.password}</p>
                <ButtonLink to={'/login'} size="md" uppercase>
                    {t('login')}
                </ButtonLink>
            </div> : <div>
                <Button mt={'md'} onClick={handleAccept} fullWidth variant={'outline'}>
                    {t('accept_invite')}
                </Button>
                <Button mt={'md'} onClick={handleReject} color='red' fullWidth variant={'outline'}>
                    {t('accept_reject')}
                </Button>
            </div>}

        </Card>
    )
}
