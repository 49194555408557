import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { BillingAddressUpdate } from '@/features/billing/components/BillingAddress'

export const BillingInformation = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'billing_information'} />

      <BillingAddressUpdate />
    </>
  )
}
