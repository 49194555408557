export enum ECHO_EVENT {
  CHAT_MESSAGE_EVENT = 'Chat\\ChatMessageEvent',
  CHAT_SEEN_EVENT = 'Chat\\ChatSeenEvent',
  CHAT_TYPING_EVENT = 'Chat\\ChatTypingEvent',

  UPDATE_SUBSCRIPTION_EVENT = 'Billing\\UpdateSubscriptionEvent',

  UPDATE_TAX_ID_EVENT = 'Billing\\UpdateTaxIdEvent',

  UPDATE_BILLING_INVOICE_EVENT = 'Billing\\UpdateBillingInvoiceEvent',
}
