import * as Yup from 'yup'
import { ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object().shape({
  company_name: Yup.string().nullable().required('field.error.required'),
  contact_name: Yup.string().nullable().required('field.error.required'),
  zip_code: Yup.string().nullable().required('field.error.required'),
  street: Yup.string().nullable().required('field.error.required'),
  state: Yup.string().nullable().required('field.error.required'),
  city: Yup.string().nullable().required('field.error.required'),
  phone: Yup.string()
    .test('phone', 'field.error.invalid', ValidationsUtils.phoneFormat)
    .required('field.error.required'),
})
