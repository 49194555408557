import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Group } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { TextInputController } from '@/components/FormElements'
import { CHAT_FILE_MAX_SIZE } from '@/const/form'
import { useFormSubmit } from '@/hooks'
import { AttachmentsSection } from './AttachmentsSection'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
  initialValues?: {
    files?: File[]
    body?: string
  }
}

export const MessageForm = ({ onCancel, initialValues = {}, ...props }: IProps) => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      body: '',
      files: [],
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    trigger,
    formState: { isSubmitting, isValid },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  // Attachments
  const [filesProcessing, setFilesProcessing] = useState<number[]>([])

  const addFileToProcessing = (index: number) => {
    setFilesProcessing((prevState) => [...prevState, index])
  }

  const removeFileFromProcessing = (index: number) => {
    setFilesProcessing((prevState) => prevState.filter((item) => item !== index))
  }
  // --- //

  const isDisabled = useMemo(() => {
    return isSubmitting || !isValid || !!filesProcessing.length
  }, [isSubmitting, isValid, filesProcessing])

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (isDisabled) {
      return
    }

    try {
      const { body, files } = data

      const params: any = {}

      if (body !== '') {
        params.body = body
      }

      const attachments = files.filter((item: any) => item?.id)
      if (attachments.length > 0) {
        params.files = attachments.map((item: any) => item?.id)
      }

      await onFormSubmit(params)
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInputController
            control={control}
            name={'body'}
            id={'body'}
            label={t('message')}
            placeholder={t('message')}
            mb={'md'}
          />

          <Box mb={'lg'}>
            <AttachmentsSection
              addFileToProcessing={addFileToProcessing}
              removeFileFromProcessing={removeFileFromProcessing}
              processing={filesProcessing}
              control={control}
              setError={setError}
              trigger={trigger}
              fileMaxSize={CHAT_FILE_MAX_SIZE}
            />
          </Box>
          <Box mt={'xl'}>
            <Group>
              <Button variant="outline" color="dark" onClick={onCancel}>
                {t('cancel')}
              </Button>

              <Button loading={isSubmitting} disabled={isDisabled} type={'submit'}>
                {t('send')}
              </Button>
            </Group>
          </Box>
        </form>
      </FormProvider>
    </>
  )
}
