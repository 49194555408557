import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { PasswordInputController } from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { useFormSubmit } from '@/hooks'

export type FormValues = {
  password: string
  password_confirmation: string
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}

export const ResetPasswordForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        password: Yup.string()
          .required('field.error.required')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
        password_confirmation: Yup.string()
          .required('field.error.required')
          .oneOf([Yup.ref('password'), null], 'field.error.password.no_match')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
      })
    ),
  })

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (err) {}
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <PasswordInputController
          control={control}
          name={'password_confirmation'}
          id={'password_confirmation'}
          label={t('confirm_password')}
          placeholder={t('confirm_password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <Space h={'md'} />

        <Button fullWidth type={'submit'} loading={isSubmitting}>
          {t('reset')}
        </Button>
      </form>
    </>
  )
}
