import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { StripeElementsProvider } from '@/features/stripe/components'
import { PaymentMethodAddContainer } from './PaymentMethodAddContainer'

interface IProps {
  type: BILLING_PAYMENT_METHOD_TYPE
  visible: boolean
  onClose: () => void
  onSuccess?: () => void
}

export const PaymentMethodAdd = ({ type, visible, onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal opened={visible} onClose={onClose} title={t('add_payment_method')}>
        <StripeElementsProvider>
          <PaymentMethodAddContainer type={type} onClose={onClose} onSuccess={onSuccess} />
        </StripeElementsProvider>
      </Modal>
    </>
  )
}
