import React from 'react'
import { ChatsContainer } from './Chats'
import { Content } from './Content'
import useStyles from './Sidebar.styles'

export const Sidebar = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Content>
        <ChatsContainer />
      </Content>
    </div>
  )
}
