import { ModelTypes } from '@/types'

export enum ROLE {
  Client = 'supplier',
  SubClient = 'sub-supplier',
}

export type BaseUserType = {
  id: number
  avatar: ModelTypes.AvatarType | null
  email: string
  first_name: string | null
  last_name: string | null
  company_name: string | null
  contact_name: string | null
  city: string | null
  state: string | null
  zip_code: string | null
  street: string | null
  address: string | null
  role: ROLE
  phone: string | null
}

// eslint-disable-next-line
export interface UserModel extends BaseUserType {}
