import { rootApi } from '@/store/api'
import {
  ForgotPasswordDTO,
  LoginDTO,
  RegisterDTO,
  ResetPasswordDTO,
  VerifyEmailDTO,
} from '@/features/auth/api/dto'
import { RTKQueryUtils } from '@/utils'

const { cacher } = RTKQueryUtils

export const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, LoginDTO>({
      query: (arg) => ({
        url: 'auth/login',
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: cacher.invalidatesUnauthorized(),
    }),
    register: builder.mutation<any, RegisterDTO>({
      query: (arg) => ({
        url: 'auth/register/supplier',
        method: 'POST',
        body: arg,
      }),
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: 'auth/logout',
        method: 'POST',
      }),
    }),
    verifyEmail: builder.mutation<any, VerifyEmailDTO>({
      query: (arg) => ({
        url: 'auth/email/verification-notification',
        method: 'POST',
        body: arg,
      }),
    }),
    forgotPassword: builder.mutation<any, ForgotPasswordDTO>({
      query: (arg) => ({
        url: 'auth/forgot-password',
        method: 'POST',
        body: arg,
      }),
    }),
    resetPassword: builder.mutation<any, ResetPasswordDTO>({
      query: (arg) => ({
        url: 'auth/reset-password',
        method: 'POST',
        body: arg,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi
