import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationControl } from '@/components/Controls'
import { ActionIconButton } from '@/components/Elements'
import { useBillingPaymentMethodDeleteMutation } from '@/features/billing/store'
import { PaymentMethodModel } from '@/features/billing/types'
import { useNotify } from '@/hooks'

interface IProps {
  data: PaymentMethodModel
  onSuccess?: (data: any) => void
}

export const RemoveControl = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [remove, { isLoading }] = useBillingPaymentMethodDeleteMutation()

  const onRemoveConfirm = async () => {
    try {
      await remove(data?.id).unwrap()
    } catch (error: any) {
      showNotification({
        type: 'error',
        message: error?.data?.message || t('error'),
      })
    }
  }

  return (
    <>
      <ConfirmationControl
        onConfirm={onRemoveConfirm}
        render={({ onOpen }) => (
          <ActionIconButton name={'remove'} onClick={onOpen} disabled={isLoading} />
        )}
      />
    </>
  )
}
