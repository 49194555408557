import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { DataShower } from '@/components/Elements'
import {
  chatChatsList,
  chatConversationData,
  selectChatsSortList,
  selectConversationParticipantId,
} from '@/features/chat/store'
import { ChatModel } from '@/features/chat/types/models'
import { RootState } from '@/store'
import { ChatsList } from './ChatsList'

interface IProps {
  list: ChatModel[]
  loading: boolean
  error: any
  participantId: number | undefined
  chatId?: number | undefined
}

const ChatsContainerComponent = (props: IProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const location = useLocation()
  const stateData = location.state

  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()

    return () => {
      dispatch(chatChatsList.cleanState())
    }
  }, [])

  useEffect(() => {
    if (location?.pathname === '/chats') {
      chatChatsList.cleanState()
      fetchData()
    }
  }, [location])

  const fetchData = () => {
    if (stateData?.post?.id) {
      dispatch(chatChatsList.getList({ postId: stateData?.post?.id }))
      navigate(`/my-listings/details/${stateData?.post?.id}`)
    } else {
      dispatch(chatChatsList.getList({}))
    }
  }

  const onChatSelect = (item: any) => {
    dispatch(chatConversationData.initChat(item))
  }

  return (
    <>
      <DataShower
        isLoading={props.loading}
        isFetched={!props.loading && !props.error}
        isFailed={!!props.error}
        error={props.error}
        loaderWrapClass={'h-full flex justify-center items-center'}
      >
        {!!props.list.length && (
          <ChatsList
            items={props.list}
            participantId={props.participantId}
            chatId={props.chatId}
            onSelect={onChatSelect}
          />
        )}

        {!props.list.length && !props.loading && !props.error && (
          <div className={'h-full flex justify-center items-center'}>{t('no_chats')}</div>
        )}
      </DataShower>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  const { loading, error } = state.chat.chats.list
  return {
    list: selectChatsSortList(state),
    loading,
    error,
    participantId: selectConversationParticipantId(state),
  }
}

export const ChatsContainer = connect(mapStateToProps)(ChatsContainerComponent)
