import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { Modal } from '@/components/Elements'
import { LatestInvoicesContainer } from '@/features/billing/components/Invoice/Invoices'
import { useSubscriptionStaticOptions } from '@/features/billing/hooks'
import { useUserSubscription } from '@/features/user/hooks'
import { IncompletePayment } from './IncompletePayment'
import { SubscriptionOverview } from './SubscriptionOverview'

export const General = () => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const { subscription } = useUserSubscription()

  const { isIncompleteStatus } = useSubscriptionStaticOptions()

  useEffect(() => {
    if (window.subModalStatus) {
      setIsOpen(true)
    }
  }, [])

  const onClose = () => {
    setIsOpen(false)
    window.subModalStatus = false
  }

  return (
    <>
      {subscription && isIncompleteStatus(subscription?.status) && (
        <Box mb={'lg'}>
          <IncompletePayment />
        </Box>
      )}
      <Modal centered opened={isOpen} onClose={onClose} title={t('expired_trial_title')}>
        <p style={{ textAlign: 'center' }}>{t('expired_trial_text')}</p>
      </Modal>
      <SubscriptionOverview />

      <Box mt={'lg'}>
        <LatestInvoicesContainer />
      </Box>
    </>
  )
}
