import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    height: '700px',
    width: '364px',

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: '100%',
    },
  },
}))
