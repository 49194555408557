import React from 'react'
import { ColorScheme, ColorSchemeProvider, MantineProvider as Provider } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { Notifications } from '@mantine/notifications'
import { Fonts, GlobalStyles, globalTheme } from '@/assets/theme'

type Props = {
  children: React.ReactNode
}

export const MantineProvider: React.FC<Props> = ({ children }) => {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  })

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <Provider
        theme={{
          ...globalTheme,
          colorScheme,
          colors: {
            primaryColor: [
              '#bacdd6',
              '#9db1bb',
              '#89b0c2',
              '#6b95a9',
              '#557584',
              '#445d69',
              '#33454e',
            ],
          },
          primaryColor: 'primaryColor',
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <Fonts />
        <GlobalStyles />
        <Notifications position={'top-right'} />

        {children}
      </Provider>
    </ColorSchemeProvider>
  )
}
