import React from 'react'
import { ChatLayout } from '@/features/chat-ui/components/Layouts/ChatLayout'
import { Room } from './Room'
import { Sidebar } from './Sidebar'

export const Chat = () => {
  return (
    <ChatLayout>
      <Sidebar />

      <Room />
    </ChatLayout>
  )
}
