import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, OverlayBlockLoading } from '@/components/Elements'

interface IProps {
  isLoading: boolean
  isFailed: boolean
  error: any
  children: React.ReactNode
  errorContainerClass?: string
}

export const OverlayDataShower = ({
  isLoading,
  isFailed = false,
  error,
  children,
  errorContainerClass,
}: IProps) => {
  const { t } = useTranslation()

  const renderErrorMessage = () => {
    let message = null
    if (typeof error === 'string') {
      message = error
    }
    if (error?.message) {
      message = error.message
    }
    if (error?.data?.message) {
      message = error?.data?.message
    }

    return message || t('error')
  }

  if (isFailed) {
    return (
      <div className={errorContainerClass}>
        <Alert type={'error'}>{renderErrorMessage()}</Alert>
      </div>
    )
  }

  return (
    <>
      <OverlayBlockLoading zIndex={100} isLoading={isLoading}>
        {children}
      </OverlayBlockLoading>
    </>
  )
}
