import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MdSend } from 'react-icons/md'
import { ActionIcon } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { FilesPicker } from './FilesPicker'

interface IProps {
  onSubmit: () => void
  inputRef: any
  onFilesPicked: (files: File[]) => void
}

export const InputSection = (props: IProps) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { isSubmitting, isValid },
  } = useFormContext()

  const onKeyPress = (e: any) => {
    const { key, value } = e
    if (key === 'Enter') {
      props.onSubmit()
    }
  }

  return (
    <>
      <TextInputController
        control={control}
        name={'body'}
        id={'body'}
        placeholder={`${t('write_a_message')}...`}
        disabled={isSubmitting}
        meta={{
          error: null,
        }}
        fieldRef={props.inputRef}
        maxLength={10000}
        onKeyPress={onKeyPress}
        autoComplete="off"
        icon={<FilesPicker disabled={isSubmitting} onChange={props.onFilesPicked} />}
        rightSection={
          <ActionIcon
            disabled={!isValid}
            onClick={props.onSubmit}
            variant="transparent"
            loading={isSubmitting}
            color={'primary'}
            radius={'xl'}
          >
            <MdSend size="24" />
          </ActionIcon>
        }
      />
    </>
  )
}
