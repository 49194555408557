import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const dark = theme.colorScheme === 'dark'

  return {
    root: {
      borderRadius: theme.radius.xl,
      backgroundColor: dark ? theme.colors.dark[7] : theme.white,
      display: 'flex',
      overflow: 'hidden',
      border:
        theme.colorScheme === 'dark'
          ? `1px solid ${theme.colors.dark[5]}`
          : `1px solid ${theme.colors.gray[4]}`,

      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        flexDirection: 'column',
      },
    },
  }
})
