import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    height: '700px',
    borderLeft:
      theme.colorScheme === 'dark'
        ? `1px solid ${theme.colors.dark[5]}`
        : `1px solid ${theme.colors.gray[4]}`,
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      flex: 'auto',
      borderLeft: 'none',
      borderTop:
        theme.colorScheme === 'dark'
          ? `1px solid ${theme.colors.dark[5]}`
          : `1px solid ${theme.colors.gray[4]}`,
    },
  },
}))
