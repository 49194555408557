import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { ChatSendMessageContainer } from './ChatSendMessageContainer'

interface IProps {
  userId: number
  chatId: number
  visible: boolean
  onClose: () => void
  onSuccess: () => void
  modalProps?: any
  initialValues?: {
    files?: File[]
    body?: string
  }
}

export const ChatSendMessage = ({
  userId,
  chatId,
  visible,
  onClose,
  onSuccess,
  modalProps,
  initialValues,
}: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal centered opened={visible} onClose={onClose} title={t('send_message')} {...modalProps}>
        <ChatSendMessageContainer
          userId={userId}
          chatId={chatId}
          onClose={onClose}
          onSuccess={onSuccess}
          initialValues={initialValues}
        />
      </Modal>
    </>
  )
}
