import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DataShower } from '@/components/Elements'
import { useBillingAddressStaticOptions } from '@/features/billing/hooks'
import { useBillingBillingAddressDataQuery } from '@/features/billing/store'
import { SepaStripeFormSection } from '@/features/stripe/components/FormSections'

interface IProps {
  state: any
  onStripeChange: (state: any) => void
  onDestroy: () => void
}

export const SepaFormSection = ({ onDestroy, ...props }: IProps) => {
  const { t } = useTranslation()

  const { data, isSuccess, isError, isLoading, isFetching, error } =
    useBillingBillingAddressDataQuery()

  const { mapBillingAddress } = useBillingAddressStaticOptions()

  useEffect(() => {
    return () => {
      onDestroy()
    }
  }, [])

  return (
    <>
      <DataShower
        isFetched={isSuccess}
        isLoading={isLoading || isFetching}
        isFailed={isError}
        error={error}
      >
        {data && (
          <SepaStripeFormSection
            defaultValues={{
              name: data?.first_name || '',
              email: data?.email || '',
              address: mapBillingAddress(data),
            }}
            {...props}
          />
        )}
      </DataShower>
    </>
  )
}
