import React from 'react'
import { BillingRoot } from '@/features/billing/routes'
import { ChatsRoot } from '@/features/chat/routes'
import { Dashboard } from '@/features/dashboard/routes'
import { Invites } from '@/features/invites/routes'
import { EditMyFeed, LiveFeed, LiveFeedPage, LiveFeeds, MyFeeds } from '@/features/liveFeeds/routes'
import { ProfileRoot } from '@/features/profile/routes'
import { Suppliers } from '@/features/suppliers/routes'
import { ROLE } from '@/features/users/types'
import { GUARD } from '../../guards'

type PrivateRouteType = {
  index?: boolean
  path?: string
  element: React.ReactNode
  roles?: Array<string>
  guards: Array<GUARD>
}

const generalRoutes: PrivateRouteType[] = [
  {
    path: 'profile/*',
    element: <ProfileRoot />,
    roles: [ROLE.Client, ROLE.SubClient],
    guards: [],
  },
  {
    path: 'dashboard',
    element: <Dashboard />,
    roles: [ROLE.Client, ROLE.SubClient],
    guards: [],
  },
  {
    path: 'invites',
    element: <Invites />,
    roles: [ROLE.Client],
    guards: [],
  },
  {
    path: 'live-feeds',
    element: <LiveFeeds />,
    roles: [ROLE.Client, ROLE.SubClient],
    guards: [],
  },
  {
    path: 'my-listings',
    element: <MyFeeds />,
    roles: [ROLE.Client, ROLE.SubClient],
    guards: [],
  },
  {
    path: 'my-listings/edit/:feedId',
    element: <EditMyFeed />,
    roles: [ROLE.Client, ROLE.SubClient],
    guards: [],
  },
  {
    path: 'my-listings/details/:feedId',
    element: <ChatsRoot />,
    roles: [ROLE.Client, ROLE.SubClient],
    guards: [],
  },
  {
    path: 'suppliers',
    element: <Suppliers />,
    roles: [ROLE.Client, ROLE.SubClient],
    guards: [],
  },
  {
    path: 'live-feeds/create',
    element: <LiveFeed />,
    roles: [ROLE.Client, ROLE.SubClient],
    guards: [],
  },
  {
    path: 'live-feeds/details/:feedId',
    element: <LiveFeedPage />,
    roles: [ROLE.Client, ROLE.SubClient],
    guards: [],
  },
  {
    path: 'chats/*',
    element: <ChatsRoot />,
    roles: [ROLE.Client, ROLE.SubClient],
    guards: [],
  },
  {
    path: 'billing/*',
    element: <BillingRoot />,
    roles: [ROLE.Client],
    guards: [],
  },
]

export const getGeneralRoutes = () => {
  return generalRoutes
}
