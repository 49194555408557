import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import regular from '@/assets/fonts/OpenSans/OpenSans-Regular.ttf'

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || ''

const stripePromise = loadStripe(STRIPE_KEY)

interface IProps {
  children: React.ReactNode
}

export const StripeElementsProvider = ({ children }: IProps) => {
  const fonts = [
    {
      family: 'Open Sans',
      src: `url('${regular}')`,
    },
  ]

  return (
    <>
      <Elements stripe={stripePromise} options={{ fonts }}>
        {children}
      </Elements>
    </>
  )
}
