import React from 'react'
import { BILLING_SUBSCRIPTION_STATUS } from '@/features/billing/consts/subscription'
import { useSubscriptionStaticOptions, useTariffStaticOptions } from '@/features/billing/hooks'
import { TariffModel } from '@/features/billing/types'
import { useUser } from '@/features/user/hooks'

const { PAUSED, INCOMPLETE } = BILLING_SUBSCRIPTION_STATUS

export const useUserSubscription = () => {
  const { user } = useUser()

  const subscription = user?.subscription

  const { isDowngradeTariffLevel } = useTariffStaticOptions()

  const { isActiveStatus } = useSubscriptionStaticOptions()

  const isOnGracePeriod = () => {
    return subscription && isActiveStatus(subscription?.status) && subscription.ends_at
  }

  const isSubscriptionTariffAllow = (target: TariffModel) => {
    if (!subscription) {
      return true
    }

    const { status, tariff } = subscription ?? {}

    if (
      status === PAUSED ||
      status === INCOMPLETE ||
      isOnGracePeriod() ||
      isDowngradeTariffLevel(target, tariff)
    ) {
      return false
    }

    return target?.id !== tariff?.id
  }

  return {
    subscription,
    isSubscriptionTariffAllow,
    isOnGracePeriod,
  }
}
