import { PayloadAction } from '@reduxjs/toolkit'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'
import { showNotification } from '@mantine/notifications'
import { BILLING_BILLING_ADDRESS_TAX_ID_STATUS } from '@/features/billing/consts/billing-address'
import { billingBillingAddressApi } from '@/features/billing/store'
import i18n from '@/i18n'
import { billingBillingAddressSocketTaxIdOnUpdate } from './actions'

const { VERIFIED, UNVERIFIED } = BILLING_BILLING_ADDRESS_TAX_ID_STATUS

const endpoint = billingBillingAddressApi.endpoints.billingBillingAddressData

const selectBillingAddressResult = endpoint.select()

function* onUpdate({ payload: { data } }: PayloadAction<{ data: any }>) {
  const { status } = data ?? {}

  const result: unknown = yield select(selectBillingAddressResult)

  const { data: billingAddressData, isSuccess } = result as any

  if (isSuccess) {
    yield put(
      billingBillingAddressApi.util.updateQueryData(
        'billingBillingAddressData',
        undefined,
        (draft) => ({
          ...draft,
          tax_id_status: status,
        })
      ) as any
    )
  }

  if (billingAddressData?.tax_id_status !== status) {
    let payload = {
      color: 'green',
      message: '',
    }
    if (status === UNVERIFIED) {
      payload = {
        color: 'red',
        message: i18n.t('vat_id_unverified'),
      }
    } else if (status === VERIFIED) {
      payload = {
        color: 'green',
        message: i18n.t('vat_id_verified'),
      }
    }

    showNotification(payload)
  }
}

function* watchOnUpdate() {
  yield takeEvery(billingBillingAddressSocketTaxIdOnUpdate, onUpdate)
}

export function* taxIdSaga() {
  yield all([fork(watchOnUpdate)])
}
