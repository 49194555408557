import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, GeneralCard } from '@/components/Elements'
import { BillingAddressUpdateContainer } from './BillingAddressUpdateContainer'

interface IProps {
  disabledMode?: boolean
}

export const BillingAddressUpdate = (props: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <GeneralCard title={t('billing_information')}>
        <Alert mb={'lg'} type={'info'}>
          {t('billing_info.editable-alert')}
        </Alert>

        <BillingAddressUpdateContainer {...props} />
      </GeneralCard>
    </>
  )
}
