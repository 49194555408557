import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { NormalizeUtils } from '@/utils'

export type GeneralFormSectionValues = {
  first_name: string
  last_name: string
  email: string
  company_name: string
  phone: string
  address: string
  city: string
  zip: string
}

interface IProps {
  isDisabled?: boolean
}

export const GeneralFormSection = ({ isDisabled }: IProps) => {
  const { t } = useTranslation()

  const { control } = useFormContext<GeneralFormSectionValues>()

  return (
    <>
      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'first_name'}
            id={'first_name'}
            label={t('first_name')}
            placeholder={t('first_name')}
            mb={'md'}
            disabled={isDisabled}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'last_name'}
            id={'last_name'}
            label={t('last_name')}
            placeholder={t('last_name')}
            mb={'md'}
            disabled={isDisabled}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'email'}
            id={'email'}
            label={t('email')}
            placeholder={t('email')}
            mb={'md'}
            disabled={isDisabled}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'company_name'}
            id={'company_name'}
            label={t('company')}
            placeholder={t('company')}
            mb={'md'}
            disabled={isDisabled}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'phone'}
            id={'phone'}
            label={t('phone')}
            placeholder={t('phone')}
            mb={'md'}
            normalize={NormalizeUtils.phone}
            disabled={isDisabled}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'address'}
            id={'address'}
            label={t('address')}
            placeholder={t('address')}
            mb={'md'}
            disabled={isDisabled}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'city'}
            id={'city'}
            label={t('city')}
            placeholder={t('city')}
            mb={'md'}
            disabled={isDisabled}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'zip'}
            id={'zip'}
            label={t('zip')}
            placeholder={t('zip')}
            mb={'md'}
            disabled={isDisabled}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
