import React from 'react'
import { useTranslation } from 'react-i18next'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { paymentMethodHelper } from '@/features/billing/helpers'

export const usePaymentMethodStaticOptions = () => {
  const { t } = useTranslation()

  const getTypes = () => {
    return paymentMethodHelper.general.getTypes()
  }

  const getType = (value: BILLING_PAYMENT_METHOD_TYPE) => {
    return paymentMethodHelper.general.getType(value)
  }

  const isCardType = (value: BILLING_PAYMENT_METHOD_TYPE) => {
    return paymentMethodHelper.general.isCardType(value)
  }

  const isSepaType = (value: BILLING_PAYMENT_METHOD_TYPE) => {
    return paymentMethodHelper.general.isSepaType(value)
  }

  return {
    getTypes,
    getType,
    isCardType,
    isSepaType,
  }
}
