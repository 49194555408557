import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import _ from 'lodash'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { chatsUsersAPI } from '@/features/chat/api'
import { User } from '@/features/user'
import { selectUserData } from '@/features/user/store'
import { userData } from '@/features/user/store'
import i18n from '@/i18n'
import { RootState } from '@/store'
import { readChatSubmit } from '../read/saga'
import { chatConversationMessagesList } from './slice'

const userDataState = (state: RootState) => selectUserData(state)

function* getList({
  payload: {
    userId,
    chatId,
    params,
    state: { isMoreLoading },
  },
}: PayloadAction<{
  userId: number
  chatId: number | undefined
  params: any
  state: { isMoreLoading: boolean }
}>) {
  try {
    const response: AxiosResponse = yield call(
      chatsUsersAPI.getUserMessages,
      userId,
      chatId || 0,
      params
    )

    const { data } = response

    yield put(chatConversationMessagesList.getListSuccess(data))

    const messages = data.data
    const { unread_count } = data.meta

    if (messages.length > 0 && !isMoreLoading) {
      const isGroup = false
      let unSeenMessage = null
      // need for case: when user added to group and group has message history
      if (isGroup && unread_count === 0) {
        unSeenMessage = _.find(messages, { seen: false, is_my: false })
      }

      if (unread_count || unSeenMessage) {
        const messageChatId = messages[0].chat_id

        // read chat (seen messages)
        const readChat: boolean | unknown = yield call(readChatSubmit as any, {
          payload: { userId, chatId: messageChatId },
        })

        if (readChat && unread_count) {
          const user: User = yield select(userDataState)
          const count = user.unread_messages ? user.unread_messages - unread_count : 0

          // update count all of unread messages (- badge in side menu)
          yield put(userData.updateUnreadMessages(count))
        }
      }
    }
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(chatConversationMessagesList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(chatConversationMessagesList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
