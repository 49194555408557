import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { useUser } from '@/features/user/hooks'
import { NormalizeUtils } from '@/utils'

export type GeneralFormSectionValues = {
  company_name: string
  contact_name: string
  address: string
  city: string
  state: string
  zip_code: string
  street: string
  business_hours: string
  business_days: string
  email: string
  phone: string
  // lang: string
}

export const GeneralFormSection = () => {
  const { user } = useUser()

  const isSubSupplier = user?.role === 'sub-supplier'

  const { t } = useTranslation()

  const { control } = useFormContext<GeneralFormSectionValues>()

  return (
    <>
      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'company_name'}
            id={'company_name'}
            label={t('company_name')}
            placeholder={t('company_name')}
            readOnly
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'contact_name'}
            id={'contact_name'}
            label={t('contact_name')}
            placeholder={t('contact_name')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'city'}
            id={'city'}
            label={t('city')}
            placeholder={t('city')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'state'}
            id={'state'}
            label={t('state')}
            placeholder={t('state')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'street'}
            id={'street'}
            label={t('street')}
            placeholder={t('street')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'zip_code'}
            id={'zip_code'}
            label={t('zip_code')}
            placeholder={t('zip_code')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'email'}
            id={'email'}
            label={t('email')}
            placeholder={t('email')}
            mb={'md'}
            readOnly
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'phone'}
            id={'phone'}
            label={t('phone')}
            placeholder={t('phone')}
            mb={'md'}
            normalize={NormalizeUtils.phone}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'business_days'}
            id={'business_days'}
            label={t('business_days')}
            placeholder={t('business_days')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'business_hours'}
            id={'business_hours'}
            label={t('business_hours')}
            placeholder={t('business_hours')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>
      {/*<Grid>*/}
      {/*  <Grid.Col md={6}>*/}
      {/*    <LangPickerController control={control} name={'lang'} label={t('language')} mb={'md'} />*/}
      {/*  </Grid.Col>*/}
      {/*</Grid>*/}
    </>
  )
}
