import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { LiveFeedsContainer } from '@/features/liveFeeds/components/liveFeeds/LiveFeedsContainer'

export const LiveFeeds = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'liveFeedsTitle'} />

      <PageContent>
        <LiveFeedsContainer />
      </PageContent>
    </>
  )
}
