import { createSlice } from '@reduxjs/toolkit'
// import { authApi } from '@/features/auth/store'
import { RootState } from '@/store'
import { authApi } from '../api/slice'

interface AuthState {
  isLoggedIn: boolean
}

const initialState: AuthState = {
  isLoggedIn: false,
}

const authDataSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    setData(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn
    },
    cleanData() {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      state.isLoggedIn = true
    })

    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
      return initialState
    })
  },
})

export const authData = authDataSlice.actions

export const selectAuthData = (state: RootState) => state.auth.data

export default authDataSlice.reducer
