import React from 'react'
import { useTranslation } from 'react-i18next'
import { BadgeProps } from '@mantine/core'
import { Badge } from '@/components/Elements'

export const DefaultPaymentMethodBadge = (props: BadgeProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Badge {...props}>{t('default')}</Badge>
    </>
  )
}
