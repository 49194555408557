import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { useBillingPaymentMethodListQuery } from '@/features/billing/store'

const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

interface IProps {
  setValue: (...arg: any) => void
}

export const usePaymentMethodEffect = ({ setValue }: IProps) => {
  const { t } = useTranslation()

  const { isSuccess, isFetching, isLoading, data, ...state } = useBillingPaymentMethodListQuery()

  useEffect(() => {
    if (data && !!data.length) {
      const defaultItem = data.find((item) => {
        return item?.default
      })

      if (defaultItem && defaultItem?.type) {
        const { type, id } = defaultItem

        setValue('payment_method_type', type)
        if (type === CARD) {
          setValue('card', id)
        } else if (type === SEPA_DEBIT) {
          setValue('iban', id)
        }
      }
    }
  }, [isSuccess])

  return {
    loading: isFetching || isLoading,
    isSuccess,
    data,
    ...state,
  }
}
