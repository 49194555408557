import React from 'react'
import { HeaderLayout } from '@/features/chat-ui/components/Layouts/RoomLayout'
import { Info } from './Info'

export const Header = () => {
  return (
    <HeaderLayout>
      <div className={'flex grow'}>
        <Info />
      </div>

      {/*<div className={'self-end'}>*/}
      {/*  <ActionBar />*/}
      {/*</div>*/}
    </HeaderLayout>
  )
}
