import { createAction } from '@reduxjs/toolkit'

const USER_SOCKET_SUBSCRIPTION_ON_SUBSCRIPTION_UPDATE =
  'user/socket-subscription-on-subscription-update'

export const userSocketSubscriptionOnSubscriptionUpdate = createAction(
  USER_SOCKET_SUBSCRIPTION_ON_SUBSCRIPTION_UPDATE,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
