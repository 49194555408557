import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { chatsAPI } from '@/features/chat/api'
import { FormTypes } from '@/types'
import {
  CHAT_CONVERSATION_ATTACHMENT_UPLOAD_FAILED,
  CHAT_CONVERSATION_ATTACHMENT_UPLOAD_REQUEST,
  CHAT_CONVERSATION_ATTACHMENT_UPLOAD_SUCCESS,
} from './actionTypes'

type ArgsType = {
  params: {
    file: File
  }
}

export const chatConversationAttachmentUploadPA = createPromiseAction(
  CHAT_CONVERSATION_ATTACHMENT_UPLOAD_REQUEST,
  CHAT_CONVERSATION_ATTACHMENT_UPLOAD_SUCCESS,
  CHAT_CONVERSATION_ATTACHMENT_UPLOAD_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* upload(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { params } = action.payload

    const response: AxiosResponse = yield call(chatsAPI.uploadAttachment, params)
    const { data } = response

    yield put(chatConversationAttachmentUploadPA.success({}))

    resolvePromiseAction(action, { ...data?.data })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      rejectPromiseAction(action, error)
    } else {
      rejectPromiseAction(action, error.response.data)
    }
  }
}

export function* uploadSaga() {
  yield takeEvery(chatConversationAttachmentUploadPA.request, upload)
}
