import axios from 'axios'
import { SendMessageToUserDTO } from './dto'

class ChatsUsersAPI {
  // Send message to user
  sendMessageToUser = async (id: number, data: SendMessageToUserDTO) => {
    return axios.post(`api/chats/groups/${id}/messages`, data)
  }

  // Get messages from user
  getUserMessages = async (id: number, chatId: number, params: any) => {
    return axios.get(`api/chats/groups/${chatId}/messages`, {
      params,
    })
  }

  // Make seen massages in chat with user
  seenUserMessages = async (id: number) => {
    return axios.post(`api/chats/groups/${id}/messages/seen`)
  }
}

export const chatsUsersAPI = new ChatsUsersAPI()
