import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { GeneralPaymentMethodsContainer } from '@/features/billing/components/PaymentMethod/PaymentMethods'

export const PaymentMethods = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'payment_methods'} />

      <GeneralPaymentMethodsContainer />
    </>
  )
}
