import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataShower } from '@/components/Elements'
import { useBillingAddressStaticOptions } from '@/features/billing/hooks'
import { useBillingBillingAddressDataQuery } from '@/features/billing/store'
import { SepaForm } from '../../../PaymentMethodsForms'

interface IProps {
  stripe: any
  elements: any
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
}

export const SepaContainer = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const { data, isSuccess, isError, isLoading, isFetching, error } =
    useBillingBillingAddressDataQuery()

  const { mapBillingAddress } = useBillingAddressStaticOptions()

  return (
    <>
      <DataShower
        isFetched={isSuccess}
        isLoading={isLoading || isFetching}
        isFailed={isError}
        error={error}
      >
        {data && (
          <SepaForm
            initialValues={{
              name: data?.first_name,
              email: data?.email,
              address: mapBillingAddress(data),
            }}
            {...props}
          />
        )}
      </DataShower>
    </>
  )
}
