import React from 'react'
import { useTranslation } from 'react-i18next'
import { Attachment as AttachmentComponent } from '@/components/Elements'
import { FileUtils } from '@/utils'

interface IProps {
  data: {
    id: number
    url: string
  }
}

export const Attachment = ({ data: { id, url } }: IProps) => {
  const { t } = useTranslation()

  const onDownload = () => {
    FileUtils.downloadFileByLink(url)
  }

  const isImg = () => {
    return url?.includes('.jpg') || url?.includes('.jpeg') || url?.includes('.png')
  }

  return (
    <AttachmentComponent
      name={t('attachment')}
      remove={false}
      onRemove={() => {}}
      download
      onDownload={onDownload}
      card={false}
      url={url}
      isImg={isImg()}
    />
  )
}
