import axios from 'axios'
import Pusher from 'pusher-js'
import { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCar, AiOutlineUser } from 'react-icons/ai'
import ImageGallery from 'react-image-gallery'
import { useNavigate } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Grid,
  Group,
  Image,
  Input,
  Select,
  Tabs,
  Text,
} from '@mantine/core'
import { Notifications, notifications } from '@mantine/notifications'
import { TableView } from '@/components/Elements'
import { Anchor, ButtonLink, ColorSchemeToggle, LogoIcon, Modal } from '@/components/Elements'
import { UserChatCreateContainer } from '@/features/chat/components/Chat/UserChatCreate/UserChatCreateContainer'
import { useLiveFeedsLoadMutation } from '@/features/liveFeeds/store'
import { useUser } from '@/features/user/hooks'
import { useIsVisible, useNotify } from '@/hooks'
import { ChatControl } from './Controls'
import './Gallery.scss'

const KEY = process.env.REACT_APP_PUSHER_KEY || 'KEY'
const CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER || 'CLUSTER'
const AUTH_URL = process.env.REACT_APP_BASE_URL + 'api/broadcasting/auth'

const pusher = new Pusher(KEY, {
  cluster: CLUSTER,
  authEndpoint: AUTH_URL,
})

export const LiveFeedsContainer = () => {
  const { showNotification } = useNotify()

  const navigate = useNavigate()

  const { t } = useTranslation()

  const [search, setSearch] = useState('')
  const [customerType, setCustomerType] = useState('')

  const debounced = useDebouncedCallback((value) => {
    loadFeeds()
  }, 500)

  const [loadLiveFeeds] = useLiveFeedsLoadMutation()

  const [isOpen, setIsOpen] = useState(false)
  const [modalData, setModalData] = useState<any>(null)
  const [activeImage, setActiveImage] = useState(0)

  const { user } = useUser()

  const [feeds, setFeeds] = useState<any>({
    data: [],
    meta: null,
    links: null,
  })
  const [isLoading, setIsLoading] = useState(false)

  const [pusherFeeds, setPusherFeeds] = useState<any>([])

  const elRef = createRef<HTMLDivElement>()
  const isVisible = useIsVisible(elRef)

  const loadFeeds = async () => {
    setIsLoading(true)

    try {
      const reqData = {
        page: 1,
        search,
        customerType,
      }

      const res = await loadLiveFeeds(reqData).unwrap()
      setFeeds(res)
    } catch (e) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadFeeds()
  }, [customerType])

  const handleView = (feed: any) => {
    setModalData(feed)
    setActiveImage(0)
    setIsOpen(true)
  }

  const loadMoreFeeds = async () => {
    const loadPage = feeds?.meta?.current_page

    if (!loadPage) return

    try {
      setIsLoading(true)

      const reqData = {
        page: loadPage + 1,
        search,
        customerType,
      }

      const res = await loadLiveFeeds(reqData).unwrap()

      const mergedData = [...feeds.data, ...res.data]

      const newFeeds = {
        data: mergedData,
        meta: res.meta,
        links: res.links,
      }

      setFeeds(newFeeds)
    } catch (e) {
    } finally {
      setIsLoading(false)
    }
  }

  const handleMergeFeeds = () => {
    setFeeds({
      ...feeds,
      data: [...pusherFeeds, ...feeds.data],
    })

    setPusherFeeds([])
  }

  const handleNewFeed = (newFeed: any) => {
    setPusherFeeds([...pusherFeeds, { ...newFeed, isNew: true }])
  }

  const handlePurchased = (toggleFeed: any) => {
    if (toggleFeed?.markPurchased) {
      setFeeds({
        ...feeds,
        data: [...feeds.data.filter((feed: any) => feed?.id !== toggleFeed?.id)],
      })
      setPusherFeeds([...pusherFeeds.filter((feed: any) => feed?.id !== toggleFeed?.id)])
    } else {
      handleNewFeed(toggleFeed)
    }
  }

  const channel = pusher.subscribe('posts')

  // eslint-disable-next-line prettier/prettier, no-useless-escape
  channel.bind('App\\Events\\Post\\PostCreatedEvent', (data: any) => {
    const newPusherFeed = data.post
    handleNewFeed(newPusherFeed)
  })

  // eslint-disable-next-line prettier/prettier, no-useless-escape
  channel.bind('App\\Events\\Post\\PostMarkPurchasedEvent', (data: any) => {
    const newPusherFeed = data.post
    handlePurchased(newPusherFeed)
  })

  useEffect(() => {
    loadFeeds()
    pusher.subscribe('posts')

    return () => {
      pusher.unsubscribe('posts')
    }
  }, [])

  useEffect(() => {
    if (feeds?.data && !isLoading && feeds?.data?.length < feeds?.meta?.total) {
      loadMoreFeeds()
    }
  }, [isVisible])

  const navigateToChat = (id: any) => {
    const participant = modalData?.user || {}

    navigate('/chats', {
      state: {
        participant,
        id,
        post: modalData,
      },
    })
  }

  const navigateToPostChat = (participant: any, chatId: any, post: any) => {
    navigate('/chats', {
      state: {
        participant,
        id: chatId,
        post,
      },
    })
  }

  const userAddress = (user: any) => {
    let address = ''

    if (user?.city) address += `${user?.city} `
    if (user?.state) address += `${user?.state} `
    // if (user?.street) address += `${user?.street} `
    if (user?.zip_code) address += `${user?.zip_code} `

    if (address === '') return '-'

    return address
  }

  return (
    <>
      <Modal
        size={'lg'}
        opened={isOpen}
        onClose={() => setIsOpen(false)}
        header={`${modalData?.make} ${modalData?.model} | ${modalData?.year}`}
      >
        <Grid>
          <Grid.Col span={6}>
            <div style={{ margin: 30 }}>
              {modalData?.photos?.length ? (
                <ImageGallery
                  infinite
                  showNav
                  showThumbnails
                  showPlayButton={false}
                  showFullscreenButton
                  showBullets={false}
                  isRTL
                  items={modalData?.photos.map((photo: any) => {
                    return { original: photo?.uri, thumbnail: photo?.uri }
                  })}
                />
              ) : null}
            </div>
            <div className={'flex wrap justify-start items-center'}>
              {!modalData?.photos?.length ? (
                <Image
                  src={modalData?.makeUrl}
                  height={250}
                  fit="contain"
                  width={250}
                  alt={`empty`}
                />
              ) : null}
            </div>
            {/* {modalData?.photos?.[activeImage]?.uri ? (
              <div>
                <Image
                  src={modalData?.photos?.[activeImage]?.uri}
                  height={250}
                  fit="cover"
                  width={250}
                  alt={`${modalData.make} ${modalData.model} | ${modalData.year}`}
                />
              </div>
            ) : null} */}
          </Grid.Col>
          <Grid.Col span={6}>
            <Tabs defaultValue="info">
              <Tabs.List>
                <Tabs.Tab value="info" icon={<AiOutlineCar />}>
                  {t('description_title')}
                </Tabs.Tab>
                <Tabs.Tab value="creator" icon={<AiOutlineUser />}>
                  {t('creator_title')}
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="creator" pt="xs">
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Avatar
                    src={modalData?.user?.avatar?.thumb}
                    alt={`${modalData?.user?.company_name} ${modalData?.user?.contact_name}`}
                    style={{ marginRight: 10 }}
                  />
                  <Text size="lg" weight={500}>
                    {modalData?.user?.company_name} {modalData?.user?.contact_name}
                  </Text>
                </div>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('street')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.user?.street}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('address')}
                </Text>
                <Text size="md" weight={500}>
                  {userAddress(modalData?.user)}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('phone')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.user?.phone || '-'}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('email')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.user?.email || '-'}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('business_hours')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.user?.business_hours || '-'}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('business_days')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.user?.business_days || '-'}
                </Text>
              </Tabs.Panel>

              <Tabs.Panel value="info" pt="xs">
                <Text color="#b3b3b3" size="sm" weight={500}>
                  {t('live_feed_form_car_id_label')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.make || '-'}
                </Text>

                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('live_feed_form_car_model_id_label')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.model} | {modalData?.year}
                </Text>

                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('trim_title')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.trim || '-'}
                </Text>

                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('description')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.description || '-'}
                </Text>
              </Tabs.Panel>
            </Tabs>
            {modalData && modalData?.user?.id !== user?.id && (
              <Box my={'md'}>
                {modalData?.chats?.length ? (
                  <div>
                    {modalData?.chats.map((chat: any) => (
                      <Button
                        onClick={() => navigateToPostChat(chat?.participant, chat?.id, modalData)}
                        variant="outline"
                        fullWidth
                        mt="md"
                        radius="md"
                      >
                        {t('open_post_chat')}
                      </Button>
                    ))}
                  </div>
                ) : (
                  <UserChatCreateContainer
                    postId={modalData?.id}
                    id={modalData?.user?.id}
                    onClose={() => setIsOpen(false)}
                    onSuccess={navigateToChat}
                  />
                )}
              </Box>
            )}
          </Grid.Col>
        </Grid>
      </Modal>
      <Notifications />
      <Box mb={'md'} style={{ display: 'flex' }}>
        <Input
          style={{ flexGrow: 4 }}
          size="lg"
          placeholder={t('search')}
          defaultValue={search}
          onChange={(e: any) => {
            setSearch(e.target.value)
            debounced(e.target.value)
          }}
        />
        <Select
          style={{ flexGrow: 1 }}
          size="lg"
          value={customerType}
          onChange={(e: any) => setCustomerType(e)}
          placeholder={t('customer_type')}
          data={[
            { value: 'suppliers', label: t('customer_type_suppliers') },
            { value: 'business', label: t('customer_type_business') },
            { value: '', label: t('customer_type_all') },
          ]}
        />
      </Box>
      <div className={'flex justify-between items-center'}>
        <h2 className={'m-0'}>{t('live_feeds_title')}</h2>
        <ButtonLink to={'create'} variant={'outline'}>
          {t('create_live_feed_btn')}
        </ButtonLink>
      </div>
      {pusherFeeds?.length ? (
        <Alert mt="md" mb="md" title={t('new_feeds_title')} color="indigo">
          <Text size="md" weight={500}>
            {t('total_new_feeds')}: {pusherFeeds?.length}
          </Text>
          <Button onClick={handleMergeFeeds} variant="outline" fullWidth mt="md" radius="md">
            {t('view_new_feeds')}
          </Button>
        </Alert>
      ) : null}

      {isLoading ? <p>{t('loading')}</p> : null}

      {feeds?.meta?.total === 0 && !isLoading ? <p>{t('feeds_not_found')}</p> : null}
      {feeds?.meta?.total !== 0 && !isLoading ? (
        <Text weight={500}>
          {t('live_feeds_total')}: {feeds?.meta?.total}
        </Text>
      ) : null}

      {feeds?.data?.map((feed: any) => (
        <Card key={feed.id} shadow="sm" padding="0" radius="md" className="mt-2" withBorder>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', maxWidth: '80%' }}
            >
              {feed?.mainPhoto?.uri ? (
                <Image
                  src={feed?.mainPhoto?.uri}
                  height={110}
                  fit="cover"
                  width={200}
                  alt={`${feed.make} ${feed.model} | ${feed.year}`}
                />
              ) : (
                <Image
                  src={feed?.makeUrl}
                  height={110}
                  fit="contain"
                  width={200}
                  alt={`${feed.make} ${feed.model} | ${feed.year}`}
                />
              )}
              <div style={{ marginLeft: 10, maxWidth: '90%', padding: '10px 0' }}>
                <Text weight={500}>
                  {feed.year} {feed.make} {feed.model} {feed.trim}
                </Text>
                <Text size="sm">{feed.description}</Text>
                <Text size="sm" color="dimmed">
                  {feed?.user?.company_name || feed?.user?.first_name}{' '}
                  {feed?.user?.contact_name || feed?.user?.last_name}
                </Text>
                <Text size="sm" color="dimmed">
                  {userAddress(feed.user)}
                </Text>
              </div>
            </div>

            <div style={{ padding: 10 }}>
              <Badge color="pink" variant="light">
                {feed.createdAt}
              </Badge>

              {feed?.isNew ? (
                <Badge color="green" variant="light">
                  {t('new_feed_badge')}
                </Badge>
              ) : null}
              <Button mt={'md'} onClick={() => handleView(feed)} fullWidth variant={'outline'}>
                {t('view_btn')}
              </Button>
            </div>
          </div>
        </Card>
      ))}
      <div ref={elRef}></div>
    </>
  )
}
