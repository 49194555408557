/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import { ChatModel } from '@/features/chat/types/models'
import useStyles from './Body.styles'
import { CountBadge } from './CountBadge'
import ImageGallery from 'react-image-gallery'
import { useTranslation } from 'react-i18next'
import { Date } from './Date'
import { Message } from './Message'
import { Name } from './Name'
import { AiOutlineCar, AiOutlineUser } from 'react-icons/ai'
import { Modal } from '@/components/Elements'
import { Grid, Image, Text, Button, Tabs, Avatar } from '@mantine/core'
interface IProps {
  data: ChatModel
}

export const Body = ({ data }: IProps) => {
  const { classes, cx } = useStyles()

  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [modalData, setModalData] = useState<any>(null)

  const { last_message, participant, unread_count } = data

  const handleView = (feed: any) => {
    setModalData(feed)
    setIsOpen(true)
  }

  const userAddress = (user: any) => {
    let address = ''

    if (user?.city) address += `${user?.city} `
    if (user?.state) address += `${user?.state} `
    // if (user?.street) address += `${user?.street} `
    if (user?.zip_code) address += `${user?.zip_code} `

    if (address === '') return '-'

    return address
  }


  return (
    <div className={classes.root}>
      <Modal
        opened={isOpen}
        onClose={() => setIsOpen(false)}
        header={`${modalData?.make} ${modalData?.model} | ${modalData?.year}`}
      >
        <Grid>
          <Grid.Col span={6}>
            <div style={{ margin: 30 }}>
              {modalData?.photos?.length ? (
                <ImageGallery
                  infinite
                  showNav
                  showThumbnails
                  showFullscreenButton
                  showPlayButton={false}
                  showBullets={false}
                  isRTL
                  items={modalData?.photos.map((photo: any) => {
                    return { original: photo?.uri, thumbnail: photo?.uri }
                  })}
                />
              ) : null}
            </div>
            <div className={'flex wrap justify-start items-center'}>
              {!modalData?.photos?.length ? (
                <Image src={modalData?.makeUrl} height={250} fit="contain" width={250} alt={`empty`} />
              ) : null}
            </div>
          </Grid.Col>
          <Grid.Col span={6}>
            <Tabs defaultValue="info">
              <Tabs.List>
                <Tabs.Tab value="info" icon={<AiOutlineCar />}>
                  {t('description_title')}
                </Tabs.Tab>
                <Tabs.Tab value="creator" icon={<AiOutlineUser />}>
                  {t('creator_title')}
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="creator" pt="xs">
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Avatar
                    src={modalData?.user?.avatar?.thumb}
                    alt={`${modalData?.user?.company_name} ${modalData?.user?.contact_name}`}
                    style={{ marginRight: 10 }}
                  />
                  <Text size="lg" weight={500}>
                    {modalData?.user?.company_name} {modalData?.user?.contact_name}
                  </Text>
                </div>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('street')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.user?.street}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('address')}
                </Text>
                <Text size="md" weight={500}>
                  {userAddress(modalData?.user)}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('phone')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.user?.phone || '-'}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('email')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.user?.email || '-'}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('business_hours')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.user?.business_hours || '-'}
                </Text>
                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('business_days')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.user?.business_days || '-'}
                </Text>
              </Tabs.Panel>

              <Tabs.Panel value="info" pt="xs">
                <Text color="#b3b3b3" size="sm" weight={500}>
                  {t('live_feed_form_car_id_label')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.make || '-'}
                </Text>

                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('live_feed_form_car_model_id_label')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.model} | {modalData?.year}
                </Text>

                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('trim_title')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.trim || '-'}
                </Text>

                <Text color="#b3b3b3" mt={'sm'} size="sm" weight={500}>
                  {t('description')}
                </Text>
                <Text size="md" weight={500}>
                  {modalData?.description || '-'}
                </Text>
              </Tabs.Panel>
            </Tabs>
          </Grid.Col>
        </Grid>
      </Modal>

      <div className={classes.topBar}>
        <div className={'flex grow min-w-0'}>
          <Name participant={participant} />
        </div>

        <div className={'ml-1'}>
          <Date value={last_message?.created_at} />
        </div>
      </div>
      <div className={classes.subBar}>
        <div className={'flex grow min-w-0 my-1'}>
          {data?.post && (
            <Button size="xs" variant="light" onClick={() => handleView(data.post)}>
              {data.post?.make} {data.post?.model}
            </Button>
          )}
        </div>
      </div>
      <div className={classes.subBar}>
        <div className={'flex grow min-w-0'}>
          <Message message={last_message} />
        </div>

        {unread_count > 0 && (
          <div className={'ml-1'}>
            <CountBadge>{unread_count}</CountBadge>
          </div>
        )}
      </div>
    </div>
  )
}
