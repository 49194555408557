import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsChevronLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { ButtonProps, Group, Text } from '@mantine/core'
import { Anchor } from '@/components/Elements'

interface IProps extends ButtonProps {
  children?: React.ReactNode
  onBack?: (event: React.MouseEvent<HTMLElement>) => void
}

export const BackButton: React.FC<IProps> = ({ children, onBack, ...props }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleBack = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (onBack) {
      onBack(event)
    } else {
      navigate(-1)
    }
  }

  return (
    <Anchor onClick={handleBack} color={'dark'}>
      {children || (
        <Group spacing={'xs'}>
          <BsChevronLeft size={24} />

          <Text size={'lg'} weight={700}>
            {t('back')}
          </Text>
        </Group>
      )}
    </Anchor>
  )
}
