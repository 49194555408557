import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { InviteContainer } from '@/features/invites/components/Invite'

export const Invite = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'invite'} />

      <PageContent>
        <InviteContainer />
      </PageContent>
    </>
  )
}
