import React, { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DataShower, GeneralCard } from '@/components/Elements'
import { useBillingInvoiceDataQuery } from '@/features/billing/store'
import { usePrintContent } from '@/hooks'
import { Action } from './Action'
import { InvoiceContent } from './InvoiceContent'

export const InvoiceDetailsContainer = () => {
  const { t } = useTranslation()

  const { id } = useParams()

  if (!id) {
    return null
  }

  const { data, isLoading, isFetching, error, isError, isSuccess } = useBillingInvoiceDataQuery(+id)

  const loading = useMemo(() => {
    return isLoading || isFetching
  }, [isLoading, isFetching])

  const printContentRef = useRef<any>(null)

  const printContent = useCallback(() => {
    return printContentRef.current
  }, [printContentRef.current])

  const { print } = usePrintContent({
    content: printContent,
  })

  return (
    <GeneralCard>
      <DataShower isFetched={isSuccess} isLoading={loading} isFailed={isError} error={error}>
        {data && (
          <>
            <InvoiceContent data={data} contentRef={printContentRef} />

            <Action onPrint={print} />
          </>
        )}
      </DataShower>
    </GeneralCard>
  )
}
