import { createAction } from '@reduxjs/toolkit'

const BILLING_BILLING_ADDRESS_SOCKET_TAX_ID_ON_UPDATE =
  'billing/billing-address-socket-tax-id-on-update'

export const billingBillingAddressSocketTaxIdOnUpdate = createAction(
  BILLING_BILLING_ADDRESS_SOCKET_TAX_ID_ON_UPDATE,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
