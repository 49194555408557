import axios from 'axios'

class ChatsAPI {
  getChats = async (params: any) => {
    return axios.get(
      `api/chats${params?.payload?.postId ? `?filters[post_id]=${params?.payload?.postId}` : ''}`
    )
  }

  // Upload file for chat message
  uploadAttachment = async (params: any) => {
    const formData = new FormData()
    formData.append('file', params.file)

    return axios.post(`api/chats/files`, formData)
  }
}

export const chatsAPI = new ChatsAPI()
