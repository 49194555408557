import React from 'react'
import useStyles from './ChatLayout.styles'

interface IProps {
  children: React.ReactNode
}

export const ChatLayout = ({ children }: IProps) => {
  const { classes } = useStyles()

  return <div className={classes.root}>{children}</div>
}
