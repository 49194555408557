import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OverlayDataShower } from '@/components/Elements'
import {
  useBillingBillingAddressDataQuery,
  useBillingBillingAddressUpdateMutation,
} from '@/features/billing/store'
import { useUserSubscription } from '@/features/user/hooks'
import { useNotify } from '@/hooks'
import { BillingAddressForm } from '../../BillingAddressForm'

interface IProps {
  disabledMode?: boolean
}

export const BillingAddressUpdateContainer = ({ disabledMode }: IProps) => {
  const { t } = useTranslation()

  const [isFormActive, setIsFormActive] = useState(!disabledMode)

  const toggleActiveForm = () => {
    setIsFormActive((prevState) => !prevState)
  }

  const { subscription } = useUserSubscription()

  const isEditable = useMemo(() => {
    return !subscription?.tariff?.id
  }, [subscription?.tariff?.id])

  const { data, isSuccess, isLoading, isFetching, error } = useBillingBillingAddressDataQuery()

  const isDisabled = useMemo(() => {
    return !isSuccess || !isFormActive
  }, [isSuccess, isFormActive])

  const [update] = useBillingBillingAddressUpdateMutation()

  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    const data = await update({ params }).unwrap()

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    if (disabledMode && isFormActive) {
      setIsFormActive(false)
    }
  }

  return (
    <>
      <OverlayDataShower isLoading={isLoading || isFetching} isFailed={!!error} error={error}>
        <BillingAddressForm
          initialValues={{
            ...data,
          }}
          onSubmit={onSubmit}
          isDisabled={isDisabled}
          disabledMode={disabledMode}
          isFormActive={isFormActive}
          toggleActiveForm={toggleActiveForm}
          isEditable={isEditable}
        />
      </OverlayDataShower>
    </>
  )
}
