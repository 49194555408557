import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '@mantine/core'
import { GeneralCard } from '@/components/Elements'
import { useSubscriptionStaticOptions, useTariffStaticOptions } from '@/features/billing/hooks'
import { useUserSubscription } from '@/features/user/hooks'
import { DateUtils } from '@/utils'
import { SubscriptionStatus } from './SubscriptionStatus'
import useStyles from './Table.styles'

export const SubscriptionOverview = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { subscription } = useUserSubscription()

  const { getPeriod } = useTariffStaticOptions()
  const { getStatus } = useSubscriptionStaticOptions()

  return (
    <GeneralCard title={t('information')}>
      <div className={'overflow-x-auto'}>
        <Table className={classes.table}>
          <tbody>
            <tr>
              <th scope="row">{t('subscription_plan')}:</th>
              <td>{subscription ? subscription.tariff?.name : t('free')}</td>
            </tr>
            {subscription && (
              <>
                <tr>
                  <th scope="row">{t('subscription_status')}:</th>
                  <td>
                    <SubscriptionStatus
                      status={subscription.status}
                      tarrifID={subscription.tariff?.id}
                    />
                  </td>
                </tr>

                {subscription.tariff?.period && (
                  <tr>
                    <th scope="row">{t('billed_period')}:</th>
                    <td>{getPeriod(subscription.tariff.period)?.label}</td>
                  </tr>
                )}

                {subscription.current_period_ends_at && (
                  <tr>
                    <th scope="row">{t('current_period_ends_at')}:</th>
                    <td>{DateUtils.formatDate(subscription.current_period_ends_at)}</td>
                  </tr>
                )}

                {subscription.ends_at && (
                  <tr>
                    <th scope="row">{t('ends_at')}:</th>
                    <td>{DateUtils.formatDate(subscription.ends_at)}</td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </Table>
      </div>
    </GeneralCard>
  )
}
