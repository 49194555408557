import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  inputElement: {
    ['.StripeElement']: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    ['.StripeElement--invalid']: {
      borderColor: theme.fn.variant({ variant: 'filled', color: 'red' }).background,
    },
  },
}))
