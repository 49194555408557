import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Text } from '@mantine/core'
import { MessageType } from '@/features/chat/types/models'
import { useUsersModel } from '@/features/users/hooks'
import { Attachment } from './Attachment'
import useStyles from './Message.styles'
import { Meta } from './Meta'

interface IProps {
  item: MessageType
  config: {
    name: boolean
    seen: boolean
  }
  alignRight?: boolean
}

const defaultConfig = {
  name: false,
  seen: false,
}

export const Message = ({ item, config, alignRight }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  config = {
    ...defaultConfig,
    ...config,
  }

  const { getName } = useUsersModel()

  return (
    <div
      className={cx(classes.root, {
        [`${classes.highlight}`]: alignRight,
      })}
    >
      {config.name && item?.user && (
        <Text
          weight={600}
          className={cx({
            [`text-end`]: alignRight,
          })}
        >
          {getName(item.user)}
        </Text>
      )}

      {item?.files &&
        !!item?.files.length &&
        item.files.map((item) => (
          <Box key={'message-attachment-' + item.id} my={'sm'}>
            <Attachment data={item} />
          </Box>
        ))}

      {item?.body && <Text className={'text-start'}>{item?.body}</Text>}

      <div className={'flex justify-end'}>
        <Meta data={item} config={{ seen: config.seen }} />
      </div>
    </div>
  )
}
