import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Group } from '@mantine/core'
import { Button } from '@/components/Elements'
import { TariffInfoTable } from '@/features/billing/components/Tariff/Tariff'
import { useBillingSubscriptionSwapTariffMutation } from '@/features/billing/store'
import { TariffModel } from '@/features/billing/types'
import { useNotify } from '@/hooks'

interface IProps {
  tariff: TariffModel
  onCancel: () => void
  onSuccess?: () => void
}

export const SubscriptionChangeContainer = ({ tariff, onCancel, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { showNotification } = useNotify()

  const [swap, { isLoading }] = useBillingSubscriptionSwapTariffMutation()

  const onSubmit = async () => {
    const handleError = (error: any) => {
      showNotification({
        type: 'error',
        message: error?.data?.message || t('error'),
      })
    }

    try {
      const params = {
        tariff: tariff?.id,
      }

      const result = await swap({ params }).unwrap()

      const { success, type, message, url } = result

      if (success) {
        navigate('/billing')

        onSuccess && onSuccess()
      } else if (url) {
        // redirect to 3d secure page
        window.location.href = url
      } else if (message) {
        handleError({ data: { message } })
        return
      }
    } catch (error: any) {
      handleError(error)
    }
  }

  return (
    <>
      <TariffInfoTable data={tariff} />

      <Box mt={'lg'}>
        <Group>
          <Button variant="outline" color="dark" onClick={onCancel}>
            {t('cancel')}
          </Button>

          <Button loading={isLoading} onClick={onSubmit}>
            {t('confirm')}
          </Button>
        </Group>
      </Box>
    </>
  )
}
