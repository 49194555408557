import { rootApi } from '@/store/api'
import { UpdateBillingAddressDTO } from '@/features/billing/api'
import { BillingAddressCountryModel, BillingAddressModel } from '@/features/billing/types'
import { RTKQueryUtils } from '@/utils'

const { cacher } = RTKQueryUtils

const enhanceApi = rootApi.enhanceEndpoints({
  addTagTypes: ['BillingBillingAddress', 'BillingBillingAddressCountries'],
})

export const billingBillingAddressApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    billingBillingAddressData: builder.query<BillingAddressModel, void>({
      query: () => ({
        url: `billing/billing-address`,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    billingBillingAddressUpdate: builder.mutation<
      BillingAddressModel,
      { params: UpdateBillingAddressDTO }
    >({
      query: (arg) => {
        const { params } = arg

        return {
          url: `billing/billing-address`,
          method: 'PUT',
          body: params,
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            billingBillingAddressApi.util.updateQueryData(
              'billingBillingAddressData',
              undefined,
              (draft) => data
            )
          )
        } catch {}
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    billingBillingAddressCountriesList: builder.query<BillingAddressCountryModel[], void>({
      query: () => ({
        url: `billing/billing-address-countries`,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
      providesTags: cacher.providesList('BillingBillingAddressCountries'),
    }),
  }),
  overrideExisting: false,
})

export const {
  useBillingBillingAddressDataQuery,
  useBillingBillingAddressUpdateMutation,
  useBillingBillingAddressCountriesListQuery,
} = billingBillingAddressApi
