import { createAction } from '@reduxjs/toolkit'
import wavFile from '@/assets/audio/ring.mp3'

const CHAT_SOCKET_ON_MESSAGE = 'chat/socket-on-message'

export const chatSocketOnMessage = createAction(CHAT_SOCKET_ON_MESSAGE, function prepare(data) {
  const audio = new Audio(wavFile)
  audio.play()

  return {
    payload: {
      data,
    },
  }
})
