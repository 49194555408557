import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { Chat as ChatComponent, ChatSocketMiddleware } from '@/features/chat/components/Chats'
import { chatConversationData } from '@/features/chat/store'
import { Participant } from '@/features/chat/types/models'

export const Chats = () => {
  const { t } = useTranslation()

  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const { participant } = (location.state as { participant?: Participant }) || {}

    const id = location.state?.id || 0
    const post = location.state?.post || null

    if (participant) {
      dispatch(chatConversationData.initParticipant({ participant, id, post }))
    }
  }, [location.state])

  return (
    <>
      <MegaTagTitle title={'chats'} />

      <PageContent>
        <ChatSocketMiddleware>
          <ChatComponent />
        </ChatSocketMiddleware>
      </PageContent>
    </>
  )
}
