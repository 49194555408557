import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { chatsUsersAPI } from '@/features/chat/api'
import { SendMessageToUserDTO } from '@/features/chat/api/dto'
import { selectConversationParticipantId } from '@/features/chat/store/conversation/data/selectors'
import { chatConversationMessagesList } from '@/features/chat/store/conversation/messages/list/slice'
import { RootState } from '@/store'
import { FormTypes } from '@/types'
import { chatUpdateOrCreate } from '../../../chats/list/saga'
import {
  CHAT_CONVERSATION_MESSAGES_SEND_FAILED,
  CHAT_CONVERSATION_MESSAGES_SEND_REQUEST,
  CHAT_CONVERSATION_MESSAGES_SEND_SUCCESS,
} from './actionTypes'

type ArgsType = {
  userId: number
  chatId: number
  params: SendMessageToUserDTO
}

export const chatConversationMessagesSendPA = createPromiseAction(
  CHAT_CONVERSATION_MESSAGES_SEND_REQUEST,
  CHAT_CONVERSATION_MESSAGES_SEND_SUCCESS,
  CHAT_CONVERSATION_MESSAGES_SEND_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

const conversationState = (state: RootState) => state.chat.conversation.data.data
const participantIdState = (state: RootState) => selectConversationParticipantId(state)

function* send(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { userId, params, chatId } = action.payload

    const response: AxiosResponse = yield call(chatsUsersAPI.sendMessageToUser, chatId, params)
    const { data } = response

    yield put(chatConversationMessagesSendPA.success({}))

    const message = data?.data

    // update message feed after send message
    const conversation: {
      participant: any
    } = yield select(conversationState)
    const participantId: number = yield select(participantIdState)

    const isGroup = false
    // check current opened chat (group or personal)
    if (
      // (isGroup && groupChatId === chatId) ||
      !isGroup &&
      participantId === userId
    ) {
      yield put(chatConversationMessagesList.addMessage(message))
    }

    resolvePromiseAction(action, {})

    // update chat list
    const chatListPayload = {
      message,
      chat: {
        id: message.chat_id,
        participant: !isGroup ? conversation?.participant : null,
        // is_group: isGroup,
      },
    }
    yield call(chatUpdateOrCreate as any, { payload: chatListPayload })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* sendSaga() {
  yield takeEvery(chatConversationMessagesSendPA.request, send)
}
